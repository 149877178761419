<template>
  <smart-church-layout page="Finance">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <v-container class="my-8">
      <div class="d-flex my-5">
        <h3 class="primary--text font-weight-bold">Finances</h3>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-5 font-weight-bold"
              v-bind="attrs"
              v-on="on"
              color="black"
              text
              ><v-icon>mdi-plus</v-icon> Create Finance</v-btn
            >
          </template>
          <v-card class="rounded-lg text-capitalize">
            <v-form class="pa-9" ref="form">
              <h2
                class="
                  mb-4
                  primary--text
                  d-flex
                  justify-center
                  font-weight-bold
                "
              >
                create finance
              </h2>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="create_finance.name"
                    outlined
                    dense
                    label="Name"
                    :error-messages="create_finance_errors.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="₦ Amount"
                    type="number"
                    id="amount"
                    v-model="create_finance.amount"
                    :error-messages="create_finance_errors.amount"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="create_finance.service_report_id"
                    :items="serviceReports"
                    dense
                    label="Service Report"
                    id="service_report_id"
                    item-value="id"
                    outlined
                    item-text="service_title"
                    :error-messages="create_finance_errors.service_report_id"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    dense
                    label="Description"
                    id="description"
                    v-model="create_finance.description"
                    :error-messages="create_finance_errors.description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn color="primary white--text" block @click="createFinance"
                >Create</v-btn
              >
            </v-form>
          </v-card>
        </v-dialog>
      </div>

      <div class="alert alert-success mt-3" v-if="create_finance.response">
        {{ create_finance.response }}
      </div>
      <div class="alert alert-danger mt-3" v-if="create_finance.error">
        {{ create_finance.error }}
      </div>

      <div class="alert alert-danger my-3" v-if="mountedError">
        Data could not be loaded: {{ mountedError }}
        <v-btn
          color="primary white--text"
          :loading="isLoading"
          @click="initFinance"
        >
          Fetch again
        </v-btn>
      </div>
      <v-data-table :headers="headers" :items="finances" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="600px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-form class="pa-9" ref="form">
                      <h2
                        class="
                          mb-4
                          primary--text
                          d-flex
                          justify-center
                          font-weight-bold
                        "
                      >
                        Update finance
                      </h2>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="create_finance.name"
                            outlined
                            dense
                            label="Name"
                            :error-messages="create_finance_errors.name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            outlined
                            dense
                            label="₦ Amount"
                            type="number"
                            id="amount"
                            v-model="create_finance.amount"
                            :error-messages="create_finance_errors.amount"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="create_finance.service_report_id"
                            :items="serviceReports"
                            dense
                            label="Service Report"
                            id="service_report_id"
                            item-value="id"
                            outlined
                            item-text="service_title"
                            :error-messages="
                              create_finance_errors.service_report_id
                            "
                          >
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-textarea
                            outlined
                            dense
                            label="Description"
                            id="description"
                            v-model="create_finance.description"
                            :error-messages="create_finance_errors.description"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-btn color="primary white--text" block @click="save"
                        >Update</v-btn
                      >
                      <div
                        class="alert alert-success mt-3"
                        v-if="create_finance.response"
                      >
                        {{ create_finance.response }}
                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.amount`]="{ item }"> ₦{{ item.amount }} </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      switch1: true,
      overlay: true,
      headers: [
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Income Title ", value: "name" },
        { text: "Description", value: "description" },
        { text: "Service", value: "service_report.service_title" },

        { text: "Actions", value: "actions", sortable: false },
      ],

      finances: [],

      create_finance: {
        name: "",
        amount: "",
        serviceReport: "",
        description: "",
        response: "",
        error: "",
        all: false,
      },

      incomeTitles: [],
      display_finance_error: [],
      income_title_error: [],

      serviceReports: [],
      editedIndex: -1,
      create_finance_errors: {},

      isLoading: false,
      mountedError: null,
    };
  },

  watch: {
    dialog2(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async initFinance() {
      this.isLoading = true;

      try {
        const displayFinance = await this.$http.get("admin/income-report");
        const displayIncomeTitle = await this.$http.get("admin/income-title");
        const serviceReports = await this.$http.get("admin/service-report");

        this.incomeTitles = displayIncomeTitle.data.data;
        this.serviceReports = serviceReports.data.data;
        this.finances = displayFinance.data.data;

        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        this.mountedError = error.response.data.message;
        this.isLoading = false;
        this.overlay = false;

        catchAxiosExceptions(error);
      }
    },

    createFinance() {
      this.$http
        .post("admin/income-report", this.create_finance)
        .then((response) => {
          this.create_finance.response = response.data.message;

          this.$refs.form.reset();
          this.create_finance.name = "";
          this.initFinance();

          this.dialog = false;
        })
        .catch((error) => {
          this.create_finance.error = error.response.data.message;
          this.create_finance_errors = error.response.data.errors;
          catchAxiosExceptions(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.finances.indexOf(item);
      this.create_finance = Object.assign({}, item);
      this.dialog2 = true;
    },

    save() {
      this.$http
        .put(
          "admin/income-report/" + this.create_finance.slug,
          this.create_finance
        )
        .then((response) => {
          this.create_finance.response = response.data.message;

          this.initFinance();
          this.dialog2 = false;
        })
        .catch((error) => {
          this.create_finance.error = error.response.data.message;
          this.create_finance_errors = error.response.data.errors;

          catchAxiosExceptions(error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.finances.indexOf(item);
      this.create_finance = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/income-report/" + this.create_finance.slug)
        .then((response) => {
          this.create_finance.response = response.data.message;

          this.initFinance();
          this.closeDelete();
        })
        .catch((error) => {
          this.create_finance.error = error.response;
          catchAxiosExceptions(error);
        });
    },

    close() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.create_finance = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.create_finance = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  mounted() {
    this.initFinance();
  },
};
</script>

<style>
h3 {
  font-size: 24px !important;
}
/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
