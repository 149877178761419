export default [
  {
    menu: "Dashboard",
    subMenus: [],
    data: {
      icon: "mdi-view-dashboard-outline",
      route: "AdminDashboard",
    },
  },
  {
    menu: "Reports",
    subMenus: [

      {
        menu: "Create Service Report",
        subMenus: [],
        data: {
          icon: "mdi-file-chart-outline",
          route: "AdminCreateServiceReport",
        },
      },
      {
        menu: "Service report",
        subMenus: [],
        data: {
          icon: "mdi-file-chart-outline",
          route: "AdminServiceReport",
        },
      },
      // {
      //   menu: "Monthly/yearly Report",
      //   subMenus: [],
      //   data: {
      //     icon: "mdi-account-multiple",
      //     route: "/monthly-report"
      //   }
      // },

      // {
      //   menu: "Global Reports",
      //   subMenus: [],
      //   data: {
      //     icon: "mdi-account-multiple",
      //     route: "AdminGlobalReports",
      //   },
      // },
      {
        menu: "Branch Monthly Reports",
        subMenus: [],
        data: {
          icon: "mdi-file-chart-outline",
          route: "AdminBranchMonthlyReports",
        },
      },
      {
        menu: "Range Reports",
        subMenus: [],
        data: {
          icon: "mdi-file-chart-outline",
          route: "adminBranchRangeReport",
        },
      },
      {
        menu: "Global Reports",
        subMenus: [],
        data: {
          icon: "mdi-file-chart-outline",
          route: "AdminGlobalReports",
        },
      },

      {
        menu: "Member Demographics",
        subMenus: [],
        data: {
          icon: "mdi-file-chart-outline",
          route: "AdminMemberDemographics",
        },
      },
      {
        menu: "Approved Months",
        subMenus: [],
        data: {
          icon: "mdi-check-outline",
          route: "AdminApprovedMonths",
        },
      },

    ],
    data: {
      icon: "mdi-file-chart-outline",
      route: "reports",
    },
  },

  {
    menu: "Personnel Management",
    subMenus: [
      {
        menu: "Pastors",
        subMenus: [],
        data: {
          icon: "mdi-account-outline",
          route: "AdminPastors",
        },
      },

      {
        menu: "Members",
        subMenus: [],
        data: {
          icon: "mdi-account-outline",
          route: "AdminMembers",
        },
      },
      {
        menu: "Add Member",
        subMenus: [],
        data: {
          icon: "mdi-account-outline",
          route: "AdminAddMember",
        },
      },
      {
        menu: "Import Members",
        subMenus: [],
        data: {
          icon: "mdi-account-outline",
          route: "memberBulkImport",
        },
      },
      {
        menu: "Staff",
        subMenus: [],
        data: {
          icon: "mdi-account-outline",
          route: "AdminStaff",
        },
      },

      {
        menu: "Add Staff",
        subMenus: [],
        data: {
          icon: "mdi-account-outline",
          route: "AdminAddStaff",
        },
      },
      {
        menu: "First Timers",
        subMenus: [],
        data: {
          icon: "mdi-account-multiple-outline",
          route: "AdminFirstTimers",
        },
      },

      // {
      //   menu: "Roles",
      //   subMenus: [],
      //   data: {
      //     icon: "mdi-account",
      //     route: "AdminRoles",
      //   },
      // },
    ],
    data: {
      icon: "mdi-account-multiple-outline",
      route: "/route-here",
    },
  },

  {
    menu: "Branches",
    subMenus: [
      // {
      //   menu: "Add Branch",
      //   subMenus: [],
      //   data: {
      //     icon: "mdi-home",
      //     route: "AdminCreateBranch",
      //   },
      // },
      {
        menu: "View Branches",
        subMenus: [],
        data: {
          icon: "mdi-home-city-outline",
          route: "AdminBranches",
        },
      },
    ],
    data: {
      icon: "mdi-home-city-outline",
      route: "AdminBranches",
    },
  },


  {
    menu: "Finance",
    subMenus: [
      {
        menu: "Income Title",
        subMenus: [],
        data: {
          icon: "mdi-cash-multiple",
          route: "AdminIncomeTitle",
        },
      },

      // {
      //   menu: "Finance",
      //   subMenus: [],
      //   data: {
      //     icon: "mdi-account-multiple",
      //     route: "/finance"
      //   }
      // },

      {
        menu: "Additional Income",
        subMenus: [],
        data: {
          icon: "mdi-cash-multiple",
          route: "AdminAdditionalIncome",
        },
      },
      {
        menu: "Expense Title",
        subMenus: [],
        data: {
          icon: "mdi-cash-multiple",
          route: "AdminExpenseTitle",
        },
      },
      {
        menu: "Expenses",
        subMenus: [],
        data: {
          icon: "mdi-cash-multiple",
          route: "AdminExpenses",
        },
      },
    ],
    data: {
      icon: "mdi-cash-multiple",
      route: "AdminFinance",
    },
  },

  {
    menu: "Manage Users",
    subMenus: [
      {
        menu: "Roles",
        subMenus: [],
        data: {
          icon: "mdi-account-multiple-outline",
          route: "AdminRoles",
        },
      },
      {
        menu: "Users",
        subMenus: [],
        data: {
          icon: "mdi-account-multiple-outline",
          route: "AdminUsers",
        },
      },
      {
        menu: "Get All Users",
        subMenus: [],
        data: {
          icon: "mdi-account-multiple-outline",
          route: "AdminAllUsers",
        },
      },

      {
        menu: "Get All Admin",
        subMenus: [],
        data: {
          icon: "mdi-account-multiple-outline",
          route: "GetAllAdmin",
        },
      },

    ],
    data: {
      icon: "mdi-account-multiple-outline",
      route: "AdminFinance",
    },
  },

  {
    menu: "Setup Menu",
    subMenus: [
      {
        menu: "Add Branch",
        subMenus: [],
        data: {
          icon: "mdi-home-city-outline",
          route: "AdminCreateBranch",
        },
      },

      {
        menu: "Add Service",
        subMenus: [],
        data: {
          icon: "mdi-file-outline",
          route: "AdminServices",
        },
      },



      {
        menu: "Income Title",
        subMenus: [],
        data: {
          icon: "mdi-cash-multiple",
          route: "AdminIncomeTitle",
        },
      },
      {
        menu: "Expense Title",
        subMenus: [],
        data: {
          icon: "mdi-cash-multiple",
          route: "AdminExpenseTitle",
        },
      },

      {
        menu: "Add Pastor",
        subMenus: [],
        data: {
          icon: "mdi-account-multiple-outline",
          route: "AdminAddPastor",
        },
      },

    ],
    data: {
      icon: "mdi-account-multiple-outline",
      route: "AdminFinance",
    },
  },
  {
    menu: "Communication",
    subMenus: [
      
      {
        menu: "Email",
        subMenus: [],
        data: {
          icon: "mdi-email-outline",
          route: "emailCommunication",
        },
      },
      {
        menu: " SMS",
        subMenus: [],
        data: {
          icon: "mdi-message-processing-outline",
          route: "smsCommunication",
        },
      },
      {
        menu: " Follow up",
        subMenus: [],
        data: {
          icon: "mdi-folder-plus-outline",
          route: "followUpCommunication",
        },
      },
    ],
    data: {
      icon: "mdi-comment-multiple-outline",
      route: "/route-here",
    },
  },

  // {
  //   menu: "Subscription",
  //   subMenus: [
  //     {
  //       menu: "Create Subscription",
  //       subMenus: [],
  //       data: {
  //         icon: "mdi-cash-multiple",
  //         route: "AdminCreateSubscription",
  //       },
  //     },

  //     {
  //       menu: "Current Subscription",
  //       subMenus: [],
  //       data: {
  //         icon: "mdi-cash-multiple",
  //         route: "AdminCurrentSubscription",
  //       },
  //     },


  //     {
  //       menu: "View All Subscription",
  //       subMenus: [],
  //       data: {
  //         icon: "mdi-account-multiple-outline",
  //         route: "AdminViewAllSubscription",
  //       },
  //     },

  //     {
  //       menu: "Subscription Plans",
  //       subMenus: [],
  //       data: {
  //         icon: "mdi-account-multiple-outline",
  //         route: "AdminSubscriptionPlans",
  //       },
  //     },
  //    ],
  //   data: {
  //     icon: "mdi-cash-multiple",
  //     route: "AdminSubscription",
  //   },
  // },



];
