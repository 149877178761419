import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userEmail: "",

    userPermissions: [],
    userDetail: {
      user: JSON.parse(localStorage.getItem("user")),
      token: "",
    },

    adminBranch: [
      {
        branch: {},
        token: "",
      },
    ],
  },
  mutations: {
    initializeStore: function (state) {
      if (localStorage.getItem("token") && localStorage.getItem("user")) {
        state.userDetail.token = localStorage.getItem("token");
        state.userDetail.user = JSON.parse(localStorage.getItem("user"));
      } else {
        state.userDetail.token = "";
        state.userDetail.user = {};
        state.branch = {};
        // state.service = {};
      }
    },
    setBranch(state, branch) {
      state.branch = branch;
    },
    setService(state, service) {
      state.service = service;
    },
    setServiceReport(state, serviceReport) {
      state.serviceReport = serviceReport;
    },
    setFinance(state, finance) {
      state.finance = finance;
    },
    setRole(state, role) {
      state.role = role;
    },
    setPastor(state, pastor) {
      state.pastor = pastor;
    },
    setExpense(state, expense) {
      state.expense = expense;
    },
    // setExpenseTitle(state, expenseTitle) {
    //   state.expenseTitle = expenseTitle
    // },

    setUserEmail(state, userEmail) {
      localStorage.setItem("userEmail", userEmail);
    },

    removeUserEmail(state) {
      localStorage.removeItem("userEmail");

      state.userEmail = "";
    },

    

    setUserDetail(state, userDetail) {
      localStorage.setItem("token", userDetail.token);
      localStorage.setItem("user", JSON.stringify(userDetail.user));

      state.userDetail.token = "";
      state.userDetail.user = userDetail.user;
    },


    removeUserDetail(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      state.userDetail.token = "";
      state.userDetail.user = {};
    },


    allPermissions(state) {
      const user = state.userDetail.user;
      const userRoles = user.roles;

      userRoles.forEach(eachRole => {
        let eachRolePermissions = eachRole.permissions;
        eachRolePermissions.forEach((permissions) => {
          //  console.log(permissions.permission);
          state.userPermissions.push(permissions.permission);
          //  console.log(permissionsArray);
          return state.userPermissions;
        });
      });
      // console.log(state.userPermissions);
    },

    removeAllPermissions(state) {
      state.userPermissions = [];
    }


  },
  actions: {

  },
  modules: {},
  getters: {
    getUserDetail: (state) => {
      return state.userDetail
    },

    getUserEmail: (state) =>
      (state.userEmail = localStorage.getItem("userEmail")),
    //  permissions

    noRoles: (state) => {
      return state.userPermissions.length === 0;
    },
    canManageServiceReport: (state) => {
      return state.userPermissions.includes('manage_service_report');
    },
    canViewServiceReport: (state) => {
      return state.userPermissions.includes('view_service_report');
    },
    canViewIncomeReport: (state) => {
      return state.userPermissions.includes('view_income_report');
    },
    canManageIncomeReport: (state) => {
      return state.userPermissions.includes('manage_income_report');
    },
    canViewIncomeTitles: (state) => {
      return state.userPermissions.includes('view_income_title');
    },
    canManageIncomeTitles: (state) => {
      return state.userPermissions.includes('manage_income_title');
    },
    canViewExpenseReport: (state) => {
      return state.userPermissions.includes('view_expense_report');
    },
    canManageExpenseReport: (state) => {
      return state.userPermissions.includes('manage_expense_report');
    },
    canManageReports: (state) => {
      return state.userPermissions.includes('manage_report');
    },
    canViewReports: (state) => {
      return state.userPermissions.includes('view_report');
    },
    canViewMembers: (state) => {
      return state.userPermissions.includes('view_members');
    },
    canManageMembers: (state) => {
      return state.userPermissions.includes('manage_members');
    },
    canViewStaffs: (state) => {
      return state.userPermissions.includes('view_staff');
    },
    canManageStaffs: (state) => {
      return state.userPermissions.includes('manage_staff');
    },
    canViewPastors: (state) => {
      return state.userPermissions.includes('view_pastors');
    },
    canManagePastors: (state) => {
      return state.userPermissions.includes('manage_pastors');
    },
    canViewUsers: (state) => {
      return state.userPermissions.includes('view_users');
    },
    canManageUsers: (state) => {
      return state.userPermissions.includes('manage_users');
    },
    canViewRoles: (state) => {
      return state.userPermissions.includes('view_roles');
    },
    canManageRoles: (state) => {
      return state.userPermissions.includes('manage_roles');
    },
    canViewAnnouncement: (state) => {
      return state.userPermissions.includes('view_announcement');
    },
    canManageAnnouncement: (state) => {
      return state.userPermissions.includes('manage_announcement');
    },

    canManageBirthday: (state) => {
      return state.userPermissions.includes('manage_birthday');
    },

    isAdmin: (state) => {
      return state.userPermissions.includes('manage_service_report') && state.userPermissions.includes('view_service_report') && state.userPermissions.includes('manage_income_report') && state.userPermissions.includes('view_income_report') && state.userPermissions.includes('manage_expense_report') && state.userPermissions.includes('view_expense_report') && state.userPermissions.includes('manage_members') && state.userPermissions.includes('view_members') && state.userPermissions.includes('manage_staff') && state.userPermissions.includes('view_staff') && state.userPermissions.includes('manage_users') && state.userPermissions.includes('view_users') && state.userPermissions.includes('manage_income_title') && state.userPermissions.includes('view_income_title') && state.userPermissions.includes('manage_announcement') && state.userPermissions.includes('view_announcement') && state.userPermissions.includes('manage_birthday') && state.userPermissions.includes('manage_roles') && state.userPermissions.includes('view_roles') && state.userPermissions.includes('manage_report') && state.userPermissions.includes('view_report') && state.userPermissions.includes('manage_pastors') && state.userPermissions.includes('view_pastors');
    }


  },
});
