<template>
  <v-container class="fill-height d-background" fluid>
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5" lg="4">
        <a href="https://smartchurch.ng/" target="_blank">
          <v-img
            src="@/assets/smartchurch-logo.png"
            max-height="180"
            max-width="180"
            class="d-block mx-auto mb-6"
          ></v-img>
        </a>
        <v-card class="pa-6 rounded-lg">
          <v-form class="px-5" ref="form" @submit.prevent="loginForm">
            <h3 class="text-center">Login</h3>
            <label><v-icon>mdi-email</v-icon>email</label>
            <v-text-field
              v-model="login_form.email"
              :error-messages="login_form_errors.email"
              outlined
              dense
            ></v-text-field>
            <label><v-icon>mdi-lock</v-icon>password</label>
            <v-text-field
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              v-model="login_form.password"
              :error-messages="login_form_errors.password"
              outlined
              dense
            ></v-text-field>
            <v-btn
              type="submit"
              block
              class="white--text"
              color="primary white--text"
              :loading="isSubmitted"
              :disabled="isSubmitted"
              >login</v-btn
            >
            <div class="alert alert-danger mt-3" v-if="login_form.error !== ''">
              {{ login_form.error }}
            </div>
            <div
              class="alert alert-success mt-3"
              v-if="login_form.success !== ''"
            >
              {{ login_form.success }}
            </div>
            <v-card-text class="text-center content-paragraph">
              Don't have an account yet?
              <router-link :to="{ name: 'sign up' }">Sign Up</router-link>
              <br />
              <router-link :to="{ name: 'forgotten password' }"
                >Forgot password?</router-link
              >
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import axios from "axios"
export default {
  data() {
    return {
      login_form_errors: [],
      login: [],

      login_form: {
        email: "",
        password: "",
        error: "",
        success: "",
      },
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      show1: false,

      isSubmitted: false,
    };
  },

  methods: {
    initPage() {
      // get the error message
      this.getErrorMessage();

      // if signup successful
      // display successful message
      this.login_form.success = "";
      let routeQuery = this.$route.query.signup;

      if (routeQuery && routeQuery !== undefined) {
        this.login_form.success = "Signup successful. Please login";
      }
    },

    getErrorMessage() {
      const errorMessage = localStorage.getItem("errorMessage");

      if (errorMessage) {
        this.snackbar.status = true;
        this.snackbar.msg = "Token expired, please login again";
        this.snackbar.color = "info";

        localStorage.removeItem("errorMessage");
      }
    },

    loginForm() {
      this.isSubmitted = true;
      localStorage.removeItem("hasLoaded");

      this.$store.commit("removeUserDetail");
      this.login_form.error = "";

      this.$http
        .post("login", this.login_form)
        .then((response) => {
          this.isSubmitted = false;
          let resData = response.data.data;

          let userDetail = {
            token: resData.token,
            user: resData.user,
          };

          this.$store.commit("setUserDetail", userDetail);
          this.$http.defaults.headers.Authorization = `Bearer ${userDetail.token}`;

          localStorage.setItem("user", JSON.stringify(userDetail.user));
          localStorage.setItem("token", userDetail.token);

          this.$refs.form.reset();

          this.$router.push({ name: "AdminDashboard" });

          // const user = JSON.parse(localStorage.getItem("user"))

          // let splittedUserTyped = user ? user.user_type.split("\\") : ""
          // let userType = splittedUserTyped[splittedUserTyped.length - 1]

          // if (userType === "church") {
          //   this.$router.push({ name: "AdminDashboard" })
          // } else if (userType === "pastor") {
          //   this.$router.push({ name: "pastorDashboard" })
          // }
        })
        .catch((error) => {
          this.isSubmitted = false;

          if (error.response) {
            this.snackbar.status = true;
            this.snackbar.msg = error.response.data.message;
            this.snackbar.color = "red";
          }
        });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style scoped>
h3 {
  text-align: left;
  padding: 20px;
  color: #c73436;
  font-weight: bold;
}

.v-btn {
  background: rgba(203, 68, 71, 1) !important;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  height: 40px !important;
}
label,
p {
  margin: 10px;
  text-transform: capitalize !important;
}
label span {
  color: rgba(203, 68, 71, 1);
  font-size: 1.3em;
}
a {
  text-decoration: none;
}
.d-background {
  background: #fef6f6;
}
</style>
