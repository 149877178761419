<template>
  <v-container class="fill-height d-background" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <div class="d-flex justify-center">
          <v-img
            src="@/assets/404 error.png"
            max-width="350"
            max-height="350"
          ></v-img>
        </div>

        <div class="text-center">
          <h4>404 Page Not Found</h4>
          <v-btn
            depressed
            block
            color="primary white--text mt-2"
            :to="{ name: 'Login' }"
            >Login</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
h4 {
  padding: 15px;
  color: #c73436;
  font-weight: bold;
}

.v-btn {
  background: rgba(203, 68, 71, 1) !important;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  height: 40px !important;
}
label,
p {
  margin: 10px;
  text-transform: capitalize !important;
}
label span {
  color: rgba(203, 68, 71, 1);
  font-size: 1.3em;
}
a {
  text-decoration: none;
}
.d-background {
  background: #fef6f6;
}
</style>
