<template>
  <smart-church-layout page="Expenses Title">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Expense Title "
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <h3 class="primary--text font-weight-bold d-lg-none mt-6">
        Expense Title
      </h3>

      <div class="d-flex mt-10 mb-5">
        <h3 class="primary--text font-weight-bold d-none d-lg-block">
          Expense Title
        </h3>
        <v-dialog v-model="dialog" max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-5 font-weight-bold"
              v-bind="attrs"
              v-on="on"
              color="primary text--white"
              v-if="
                canManageUsers ||
                canViewUsers ||
                canManageRoles ||
                canViewRoles ||
                noRoles
              "
              ><v-icon>mdi-plus</v-icon> create expense title</v-btn
            >
          </template>
          <v-card class="rounded-lg text-capitalize">
            <v-form class="pa-10" ref="form" @submit="createExpenseTitle">
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                create expense title
              </h2>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="Name"
                    id="name"
                    v-model="expense_title.name"
                    :error-messages="expense_title_errors.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Create</v-btn
              >

              <p class="text-center my-3">
                <v-btn depressed small @click="cancel">Close</v-btn>
              </p>
            </v-form>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Expense Title "
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

      <div class="alert alert-danger my-3" v-if="mountedError">
        Data could not be loaded: {{ mountedError }}
        <v-btn
          color="primary white--text"
          :loading="isLoading"
          @click="displayExpensesTitle"
        >
          Fetch again
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="expensesTitle"
        :search="search"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="500px">
              <v-card class="p-4">
                <!-- <v-card-title class="d"> -->
                <h2 class="mb-4 primary--text font-weight-bold text-center">
                  Update Expenses Title
                </h2>
                <!-- </v-card-title> -->

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          label="Name"
                          v-model="expense_title.name"
                          :error-messages="expense_title_errors.name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn
                      color="primary"
                      block
                      @click="save"
                      :loading="isSubmitted"
                      :disabled="isSubmitted"
                    >
                      Update
                    </v-btn>
                  </v-container>
                </v-card-text>

                <p class="text-center">
                  <v-btn @click="close" depressed> Close </v-btn>
                </p>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="550px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this expense
                  title?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="
                  canManageUsers ||
                  canViewUsers ||
                  canManageRoles ||
                  canViewRoles ||
                  noRoles
                "
              >
                <v-list-item-title>
                  <a @click="editItem(item)"
                    ><v-icon small class="mr-2"> mdi-pencil </v-icon>Edit</a
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  canManageUsers ||
                  canViewUsers ||
                  canManageRoles ||
                  canViewRoles ||
                  noRoles
                "
              >
                <v-list-item-title>
                  <a @click="deleteItem(item)"
                    ><v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
import skeletonLoader from "../../components/DataTableLoader.vue";
import { mapGetters } from "vuex";

export default {
  components: { SmartChurchLayout, skeletonLoader },

  data() {
    return {
      search: "",
      dialog: false,

      dialog2: false,
      dialogDelete: false,

      headers: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      expenses: [],

      overlay: true,
      sortDesc: true,

      expense_title: {
        name: "",

        response: "",
        error: "",
      },

      defaultItem: {
        name: "",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      response: "",

      expense_title_errors: {},

      expensesTitle: [],

      editedIndex: -1,

      isLoading: false,
      mountedError: null,

      user: this.$store.state.userDetail.user,

      userPermissionNames: [],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Edit Expense Title"
        : "Edit Expense Title";
    },

    ...mapGetters([
      "noRoles",
      "canManageUsers",
      "canViewUsers",
      "canManageRoles",
      "canViewRoles",
    ]),
  },

  watch: {
    dialog2(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    displayExpensesTitle() {
      this.isLoading = true;

      this.$http
        .get("admin/expense-title")
        .then((response) => {
          this.expensesTitle = response.data.data;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.mountedError = error.response.data.message;
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

    createExpenseTitle() {
      this.expense_title.error = "";
      this.expense_title.response = "";

      this.isSubmitted = true;

      this.$http
        .post("admin/expense-title", this.expense_title)
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.displayExpensesTitle();
          this.$refs.form.reset();

          this.cancel();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.isSubmitted = false;
          this.expense_title_errors = error.response.data.errors;

          catchAxiosExceptions(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.expenses.indexOf(item);
      this.expense_title = Object.assign({}, item);
      this.dialog2 = true;
    },

    save() {
      this.isSubmitted = true;

      this.$http
        .put(
          "admin/expense-title/" + this.expense_title.slug,
          this.expense_title
        )
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.displayExpensesTitle();
          this.close();
        })
        .catch((error) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.expense_title_errors = error.response.data.errors;
          catchAxiosExceptions(error);
        });
    },

    cancel() {
      this.dialog = false;
    },

    deleteItem(item) {
      this.editedIndex = this.expenses.indexOf(item);
      this.expense_title = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/expense-title/" + this.expense_title.slug)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.displayExpensesTitle();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          catchAxiosExceptions(error);
        });
    },

    close() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.expense_title = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.expense_title = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  created() {
    this.$store.commit("allPermissions");
  },

  mounted() {
    this.displayExpensesTitle();
  },
};
</script>

<style>
.bg-transparent {
  background: transparent;
}
h3 {
  font-size: 24px !important;
}
/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
