<template>
  <smart-church-layout page="First Timers">
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout">{{ snackbar.msg
    }}</v-snackbar>
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <v-container class="my-8">
      <div class="d-flex my-5">
        <h3 class="primary--text font-weight-bold">First Timers</h3>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-5 font-weight-bold primary elevation-2" v-bind="attrs" v-on="on" color="text--white"
              :to="{ name: 'AdminAddFirstTimers' }" v-if="canManageStaffs || canManageMembers || noRoles"
              text><v-icon>mdi-plus</v-icon> Add First Timer</v-btn>
          </template>
          <v-card class="rounded-lg text-capitalize">
            <v-form class="pa-9" ref="form">
              <h2 class="mb-4 primary--text d-flex justify-center font-weight-bold">
                create first timer
              </h2>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field outlined dense label="Date" type="date" id="Date" v-model="create_first_timers.date"
                    :error-messages="create_first_timers.date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="create_first_timers.first_name" outlined dense label="First Name"
                    :error-messages="create_first_timers_errors.first_name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="create_first_timers.last_name" outlined dense label="Last Name"
                    :error-messages="create_first_timers_errors.last_name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense label="Phone Number" type="number" v-model="create_first_timers.phone"
                    :error-messages="create_first_timers_errors.phone"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined dense label="Email" type="email" v-model="create_first_timers.email"
                    :error-messages="create_first_timers_errors.email"></v-text-field>
                </v-col>
              </v-row>
              <v-btn color="primary white--text" block>Create</v-btn>
            </v-form>
          </v-card>
        </v-dialog>
      </div>


      <v-data-table :headers="headers" :items="first_timers" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="600px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-form class="pa-9" ref="form">
                      <h2 class="mb-4 primary--text d-flex justify-center font-weight-bold">
                        Update first timer
                      </h2>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="create_first_timers.name" outlined dense label="Name"
                            :error-messages="create_first_timers_errors.name"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-btn color="primary white--text" block>Update</v-btn>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>


        <template v-slot:[`item.transitions`]="{ item }">
          <v-chip
          :style="{ backgroundColor: 'orange' }"
            class="me-3 text-capitalize"
            v-for="(eachTransition, index) in item.transitions"
            :key="eachTransition.id"
            v-show="index == item.transitions.length - 1"
          >
            {{ eachTransition.name }}
          </v-chip>
        </template>



        <template v-slot:[`item.amount`]="{ item }">
          ₦{{ item.amount }}
        </template>
        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template> -->

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent" icon>
                <v-icon>mdi-phone-outline</v-icon>
              </v-btn>
            </template>
            <v-list v-if="canManageStaffs || canManageMembers || noRoles">
              <v-list-item>
                <v-list-item-title>
                  <a :href="`tel:${item.phone}`" class="phone text-decoration-none text-secondary">
                    <v-icon small class="mr-2"> mdi-phone-plus </v-icon>Call</a>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <a @click="openWhatsApp(item.phone)" color="success" class="text-decoration-none text-secondary">
                    <v-icon small class="mr-2"> mdi-whatsapp </v-icon>Whatsapp</a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent" icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canManageStaffs || canManageMembers || noRoles">
                <v-list-item-title>
                  <router-link class="text-decoration-none" :to="{
                    name: 'AdminEditFirstTimers',
                    params: { firstTimer_slug: item.slug },
                  }">
                    <v-icon small class="mr-2"> mdi-pencil </v-icon>

                    <span class="textColor--text">Edit</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canViewStaffs || canViewMembers || noRoles || canManageStaffs || canManageMembers">
                <v-list-item-title>
                  <router-link class="text-decoration-none" :to="{
                    name: 'AdminViewFirstTimers',
                    params: { firstTimer_slug: item.slug },
                  }">
                    <v-icon small class="mr-2"> mdi-eye </v-icon>

                    <span class="textColor--text">View</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canManageStaffs || canManageMembers || noRoles">
                <v-list-item-title>
                  <!-- <v-tooltip bottom> -->
                  <!-- <template v-slot:activator="{ on, attrs }"> -->

                  <!-- </template> -->
                  <a @click="deleteItem(item)">
                    <v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a>
                  <!-- </v-tooltip> -->
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/DataTableLoader.vue"
import { mapGetters } from 'vuex';


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      switch1: true,
      overlay: true,
      headers: [
        { text: "Date", value: "attendance_date" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Branch", value: "branch.name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: 'Transitions', value: 'transitions' },
        { text: "Actions", value: "actions", sortable: false },
      ],

      first_timers: [],

      create_first_timers: {},

      incomeTitles: [],
      display_finance_error: [],
      income_title_error: [],

      editedIndex: -1,
      create_first_timers_errors: {},

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isLoading: false,
      mountedError: null,
      user: this.$store.state.userDetail.user,

      userPermissionNames: [],
      getMigratedMembers: []
    }
  },

  watch: {
    dialog2(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  computed: {
    ...mapGetters(['canViewMembers', 'canManageMembers', 'canManageStaffs', 'canViewStaffs', 'noRoles', 'isAdmin']),
  },
  methods: {
    async initFirstTimers() {
      this.isLoading = true

      try {
        const displayFirstTimers = await this.$http.get("admin/firstTimers")
        this.first_timers = displayFirstTimers.data.data
        console.log(this.first_timers)
        const migratedMembers = await this.$http.gety("admin/firstTimerMigrated")
        this.getMigratedMembers = migratedMembers.data.data
        console.log(this.getMigratedMembers)

        this.isLoading = false
        this.overlay = false
      } catch (error) {
        // this.mountedError = error.response.data.message
        this.isLoading = false
        this.overlay = false

        catchAxiosExceptions(error)
      }
    },

    editItem(item) {
      this.editedIndex = this.finances.indexOf(item)
      this.create_first_timers = Object.assign({}, item)
      this.dialog2 = true
    },

    deleteItem(item) {
      this.editedIndex = this.first_timers.indexOf(item)
      this.create_first_timers = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/firstTimers/" + this.create_first_timers.slug)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"
          // this.create_first_timers.response = response.data.message

          this.closeDelete()
          this.initFirstTimers()
        })
        .catch((error) => {
          // this.create_first_timers.error = error.response
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
          catchAxiosExceptions(error)
        })
    },

    close() {
      this.dialog2 = false
      this.$nextTick(() => {
        this.create_first_timers = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.create_first_timers = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    openWhatsApp(phone) {
      const whatsappUrl = `https://wa.me/${phone}`

      // Open the WhatsApp URL in a new tab or redirect the user to the WhatsApp application
      window.open(whatsappUrl)
    },
  },
  created() {
    this.$store.commit('allPermissions');

  },

  mounted() {
    this.initFirstTimers()
  },
}
</script>

<style>
h3 {
  font-size: 24px !important;
}

/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
