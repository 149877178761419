<template>
  <smart-church-layout>
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <div v-if="overlay">
      <skeletonLoader />
    </div> -->
    <v-container class="background my-8">
      <div>
        <div>
          <h1>Welcome Back!</h1>
          <p class="text-capitalize">
            {{ userDetails.first_name ? userDetails.first_name : "" }}
            {{ userDetails.last_name ? userDetails.last_name : "" }}
          </p>
          <p class="text-capitalize">{{ churchName }}</p>
        </div>
        <div>
          <v-row class="d-flex justify-content-end">
            <v-col cols="8" lg="4">
              <v-autocomplete
                dense
                outlined
                rounded
                :items="branches"
                item-value="id"
                item-text="name"
                label="Select Branch"
                v-model="branch_id"
                @change="setCurrentBranch"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row>
        <v-col cols="12" class="my-5">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initAdminDashboard"
            >
              Fetch again
            </v-btn>
          </div>

          <v-row align="center" justify="center">
            <v-col
              cols="6"
              md="4"
              v-if="
                canManageUsers ||
                canViewUsers ||
                canManageRoles ||
                canViewRoles ||
                noRoles
              "
            >
              <v-card class="p-3" :to="{ name: 'AdminBranches' }">
                <p class="mb-0 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-home-city-outline</v-icon
                  >
                </p>
                <p class="fs-1 text-center mb-0">
                  {{
                    getFormattedAmount(
                      dashboardTotalBranches ? dashboardTotalBranches : "0"
                    )
                  }}
                </p>
                <p class="fs-5 text-secondary text-center">Total Branches</p>
              </v-card>
            </v-col>

            <v-col cols="6" md="4" v-if="canViewMembers || noRoles">
              <v-card class="p-3" :to="{ name: 'AdminMembers' }">
                <p class="mb-0 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-account-group-outline</v-icon
                  >
                </p>
                <p class="fs-1 text-center mb-0">
                  {{
                    getFormattedAmount(
                      dashboardTotalMembers ? dashboardTotalMembers : "0"
                    )
                  }}
                </p>
                <p class="fs-5 text-secondary text-center">Total Members</p>
              </v-card>
            </v-col>

            <v-col cols="6" md="4" v-if="canViewStaffs || noRoles">
              <v-card class="p-3" :to="{ name: 'AdminStaff' }">
                <p class="mb-0 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-account-group-outline</v-icon
                  >
                </p>
                <p class="fs-1 text-center mb-0">
                  {{
                    getFormattedAmount(
                      dashboardTotalStaff ? dashboardTotalStaff : "0"
                    )
                  }}
                </p>
                <p class="fs-5 text-secondary text-center">Total Staffs</p>
              </v-card>
            </v-col>

            <v-col
              cols="6"
              v-if="
                canViewMembers || canManageReports || canViewReports || noRoles
              "
            >
              <v-card class="p-3">
                <p class="mb-0 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-account-group-outline</v-icon
                  >
                </p>
                <p class="fs-1 text-center mb-0">
                  {{
                    getFormattedAmount(
                      dashboardFirstTimers ? dashboardFirstTimers : "0"
                    )
                  }}
                </p>
                <p class="fs-5 text-secondary text-center">
                  First Timers for Current Month
                </p>
              </v-card>
            </v-col>

            <v-col
              cols="6"
              v-if="
                canViewMembers || canManageReports || canViewReports || noRoles
              "
            >
              <v-card class="p-3">
                <p class="mb-0 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-account-group-outline</v-icon
                  >
                </p>
                <p class="fs-1 text-center mb-0">
                  {{
                    getFormattedAmount(
                      dashboardNewConverts ? dashboardNewConverts : "0"
                    )
                  }}
                </p>
                <p class="fs-5 text-secondary text-center">
                  New Converts for Current Month
                </p>
              </v-card>
            </v-col>

            <v-col
              cols="6"
              md="6"
              v-if="canViewIncomeReport || canViewExpenseReport || noRoles"
            >
              <v-card class="p-4">
                <p class="mb-3 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-cash-multiple</v-icon
                  >
                </p>
                <p class="fs-3 text-center mb-0">
                  ₦{{
                    dashboardTotalIncome
                      ? getFormattedAmount(Number(dashboardTotalIncome))
                      : "0"
                  }}
                </p>
                <p class="fs-5 text-secondary text-center mb-0">
                  Total Income for Current Month
                </p>
              </v-card>
            </v-col>

            <v-col
              cols="6"
              md="6"
              v-if="canViewExpenseReport || canViewIncomeReport || noRoles"
            >
              <v-card class="p-4">
                <p class="mb-3 text-center">
                  <v-icon color="primary" class="fs-7 mx-auto w-50"
                    >mdi-cash-multiple</v-icon
                  >
                </p>
                <p class="fs-3 text-center mb-0">
                  ₦{{
                    dashboardTotalExpense
                      ? getFormattedAmount(dashboardTotalExpense)
                      : "0"
                  }}
                </p>
                <p class="fs-5 text-secondary text-center mb-0">
                  Total Expense for Current Month
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="12" md="4" sm="12" class="my-5 p-3">
          <v-card flat rounded="lg">
            <v-date-picker
              v-model="picker"
              color="primary"
              full-width
            ></v-date-picker>
          </v-card>

        
        </v-col> -->

        <v-col
          cols="12"
          v-if="canViewIncomeReport || canViewExpenseReport || noRoles"
        >
          <v-row align="center" justify="center">
            <v-col cols="12">
              <h5
                class="primary text-white p-2 mb-0 text-center fs-4 text-uppercase fw-bold"
              >
                Income and Expense Chart
              </h5>
              <v-card class="p-3">
                <bar-chart
                  :chart-data="{
                    labels: [dashboardTotal.name],
                    datasets: [
                      {
                        label: 'Income',
                        backgroundColor: '#3366CC',
                        data: [dashboardTotal.total_income],
                      },
                      {
                        label: 'Expenditure',
                        backgroundColor: '#880808',
                        data: [dashboardTotal.total_expense],
                      },
                    ],
                  }"
                  :chartOptions="{
                    responsive: true,
                    maintainAspectRatio: false,
                  }"
                />
                <p class="text-center pt-4 fw-bold fs-5">
                  {{ incomeExpensePercentage ? incomeExpensePercentage : "0" }}%
                  of Income was spent on Expenditure.
                </p>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              lg="6"
              v-if="canViewIncomeReport || canViewExpenseReport || noRoles"
            >
              <h5
                class="primary text-white p-2 fs-4 text-uppercase mb-0 text-center fw-bold"
              >
                Income Chart
              </h5>
              <v-card class="p-3">
                <pie-chart
                  :chart-data="incomePieChartData"
                  :chartOptions="{
                    responsive: true,
                    maintainAspectRatio: false,
                  }"
                />
                <p class="text-center p-2 fw-bold fs-5 pt-4">
                  This shows all Income for the Church
                </p>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-if="canViewIncomeReport || canViewExpenseReport || noRoles"
            >
              <h5
                class="primary text-white p-2 fs-4 text-uppercase mb-0 text-center fw-bold"
              >
                Expense Chart
              </h5>
              <v-card class="p-3">
                <pie-chart
                  :chart-data="expensePieChartData"
                  :chartOptions="{
                    responsive: true,
                    maintainAspectRatio: false,
                  }"
                />
                <p class="text-center p-2 fw-bold fs-5 pt-4">
                  This shows all Expenditure for the Church
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedDate,
  getFormattedAmount,
  getReportYears,
  // getLastTwoMonthsAndToday,
} from "../../__helpers/handelExceptions";
import BarChart from "../../components/charts/BarChart.vue";
import PieChart from "../../components/charts/PieChart.vue";
import { mapGetters } from "vuex";
// import skeletonLoader from "../../components/DashboardSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout, BarChart, PieChart },

  data() {
    return {
      theme: "light",
      userDetails: [],
      userRoles: [],
      userPermissions: [],
      churchName: "",
      branch_id: "",
      user: this.$store.state.userDetail.user,
      homeDashboardEndpoint: "",

      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      overlay: true,

      membersBirthday: [],
      staffBirthday: [],

      dashboardTotal: [],
      showBirthday: [],
      dashboardTotalExpense: 0,
      dashboardTotalIncome: 0,

      totalIncome: 0,

      birthdays: [],
      branches: [],

      incomeExpenseChart: [],
      incomeExpenseChartData: [],
      dashboardTotalBranches: 0,
      dashboardTotalStaff: 0,
      dashboardTotalMembers: 0,
      dashboardTotalPastors: 0,
      totalFirstTimers: 0,
      dashboardFirstTimers: 0,
      dashboardNewConverts: 0,
      isSubmitted: false,

      // totalNewConvertdashboard

      incomeExpensePercentage: 0,

      incomeData: [],
      incomeChartData: [],

      incomePieChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      expenseData: [],
      expenseChartData: [],

      expensePieChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      isLoading: false,

      mountedError: null,
    };
  },

  computed: {
    ...mapGetters([
      "canViewMembers",
      "canViewStaffs",
      "canViewIncomeReport",
      "canViewExpenseReport",
      "noRoles",
      "canManageUsers",
      "canViewUsers",
      "canManageRoles",
      "canViewRoles",
      "canManageReports",
      "canViewReports",
    ]),
  },

  methods: {
    setDefaultBranch() {
      if (this.user.is_admin === true) {
        this.homeDashboardEndpoint = "admin/home";
      }
      if (this.user.is_admin === false) {
        this.branch_id = this.user.branches[0].id;
        this.homeDashboardEndpoint = `admin/home?branch_id=${this.branch_id}`;
      }
      if (this.user.branches.length === 0) {
        this.branch_id = this.user.id;
        this.homeDashboardEndpoint = `admin/home?branch_id=${this.branch_id}`;
      }

      this.initAdminDashboard();
    },
    setCurrentBranch() {
      this.homeDashboardEndpoint = `admin/home?branch_id=${this.branch_id}`;
      this.initAdminDashboard();
    },
    async initAdminDashboard() {
      this.isLoading = true;
      // this.showBirthday = false
      this.overlay = true;
      try {
        // let homeDashboardEndpoint = `admin/home?branch_id=${this.branch_id}`
        const homeDashboard = await this.$http.get(
          `${this.homeDashboardEndpoint}`
        );
        this.isLoading = false;
        this.overlay = false;
        this.dashboardTotal = homeDashboard.data.data;

        // console.log(this.dashboardTotal)

        this.dashboardTotalBranches = this.dashboardTotal.total_branches;
        this.dashboardTotalPastors = this.dashboardTotal.total_pastors;
        this.dashboardTotalMembers = this.dashboardTotal.total_members;
        this.dashboardTotalStaff = this.dashboardTotal.total_staff;
        this.dashboardFirstTimers = this.dashboardTotal.total_first_timers;
        this.dashboardNewConverts = this.dashboardTotal.total_new_converts;
        this.dashboardTotalIncome = this.dashboardTotal.total_income;
        this.dashboardTotalExpense = this.dashboardTotal.total_expense;

        this.incomeExpensePercentage =
          (this.dashboardTotalExpense / this.dashboardTotalIncome) * 100;
        this.incomeExpensePercentage = Math.ceil(this.incomeExpensePercentage);

        const incomesChart = homeDashboard.data.incomes;
        this.incomeData = incomesChart;

        const expenseChart = homeDashboard.data.expenses;
        this.expenseData = expenseChart;

        let incomeArray = [];
        this.incomeData.forEach((income) => {
          if (
            income.income_title.name !== null ||
            income.amount !== null ||
            income.income_title.name != "" ||
            income.amount != ""
          ) {
            incomeArray.push([income.income_title.name, Number(income.amount)]);
          }
          return incomeArray;
        });

        const reducedData = incomeArray.reduce((acc, [income, value]) => {
          if (acc[income]) {
            acc[income] += value;
          } else {
            acc[income] = value;
          }

          return acc;
        }, []);

        this.incomeChartData = reducedData;
        this.incomePieChartData.labels = Object.keys(this.incomeChartData);

        this.incomePieChartData.datasets[0].data = Object.values(
          this.incomeChartData
        );

        this.incomePieChartData.datasets[0].backgroundColor =
          this.generateBackgroundColors(this.incomePieChartData.labels.length);

        let expenseArray = [];
        this.expenseData.forEach((expense) => {
          if (
            expense.expense_title.name !== null ||
            expense.amount !== null ||
            expense.expense_title.name != "" ||
            expense.amount != ""
          ) {
            expenseArray.push([
              expense.expense_title.name,
              Number(expense.amount),
            ]);
          }

          return expenseArray;
        });

        const reducedExpenseData = expenseArray.reduce(
          (acc, [expense, value]) => {
            if (acc[expense]) {
              acc[expense] += value;
            } else {
              acc[expense] = value;
            }

            return acc;
          },
          []
        );

        this.expenseChartData = reducedExpenseData;
        this.expensePieChartData.labels = Object.keys(this.expenseChartData);

        this.expensePieChartData.datasets[0].data = Object.values(
          this.expenseChartData
        );

        this.expensePieChartData.datasets[0].backgroundColor =
          this.generateBackgroundColors(this.expensePieChartData.labels.length);

        // get years for global report
        this.years = getReportYears(this.serviceReports);

        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        // this.mountedError = error.response.data.message
        this.isLoading = false;
        this.overlay = false;

        catchAxiosExceptions(error);
      }
    },

    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },

    generateBackgroundColors(count) {
      const backgroundColors = [];
      // Generate a random color for each label
      for (let i = 0; i < count; i++) {
        const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        backgroundColors.push(color);
      }
      return backgroundColors;
    },
  },
  created() {
    this.$store.commit("allPermissions");
    this.userDetails = this.$store.state.userDetail.user;
    this.branches = this.user.branches;
    // console.log(localStorage.getItem("checkedRatingForm"));
    // this.currentBranch = this.user.id;
    this.setDefaultBranch();
    this.churchName = this.userDetails.church.name;
  },

  // mounted(){
  // }
};
</script>

<style scoped>
/* * {
  outline: 1px solid blue;
} */
h1 {
  font-size: 24px !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.custom-icon {
  width: 10rem;
  height: 10rem;
}

.bg-transparent {
  background-color: transparent;
}

.chart {
  height: 200px;
  width: 100%;
}

.fs-7 {
  font-size: 3rem !important;
}

.center {
  padding-top: 25vh !important;
}
</style>
