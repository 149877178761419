<template>
  <smart-church-layout page="Roles">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Role"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex mb-5 mt-10">
        <h3 class="primary--text font-weight-bold">Roles</h3>
        <v-dialog v-model="dialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-5 font-weight-bold"
              v-bind="attrs"
              v-on="on"
              color="primary text--white"
              v-if="canManageRoles || noRoles"
              ><v-icon>mdi-plus</v-icon> create role</v-btn
            >
          </template>
          <v-card class="rounded-lg text-capitalize">
            <v-form class="pa-10" ref="form" @submit.prevent="createRoles">
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                create role
              </h2>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="Role Name"
                    id="service"
                    v-model="create_roles.name"
                    :error-messages="create_roles_errors.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="mt-0">
                  <div>
                    <v-spacer></v-spacer>
                    <v-checkbox
                      v-model="selectAll"
                      @change="selectAllOptions"
                      label="tick all permissions"
                      class="d-flex justify-end text-right"
                    >
                    </v-checkbox>
                  </div>

                  <v-select
                    v-model="create_roles.permissions"
                    :items="permissions"
                    attach
                    chips
                    label="Permissions"
                    item-text="permission"
                    item-value="id"
                    multiple
                    outlined
                  >
                    <!-- <template v-slot:append>
                      <v-checkbox
                        v-model="selectAll"
                        @change="selectAllOptions"
                      ></v-checkbox>
                    </template> -->
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          {{ item.permission.split("_").join(" ") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                :loading="isSubmitted"
                :disabled="isSubmitted"
                block
                >Create</v-btn
              >

              <p class="text-center my-3">
                <v-btn depressed small @click="cancel">Close</v-btn>
              </p>
            </v-form>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table :headers="headers" :items="roles" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="700px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row class="mt-4">
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          label="Role Name"
                          v-model="create_roles.name"
                          :error-messages="create_roles_errors.name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <div>
                          <v-spacer></v-spacer>
                          <v-checkbox
                            v-model="selectAll"
                            @change="selectAllOptions"
                            label="tick all permissions"
                            class="d-flex justify-end text-right"
                          >
                          </v-checkbox>
                        </div>
                        <v-select
                          v-model="create_roles.permissions"
                          :items="permissions"
                          attach
                          chips
                          label="Permissions"
                          item-text="permission"
                          item-value="id"
                          multiple
                          outlined
                        >
                          <!-- <template v-slot:append>
                            <v-checkbox
                              v-model="selectAll"
                              @change="selectAllOptions"
                            ></v-checkbox>
                          </template> -->
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title class="text-capitalize">
                                {{ item.permission.split("_").join(" ") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close"> Cancel </v-btn>
                  <v-btn color="primary" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="700px">
              <v-card class="pa-5 text-center">
                <v-card-title class="text-h6 text-center"
                  ><p class="text-center">Are you sure you want to delete this role?</p></v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.permissions`]="{ item }">
          <v-chip
            color="textColor"
            outlined
            class="me-3 text-capitalize"
            v-for="eachPermission in item.permissions"
            :key="eachPermission.id"
          >
            {{ eachPermission.permission.split("_").join(" ") }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canManageRoles || noRoles">
                <v-list-item-title>
                  <a @click="editItem(item)"
                    ><v-icon small class="mr-2"> mdi-pencil </v-icon>Edit</a
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canManageRoles || noRoles">
                <v-list-item-title>
                  <a @click="deleteItem(item)">
                    <v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/DataTableLoader.vue";
import { mapGetters } from "vuex";

export default {
  components: { SmartChurchLayout },
  data: () => ({
    search: "",
    dialog: false,

    dialog2: false,
    dialogDelete: false,
    overlay: true,
    headers: [
      { text: "Name", value: "name" },
      { text: "Permissions", value: "permissions" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    roles: [],

    create_roles: {
      name: "",
      permissions: [],
      error: "",
      response: "",
    },
    permissions: [],
    selectAll: false,

    snackbar: {
      status: false,
      msg: "",
      color: "",
      timeout: 3000,
    },

    isSubmitted: false,

    defaultItem: {
      name: "",
    },

    create_roles_errors: {},
    create_roles_messages: [],

    editedIndex: -1,

    isLoading: false,
  }),

  watch: {
    dialog2(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    ...mapGetters(["canManageRoles", "canViewRoles", "noRoles", "isAdmin"]),
  },

  methods: {
    getRoles() {
      this.isLoading = true;

      this.$http
        .get("admin/roles")
        .then((response) => {
          this.roles = response.data.data;
          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

    getPermissions() {
      this.$http
        .get(`admin/permissions`)
        .then((response) => {
          const permissionsInArray = [response.data];
          this.permissions = permissionsInArray[0].data;
        })
        .catch((error) => {
          catchAxiosExceptions(error);
        });
    },

    selectAllOptions() {
      if (this.selectAll) {
        this.create_roles.permissions = this.permissions.map(
          (permission) => permission.id
        );
      } else {
        this.create_roles.permissions = [];
      }
    },

    createRoles() {
      this.create_roles_errors = "";

      this.isSubmitted = true;

      this.$http
        .post("admin/roles", this.create_roles)
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getRoles();
          this.$refs.form.reset();

          this.cancel();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.create_roles_messages = error.response;

          this.isSubmitted = false;
          this.create_roles_errors = error.response.data.message;
          // console.log(this.create_roles_errors);
          catchAxiosExceptions(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.roles.indexOf(item);
      this.create_roles = Object.assign({}, item);
      this.dialog2 = true;
    },

    save() {
      this.create_roles_errors = "";

      this.isSubmitted = true;

      this.$http
        .put("admin/roles/" + this.create_roles.slug, this.create_roles)
        .then((response) => {
          this.isSubmitted = false;
          this.isSubmitted = false;
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getRoles();
          this.close();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.isSubmitted = false;
          this.create_roles_errors = error.response.data.errors;

          catchAxiosExceptions(error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.roles.indexOf(item);
      this.create_roles = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/roles/" + this.create_roles.slug)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getRoles();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          catchAxiosExceptions(error);
        });
    },

    cancel() {
      this.dialog = false;
    },

    close() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.create_roles = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.create_roles = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  created() {
    this.$store.commit("allPermissions");
  },

  mounted() {
    this.getRoles();
    this.getPermissions();
  },
};
</script>

<style>
h3 {
  font-size: 24px !important;
}
</style>
