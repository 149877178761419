<template>
  <SmartChurchLayout page="Edit User">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container fluid  class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminUsers' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initUserDetails"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="mt-12 rounded-lg text-capitalize">
           <v-form class="pa-9" ref="form" @submit.prevent="updateUser">
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                Edit User
              </h2>
              <v-row class="d-flex justify-center mt-7">
               
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                    type="text"
                    v-model="user_details.first_name"
                    :error-messages="user_details_errors.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Last Name"
                    type="text"
                    v-model="user_details.last_name"
                    :error-messages="user_details_errors.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    type="email"
                    v-model="user_details.email"
                    :error-messages="user_details_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Phone Number"
                    type="number"
                    v-model="user_details.phone"
                    :error-messages="user_details_errors.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    outlined
                    dense
                    label="Gender"
                    :items="gender"
                    v-model="user_details.gender"
                    :error-messages="user_details_errors.gender"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    type="text"
                    v-model="user_details.state"
                    :error-messages="user_details_errors.state"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    type="text"
                    v-model="user_details.city"
                    :error-messages="user_details_errors.city"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12">
                  <v-autocomplete
                    label="Country"
                    v-model="user_details.country"
                    :error-messages="user_details_errors.country"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="user_details.branches"
                    :items="branchesData"
                    attach
                    chips
                    label="Branches"
                    item-text="name"
                    item-value="id"
                    multiple
                    outlined
                    :error-messages="user_details_errors.branches"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="user_details.roles"
                    :items="rolesData"
                    attach
                    chips
                    label="Roles"
                    item-text="name"
                    item-value="id"
                    multiple
                    outlined
                    :error-messages="user_details_errors.roles"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    dense
                    label="Address"
                    v-model="user_details.address"
                    :error-messages="user_details_errors.address"
                  ></v-textarea>
                </v-col>
                 <!-- <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :value="userPassword"
                    required
                    label="Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    v-model="user_details.password"
                    :error-messages="user_details_errors.password"
                    @click:append="show1 = !show1"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Confirm Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    v-model="user_details.password_confirmation"
                    :error-messages="user_details_errors.password_confirmation"
                    @click:append="show2 = !show2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                :loading="isSubmitted"
                 :disabled="isMismatched || isSubmitted"
                >Edit</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </SmartChurchLayout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"
import Countries from "@/models/countries";


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      rules: [
        (value) =>
          !value ||
          !value.length ||
          value[0].size < 200000 ||
          "Avatar size should be less than 2 MB!",
      ],
      user_details: [],
      userPassword: "",

      items: ["yes", "no"],
      status: ["Active", "Inactive", "Suspended"],
      overlay: true,
      gender: ["male", "female"],


      user_details_errors: {},
      branchesData: [],
      rolesData: [],

      routeParamId: this.$route.params.user_id,

      isLoading: false,

      isSubmitted: false,

      countries: [],
      show1: false,
      show2: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      mountedError: null,
      photo: {
        logo: "",
      },
    }
  },

   computed: {
    isMismatched() {
      if (
        this.user_details.password !== this.user_details.password_confirmation
      ) {
        return false;
      }
    },
  },

  methods: {
    async initUserDetails() {
      if (this.routeParamId === undefined || this.routeParamId.length < 1) {
        this.$router.push({ name: "AdminUsers" })
      } else {
        this.isLoading = true

        try {
          const user_details = await this.$http.get(
            "admin/users/" + this.routeParamId
          )
          const roles = await this.$http.get(`admin/roles`);
        const branches = await this.$http.get(`admin/getBranches`);

        const rolesInArray = [roles.data];
        this.rolesData = rolesInArray[0].data;
        this.branchesData = branches.data.data;

        this.user_details = user_details.data.data;

        // console.log(this.rolesData);
        // console.log(this.branchesData);

          this.isLoading = false
          this.overlay = false
        } catch (error) {
          this.mountedError = error.response.data.message
          this.isLoading = false
          this.overlay = false

          catchAxiosExceptions(error)
        }
      }
    },

    updateUser() {
      this.user_details.error = ""
      this.user_details.response = ""

      this.isSubmitted = true
      // const headers = { "Content-Type": "multipart/form-data" };

      this.$http
        .put("admin/users/" + this.routeParamId, this.user_details)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.user_details_errors = error.response.data.errors
          this.isSubmitted = false

          catchAxiosExceptions(error)
        })
    },
   
  },

  mounted() {
    this.initUserDetails();
    this.countries = Countries;

  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
.v-icon {
  color: #c73438;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.itk {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.v-text-field::-moz-focus-inner {
  background-color: red !important;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
}
.img-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
