<template>
  <smart-church-layout page="Post Service Report">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialog"
      max-width="900"
      height="700"
      overlay
      retain-focus
      transition="dialog-bottom-transition"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <v-card class="p-1 p-lg-3">
        <!-- <v-toolbar dark color="white" class="elevation-0">
          <v-toolbar-title class="d-none">Settings</v-toolbar-title>
        </v-toolbar> -->
        <div class="d-flex justify-content-end">

          
          <v-btn icon dark @click="dialog = false" color="black" class="p-3">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row align="center" justify="center" class="mt-0 mt-lg-5 w-90 mx-auto">
          <v-col cols="12">
            <a href="https://smartchurch.ng/" target="_blank">
              <v-img
                src="@/assets/smartchurch-logo.png"
                max-height="180"
                max-width="180"
                class="d-block mx-auto"
              ></v-img>
            </a>
          </v-col>
          <v-col cols="12" lg="7">
            <v-form class="p-4" @submit.prevent="addFeedback" ref="ratingForm">
              <v-row>
                <v-col cols="12">
                  <h3 class="text-h4 text-bold text-center">
                    What is your rating?
                  </h3>
                  <p class="text-secondary fs-5 text-center">
                    Please drop a comment or remark about your 
                    experience using SmartChurch
                  </p>
                  <div class="w-75 mx-auto m-2">
                    <star-rating
                      animate
                      :glow="glow"
                      :show-rating="showNumber"
                      :star-size="starSize"
                      v-model="feedBack.rating"
                      class="d-flex justify-content-center"
                    />
                  </div>
                </v-col>
                <v-col cols="12" class="mt-5">
                  <v-textarea outlined v-model="feedBack.message" hint="Kindly complete the comment section" required> </v-textarea>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                class="fw-bold"
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Submit</v-btn
              >
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container fluid class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminServiceReport' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initCreateServiceReport"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="mt-12 rounded-lg text-capitalize">
            <v-form
              class="my-6 pa-13"
              ref="form"
              @submit.prevent="createServiceReport"
            >
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                Post Service report
              </h2>
              <v-row class="mt-4">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="service_report.branch_id"
                    :items="branches"
                    dense
                    label="Branch"
                    id="branch_id"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="service_report_errors.branch_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="service_report.service_type_id"
                    :items="services"
                    dense
                    id="service_type_id"
                    item-value="id"
                    outlined
                    label="Services"
                    item-text="name"
                    :error-messages="service_report_errors.service_type_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    dense
                    outlined
                    :items="days"
                    label="Service Day"
                    v-model="service_report.service_day"
                    :error-messages="service_report_errors.service_day"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="date"
                    dense
                    label="Service Date"
                    outlined
                    v-model="service_report.service_date"
                    :error-messages="service_report_errors.service_date"
                  ></v-text-field>
                  <div v-if="isMismatched" class="error--text">
                    The selected day in the service day field does not match the
                    selected day in the service date field
                  </div>
                </v-col>

                <v-col cols="12">
                  <div>Church Attendance</div>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Male Attendance"
                    v-model="service_report.male_attendance"
                    :error-messages="service_report_errors.male_attendance"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Female Attendance"
                    v-model="service_report.female_attendance"
                    :error-messages="service_report_errors.female_attendance"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Children Attendance"
                    v-model="service_report.children_attendance"
                    :error-messages="service_report_errors.children_attendance"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Teen Attendance"
                    v-model="service_report.teenager_attendance"
                    :error-messages="service_report_errors.teenager_attendance"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="First Timers"
                    v-model="service_report.first_timers"
                    :error-messages="service_report_errors.first_timers"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="New Convert"
                    v-model="service_report.new_convert"
                    :error-messages="service_report_errors.new_convert"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <div>About sermon</div>
                  <p class="warning--text text-capitalize">
                    Note that the fields below are optional
                  </p>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    label="Service Title"
                    v-model="service_report.service_title"
                    :error-messages="service_report_errors.service_title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    label="Topic"
                    v-model="service_report.topic"
                    :error-messages="service_report_errors.topic"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    label="Speaker"
                    v-model="service_report.speaker"
                    :error-messages="service_report_errors.speaker"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    dense
                    outlined
                    label="Notes"
                    v-model="service_report.notes"
                    :error-messages="service_report_errors.notes"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    canManageIncomeReport || canManageExpenseReport || noRoles
                  "
                >
                  <h5>Finance section</h5>
                  <p class="warning--text text-capitalize">
                    Please, click on the add income button after filling each
                    income
                  </p>
                </v-col>

                <v-row
                  v-if="
                    canManageIncomeReport || canManageExpenseReport || noRoles
                  "
                >
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      v-model="incomePayLoad.income_title_id"
                      :items="incomeTitles"
                      dense
                      label="Income Title"
                      id="finance_id"
                      item-value="id"
                      outlined
                      item-text="name"
                      :error-messages="service_report_errors.incomes"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      label="₦ Amount"
                      v-model="incomePayLoad.amount"
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-8">
                    <v-btn
                      color="primary white--text"
                      @click="addIncome"
                      :disabled="!addIncomeAndAmount"
                      class="me-3"
                      >add income</v-btn
                    >
                    <!-- <v-btn
                      class="me-4 mt-5 mt-lg-0"
                      outlined
                      color="primary"
                      @click="cancelIncomeReport"
                      >cancel</v-btn
                    > -->
                  </v-col>

                  <v-col cols="12">
                    <v-simple-table class="mt-2 p-5">
                      <template default>
                        <thead>
                          <tr>
                            <th>Income Title Name</th>
                            <th>Amount</th>
                            <!-- <th class="text-end">Edit</th> -->
                            <th class="text-start">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="service_report.incomes.length == 0">
                            <td>
                              <div>
                                <p class="text-center mt-4">
                                  Add Income and Amount
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr
                            v-for="(income, i) in service_report.incomes"
                            :key="i"
                            v-else
                          >
                            <td>
                              {{
                                incomeTitles.find(
                                  (title) => title.id == income.income_title_id
                                ).name
                              }}
                            </td>
                            <td>₦{{ getFormattedAmount(income.amount) }}</td>
                            <!-- <td class="text-end">
                              <v-btn @click="editItem(i)">
                                <v-icon color="primary"
                                  >mdi-pencil-outline</v-icon
                                >
                              </v-btn>
                            </td> -->
                            <td class="text-start">
                              <v-btn @click="removeItem(i)"
                                ><v-icon color="primary"
                                  >mdi-delete-outline</v-icon
                                ></v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <div
                    v-for="(incomeErrors, i) in service_report_errors"
                    :key="i"
                  >
                    <div
                      v-for="theError in incomeErrors"
                      :key="theError"
                      class="alert alert-danger d-flex justify-center"
                    >
                      {{ theError }}
                    </div>
                  </div>
                  <!-- <div v-if="service_report_errors.length > 1"> -->
                  <!-- </div> -->
                </v-row>

                <v-btn
                  type="submit"
                  color="primary white--text"
                  :loading="isSubmitted"
                  :disabled="
                    isSubmitted ||
                    isMismatched ||
                    !service_report.service_date ||
                    !service_report.service_day
                  "
                  >Post</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedAmount,
} from "../../__helpers/handelExceptions";
import { mapGetters } from "vuex";

import StarRating from "vue-star-rating";

// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout, StarRating },
  data() {
    return {
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      userPermissionNames: [],
      user: this.$store.state.userDetail.user,

      overlay: true,
      service_report: {
        service_type_id: "",
        service_day: "",
        service_date: "",

        service_title: "",
        male_attendance: "",
        female_attendance: "",
        children_attendance: "",
        teenager_attendance: "",
        first_timers: "",
        new_convert: "",
        speaker: "",
        finance_id: "",
        topic: "",
        notes: "",
        branch_id: "",
        response: "",
        error: "",
        incomes: [],
      },
      incomePayLoad: {
        amount: null,
        income_title_id: null,
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      response: "",
      service_report_errors: {},
      services: [],
      branches: [],
      finances: [],
      incomeTitles: [],
      incomeTitles_error: {},

      isLoading: false,
      mountedError: null,

      dialog: false,

      showNumber: false,
      starSize: 45,
      glow: 4,

      feedBack: {
        rating: 0,
        message: "",
      },

     
    };
  },

  computed: {
    isMismatched() {
      if (
        !this.service_report.service_date ||
        !this.service_report.service_day
      ) {
        return false;
      }
      const dayOfWeek = new Date(
        this.service_report.service_date
      ).toLocaleDateString("en-US", { weekday: "long" });
      return this.service_report.service_day !== dayOfWeek;
    },

    addIncomeAndAmount() {
      return this.incomePayLoad.income_title_id && this.incomePayLoad.amount;
    },
    ...mapGetters([
      "canManageIncomeReport",
      "canManageExpenseReport",
      "noRoles",
    ]),
  },

  methods: {
    async initCreateServiceReport() {
      this.isLoading = true;

      try {
        const displayService = await this.$http.get("admin/service-type");
        // const allBranch = await this.$http.get("admin/getBranches")
        const displayIncome = await this.$http.get("admin/income-title");
        const user = this.$store.state.userDetail.user;
        this.services = displayService.data.data;

        this.branches = user.branches;
        this.incomeTitles = displayIncome.data.data;
        // console.log(this.incomeTitles);

        // console.log(
        //   this.incomeTitles.filter(
        //     (item) => item.display_in_service_report == true
        //   )
        // )
        this.incomeTitles = this.incomeTitles.filter(
          (item) => item.display_in_service_report == true
        );

        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        // this.mountedError = error.response.data.message;
        this.isLoading = false;
        this.overlay = false;

        catchAxiosExceptions(error);
      }
    },

    addFeedback() {
      this.isSubmitted = true;

      this.$http
        .post("feedback", this.feedBack)
        .then((response) => {
          // console.log(response.data.message);
          this.isSubmitted = false;
          this.dialog = false;
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.$refs.ratingForm.reset();
          this.feedBack.rating = 0;
          localStorage.setItem("checkedRatingForm", true);
        })
        .catch((error) => {
          // console.log(error);
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },

    addIncome() {
      // if (!this.incomePayLoad.income_title_id) {
      this.service_report.incomes.push(this.incomePayLoad);
      // }
      this.incomePayLoad = { amount: null, income_title_id: null };
    },

    removeItem(index) {
      this.service_report.incomes.splice(index, 1);
    },

    createServiceReport() {
      this.service_report.error = "";

      this.isSubmitted = true;
      this.$http
        .post("admin/service-report", this.service_report)
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          this.$refs.form.reset();
          this.service_report.incomes = [];

          if (!localStorage.getItem("checkedRatingForm")) {
            this.dialog = true;
          }
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.isSubmitted = false;
          this.service_report_errors = error.response.data.errors;

          catchAxiosExceptions(error);
          if (error.response.status === 402) {
            alert(
              "Please upgrade your Subscription Plan to enjoy unlimited access. Thank You!"
            );
            this.$refs.form.reset();
          }
          if (error.response.status === 400) {
            alert("The report for the month has been approved");
            this.$refs.form.reset();
          }
        });
    },

    // addIncomeReport() {
    //   this.service_report.incomes.push({
    //     amount: null,
    //     name: null,
    //   });
    // },

    // cancelIncomeReport() {
    //   if (this.service_report.incomes.length > 1) {
    //     this.service_report.incomes.pop({
    //       amount: null,
    //       income_title_id: null,
    //     });
    //   }
    // },

    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },

  
  },

  created() {
    this.$store.commit("allPermissions");
  },

  mounted() {
    this.initCreateServiceReport();

  },
};
</script>

<style scoped>


.w-90 {
  width: 90%;
}
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
small {
  line-height: -50px;
}

.mt-7 {
  padding-top: 6rem !important;
}
</style>
