<template>
  <smart-church-layout page=" View Pastor">
     <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminPastors' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="viewPastor"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="
                  primary--text
                  font-weight-bold
                  d-flex
                  justify-center
                  mb-5
                "
              >
                view Pastor
              </h2>
              <v-img
                :src="pastor_details.logo"
                max-height="120"
                max-width="120"
                class="mx-auto rounded-circle pa-6"
              ></v-img>
              <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ pastor_details.first_name }}</span>
                <span class="me-3">{{ pastor_details.last_name }}</span>
              </h2>
              <v-row class="mt-6 primary--text pa-4">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ pastor_details.phone }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"><v-icon>mdi-home</v-icon>Branch</label>
                  <p>{{ branchName }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ pastor_details.email }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"><v-icon>mdi-home</v-icon>Address</label>
                  <p>{{ pastor_details.address }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"><v-icon>mdi-party-popper</v-icon>Birthday</label>
                  <p>{{ pastor_details.date_of_birth }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="country"><v-icon>mdi-map</v-icon>Country</label>
                  <p>{{ pastor_details.country }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="state"><v-icon>mdi-map</v-icon>State</label>
                  <p>{{ pastor_details.state }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>City</label>
                  <p>{{ pastor_details.city }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>Nationality</label>
                  <p>{{ pastor_details.nationality }}</p>
                </v-col>
                <v-btn
                  block
                  :to="{
                    name: 'AdminEditPastor',
                    params: { pastor_slug: routeParamSlug },
                  }"
                  color="primary"
                  >Edit Pastor</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      pastor_details: [],
       branchName: "",
      overlay: true,

      response: "",
      pastor_details_errors: {},

      routeParamSlug: this.$route.params.pastor_slug,

      isLoading: false,
      mountedError: null,
    };
  },

  methods: {
    viewPastor() {
      this.isLoading = true;

      this.$http
        .get("admin/pastors/" + this.routeParamSlug)
        .then((response) => {
          this.pastor_details = response.data.data;
          //  console.log(this.pastor_details);
           this.branchName = this.pastor_details.branch.name
          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.mountedError = error.response.data.message;
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },
  },

  mounted() {
    this.viewPastor();
  },
};
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
