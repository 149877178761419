<template>
  <smart-church-layout page="Create Branch">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container fluid class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminBranches' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <v-card class="d-flex justify-center mt-6 rounded-lg text-capitalize">
            <v-form
              class="my-4 pa-10"
              ref="form"
              @submit.prevent="createBranch"
            >
              <div
                class="alert alert-success my-3"
                v-if="create_branch.response"
              >
                {{ create_branch.response }}
              </div>
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                add new branch
              </h2>

              <v-row class="mt-6">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Name"
                    id="name"
                    v-model="create_branch.name"
                    :error-messages="create_branch_errors.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    id="city"
                    v-model="create_branch.city"
                    :error-messages="create_branch_errors.city"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    id="state"
                    v-model="create_branch.state"
                    :error-messages="create_branch_errors.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Country"
                    id="country"
                    v-model="create_branch.country"
                    :error-messages="create_branch_errors.country"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    id="email"
                    type="email"
                    v-model="create_branch.email"
                    :error-messages="create_branch_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Phone Number"
                    id="phone"
                    type="number"
                    v-model="create_branch.phone"
                    :error-messages="create_branch_errors.phone"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="Year of creation"
                    id="year"
                    type="date"
                    v-model="create_branch.year"
                    :error-messages="create_branch_errors.year"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    id="address"
                    label="Address"
                    v-model="create_branch.address"
                    :error-messages="create_branch_errors.address"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- <label for="active"><v-icon>mdi-home</v-icon>Active branch</label> -->
                  <v-switch
                    v-model="create_branch.is_active"
                    label="Is this branch active?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- <label for="head branch">head branch</label> -->
                  <v-switch
                    v-model="create_branch.is_head_branch"
                    label="Is this the head branch?"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                depressed
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                color="primary white--text"
                >Create Branch</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      items: ["yes", "no"],
      create_branch: {
        name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        year: null,
        is_head_branch: false,
        is_active: false,
        response: "",
        error: "",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      response: "",
      create_branch_errors: {},
      branches: [],
    }
  },

  methods: {
    createBranch() {
      this.create_branch.response = ""
      this.create_branch.error = ""

      this.isSubmitted = true

      this.$http
        .post("admin/branches", this.create_branch)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false

          this.create_branch.name = ""
          this.create_branch.email = ""
          this.create_branch.phone = ""
          this.create_branch.address = ""
          this.create_branch.country = ""
          this.create_branch.state = ""
          this.create_branch.city = ""
          this.create_branch.year = null
          this.create_branch.is_head_branch = false
          this.create_branch.is_active = false

          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.create_branch_errors = error.response.data.errors
          this.isSubmitted = false
          catchAxiosExceptions(error)
          if (error.response.status === 402) {
            alert(
              "Please upgrade your Subscription Plan to enjoy unlimited access. Thank You!"
            )
            this.$refs.form.reset()
          }
        })
    },
  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
.v-icon {
  color: #c73438;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.itk {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.v-text-field::-moz-focus-inner {
  background-color: red !important;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
}

.bg-transparent {
  background-color: transparent !important;
}
</style>
