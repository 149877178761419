<template>
  <smart-church-layout page="Service Reports">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->
    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Service Report"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <h3 class="primary--text font-weight-bold mt-6 d-lg-none">
        Service Report
      </h3>

      <div class="d-flex justify-content-around mb-5 mt-9">
        <h3 class="primary--text font-weight-bold d-none d-lg-block">
          Service Report
        </h3>
        <v-btn
          color="primary text--white"
          class="mx-5 font-weight-bold"
          :to="{ name: 'AdminCreateServiceReport' }"
          v-if="canManageServiceReport || noRoles"
          ><v-icon left>mdi-plus</v-icon><span>add service report</span></v-btn
        >
        <v-spacer></v-spacer>
      </div>
      <v-row class="mt-5">
        <v-col cols="12" lg="4">
          <v-autocomplete
            :items="branches"
            item-value="id"
            v-model="branch_id"
            @change="allServiceReports(page = 1)"
            dense
            outlined
            rounded
            label="Select Branch"
            item-text="name"
          ></v-autocomplete>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="12" lg="4">
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field
            class="d-none d-md-flex"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Service Report"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="serviceReports"
        :search="search"
        :sort-by="'service_date'"
        disable-pagination
        hide-default-footer
        :sortDesc="true"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialogDelete" max-width="550px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this service
                  report?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.service_date`]="{ item }">
          {{ getFormattedDate(item.service_date) }}
        </template>
        <template v-slot:[`item.total_attendance`]="{ item }">
          {{ getTotalAttendance(item) }}
        </template>
        <template v-slot:[`item.total_income`]="{ item }">
          ₦{{ getFormattedAmount(item.total_income) }}
        </template>
        <template v-slot:[`item.new_convert`]="{ item }">
          {{ item.new_convert }}
        </template>
        <template v-slot:[`item.first_timer`]="{ item }">
          {{ item.first_timers }}
        </template>
        <template v-slot:[`item.approval_status`]="{ item }">
          <v-chip :color="getColor(item.approval_status)">
            {{ item.approval_status == "pending" ? "Open" : "Closed" }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="bg-transparent"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canManageServiceReport || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none"
                    tag="button"
                    :disabled="item.approval_status == 'approved'"
                    :style="`color: ${
                      item.approval_status === 'approved'
                        ? 'green'
                        : 'text--secondary'
                    }`"
                    :to="{
                      name: 'AdminEditServiceReport',
                      params: { serviceReport_slug: item.slug },
                    }"
                  >
                    <v-icon
                      small
                      class="mr-2"
                      :style="`color: ${
                        item.approval_status === 'approved'
                          ? 'green'
                          : 'text--secondary'
                      }`"
                    >
                      mdi-pencil
                    </v-icon>
                    <span>Edit</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canManageServiceReport || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none"
                    :to="{
                      name: 'AdminViewServiceReport',
                      params: { serviceReport_slug: item.slug },
                    }"
                  >
                    <v-icon small class="mr-2"> mdi-eye </v-icon>

                    <span class="textColor--text">View</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canManageServiceReport || noRoles">
                <v-list-item-title>
                  <a
                    @click="deleteItem(item)"
                    :disabled="item.approval_status == 'approved'"
                    :style="`color: ${
                      item.approval_status === 'approved'
                        ? 'green'
                        : 'text--secondary'
                    }`"
                    ><v-icon
                      small
                      class="mr-2"
                      :style="`color: ${
                        item.approval_status === 'approved'
                          ? 'green'
                          : 'text--secondary'
                      }`"
                    >
                      mdi-delete </v-icon
                    >Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-end d-flex justify-end" >
        <v-pagination
          v-model="page"
          :length="lastPage"
          @input="allServiceReports"
        ></v-pagination>
        <span class="mt-3">Showing {{ from }} - {{ to }} of {{ total }} </span>
      </div>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedDate,
  getTotalAttendance,
  getFormattedAmount,
} from "../../__helpers/handelExceptions";

import skeletonLoader from "../../components/DataTableLoader.vue";
import { mapGetters } from "vuex";

export default {
  components: { SmartChurchLayout, skeletonLoader },

  data() {
    return {
      search: "",
      overlay: true,
      showReport: false,
      dialogDelete: false,
      user: this.$store.state.userDetail.user,
      branches: [],

      page: 1,
      lastPage: 1,
      from: 0,
      to: 0,
      total: 0,

      userPermissionNames: [],

      headers: [
        { text: "Branch", value: "branch.name" },
        {
          text: "Date",
          value: "service_date",
          sortable: true,
          searchable: true,
        },
        { text: "Day", value: "service_day" },
        { text: "Attendance", value: "total_attendance" },
        { text: "First timers", value: "first_timers" },
        { text: "New converts", value: "new_convert" },
        { text: "Income", value: "total_income" },
        { text: "Topic", value: "topic" },
        { text: "Speaker", value: "speaker" },
        { text: "Status", value: "approval_status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      serviceReports: [],

      editedIndex: -1,

      isLoading: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 2000,
      },

      branch_id: 0,

      approval_status: "",
    };
  },
  computed: {
    ...mapGetters([
      "canManageServiceReport",
      "canViewServiceReport",
      "canViewIncomeReport",
      "canViewExpenseReport",
      "noRoles",
      "isAdmin",
    ]),
  },

  methods: {
    allServiceReports(page = 1) {
      this.isLoading = true;
       this.overlay = true
      this.$http
        .get(`admin/service-report?branch_id=${this.branch_id}&page=${page}`)
        .then((response) => {
          this.serviceReports = response.data.data;
          //  console.log(this.serviceReports)

          this.page = response.data.meta.current_page;
          this.lastPage = response.data.meta.last_page;
          this.from = response.data.meta.from;
          this.to = response.data.meta.to;
          this.total = response.data.meta.total;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          this.isLoading = false;

          catchAxiosExceptions(error);
        });
    },

    deleteItem(item) {
      console.log(item);
      if (item.approval_status !== "approved") {
        this.editedIndex = this.serviceReports.indexOf(item);
        this.serviceReport_details = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/service-report/" + this.serviceReport_details.slug)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.allServiceReports();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.serviceReport_details = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getFormattedDate(date) {
      return getFormattedDate(date);
    },

    getTotalAttendance(report) {
      return getTotalAttendance(report);
    },

    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },
    getColor(approval_status) {
      if (approval_status == "approved") {
        return "green";
      } else return "orange";
    },
  },

  created() {
    this.$store.commit("allPermissions");
    this.branches = this.user.branches;
  },

  mounted() {
    // this.allServiceReports();
    this.overlay = false;
    // console.log(this.branches)
  },
};
</script>
<style >
/* .center {
  padding-top: 20vh !important;
} */
.color-approved {
  color: green !important;
}
</style>
