<template>
  <smart-church-layout page="Support">
    <v-container class="my-8">
      <v-row justify="center" align="center">
        <v-col cols="12" >
          <div class="mt-8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-img src="@/assets/support 1.png"></v-img>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <div class="ms-3">
                    <h2 class="primary--text fw-bold">
                      Hi there!!!, 
                    </h2>
                    <p class=" fs-5 ">
                      You have a question? <br>
                      We've got you covered. Check out our FAQs 
                      below for answers to common inquiries. <br>
                       If you can't find what you are looking for,
                       please reach out to our customer support team for assistance.</p>
                    
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-card elevation="0" class=" mt-8">
                    <h2 class="primary--text font-weight-bold fs-8 text-center mt-2 mb-5 pt-6">Find answers to all of our frequently ask questions</h2>
                    <template>
                      <v-expansion-panels focusable inset class="fs-6">
                        <v-expansion-panel>
                          <v-expansion-panel-header >
                            <h5>How to  Create a Service Report</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <p>
                              <ul>
                                <li>Click on report on the sidebar</li>
                                <li>Click on create service report</li>
                                <li>Fill the available fields</li>
                                <li>Click on Create report</li>
                              </ul>
                            </p>
                              <hr>
                            <h6>
                              To Edit Service Report <br>
                              <ul>
                                <li>Click on service report</li>
                                <li>On the headings where you have actions, click on the three dot</li>
                                <li>Select EDIT</li>
                                <li>Edit any of the fields you want to</li>
                              </ul>
                              <hr>
                              To Edit Income in Service Report <br>
                                <ul>
                                  <li>click on VIEW from the three dots</li>
                                  <li>Click on the pen icon to edit</li>
                                  <li>Click on the trash icon to delete an income title</li>
                                  <li>You can as well add an income by clicking the Add Income Button</li>
                                </ul>
                                <hr>
                                To Delete a Service Report <br>
                                <ul>
                                  <li>Click on service report</li>
                                  <li>click on DELETE from the three dots</li>
                                </ul>
                                <hr>
                                To View a Service Report
                                <ul>
                                  <li>Click on service report</li>
                                  <li>Click on the three dot</li>
                                  <li>Select View</li>
                                </ul>
                                <hr>
                                To View All Service Reports
                                <ul>
                                  <li>Click on service report</li>
                                  <li>On the search box, click and select your branch</li>
                                  
                                </ul>
                            </h6>
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <h5>How to generate monthly report</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul>
                              <li>Click on report on the sidebar</li>
                              <li>Click on branch monthly report</li>
                              <li>Select the branch, month and year of report</li>
                              <li>Click on Generate report</li>
                            </ul>
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <h5>How to Download monthly report</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul>
                              <li>After generating monthly report</li>
                              <li>On the bottom right corner, click on Download Report as PDF</li>
                              <li>Download your report</li>
                            </ul>
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <h5>How to Import members in bulk</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul>
                              <li>On the sidebar, Click on Personnel management</li>
                              <li>Select Import Members</li>
                              <li>Click on Download Sample Excel Template</li>
                              <li>Select the downloaded excel template in your file explorer</li>
                              <li>fill the fields required</li>
                              <li>Click on the import field and import the excel sheet you've filled from your file explorer</li>
                              <li>Click on the Import button</li>
                            </ul>
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <h5>How to Add a Role</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul>
                              <li>On the sidebar, Click on Setup Menu</li>
                              <li>Select Add Role</li>
                              <li>Fill the role name and tick the permissions you desire for the role</li>
                              <li>Click on the create button</li>
                              
                            </ul>
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <h5>How to Add a User</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul>
                              <li>On the sidebar, Click on Setup Menu</li>
                              <li>Select Add User</li>
                              <li>Fill the required fields</li>
                              <li>Click on the create button</li>
                              
                            </ul>
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                       
                      </v-expansion-panels>
                    </template>
                  </v-card>

                </v-col>
              </v-row>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import WhatsAppWidget from "../../components/WhatsAppWidget.vue"
export default {
  components: { SmartChurchLayout, WhatsAppWidget },
  
}
  
</script>


<style>
</style>