<template>
  <smart-church-layout page="Users">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container  class="my-8">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="allUsers"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-dialog v-model="dialogUpgrade" max-width="550px">
                <v-card>
                  <v-card-title class="text-h5 text-center"
                    >Are you sure you want to upgrade this user to Admin?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDelete">No</v-btn>
                    <v-btn color="primary" text @click="confirmUpgradeUser"
                      >YES</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this user?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item, index }">
              <template v-if="getUsertype(item.user_type) === 'pastor'">
                <v-btn
                  outlined
                  small
                  elevation="0"
                  color="primary"
                  class="mr-2"
                  @click="upgradeUser(item, index)"
                >
                  Pastor</v-btn
                >
              </template>
              <template v-else-if="getUsertype(item.user_type) === 'church'">
                <v-btn
                  small
                  elevation="0"
                  color="primary text--white"
                  class="mr-2"
                >
                  Admin</v-btn
                >
              </template>
              
              <template>
                <v-btn
                  small
                  elevation="0"
                  color="primary text--white"
                  class="mr-2"
                  @click="deleteItem(item)"
                >
                  Delete</v-btn
                >
              </template>

              <!-- <v-btn
                outlined
                small
                elevation="0"
                :color="btnColor"
                class="mr-2"
                @click="upgradeUser(item)"
                >{{ btnText }}</v-btn
              > -->
              <!-- <v-btn  small elevation="0" outlined color="primary" class="mr-2" @click="removeUser(item)">Remove</v-btn> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/DataTableLoader.vue"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      btnColor: "primary",
      btnText: "Upgrade",
      overlay:true,
      allUsers: [],
      dialogUpgrade: false,
      dialogDelete: false,
      headers: [
        
        { text: "Name", value: "name" },
        { text: "Email Address", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      change_btn: {
        color: "",
        text: "Upgraded",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      all_users: {
        name: "",
        email: "",
        phone: "",
      },
      defaultItem: {
        name: "",
      },
      editedIndex: -1,
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getAllUsers() {
      this.$http
        .get("/admin/getAllUsers")
        .then((response) => {
          this.allUsers = response.data.data.user;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          catchAxiosExceptions(error)
        });
    },

    upgradeUser(item) {
      
      this.editedIndex = this.allUsers.indexOf(item);
      this.all_users = Object.assign({}, item);
      this.dialogUpgrade = true;

      
    },

    confirmUpgradeUser() {
      this.$http
        .put(`/admin/upgradeToAdmin/${this.all_users.id}`)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.closeDelete();

          this.getAllUsers();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },
    deleteItem(item) {
      this.editedIndex = this.allUsers.indexOf(item)
      this.all_users = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$http
        .delete(`/admin/userDelete/${this.all_users.id}`)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.getAllUsers();
          this.close()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          
        })
    },

    closeDelete() {
      this.dialogUpgrade = false;
    },
    close() {
      this.dialogDelete = false;
    },

    getUsertype(user_type) {
      let splittedUserTyped = user_type.split("\\");
      let userType = splittedUserTyped[splittedUserTyped.length - 1];

      return userType;
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>

<style>
</style>