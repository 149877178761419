<template>
  <smart-church-layout page="Expenses">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Expense "
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex mb-5 mt-10">
        <h3 class="primary--text font-weight-bold">Expenses</h3>
        <v-btn
          class="mx-5 font-weight-bold"
          :to="{ name: 'adminAddExpense' }"
          color="primary text--white"
          v-if="canManageIncomeReport || canManageExpenseReport || noRoles"
        >
          <v-icon>mdi-plus</v-icon> Create Expense
        </v-btn>

        <v-spacer></v-spacer>
      </div>

      <v-row class="mt-5">
        <!-- <v-col>
          <v-spacer></v-spacer>
        </v-col> -->
        <v-col cols="12" lg="4">
          <v-autocomplete
            :items="branches"
            item-value="id"
            v-model="branch_id"
            @change="initExpenses((page = 1))"
            dense
            outlined
            rounded
            label="Select Branch"
            item-text="name"
          ></v-autocomplete>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="12" lg="4">
          <v-spacer></v-spacer>
        </v-col>
        <v-col lg="4">
          <v-text-field
            class="d-none d-md-flex"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Expense"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="expenses"
        :search="search"
        disable-pagination
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="600px">
              <v-card class="p-4">
                <!-- <v-card-title> -->
                <h2 class="mb-4 primary--text text-center font-weight-bold">
                  Update Expense
                </h2>
                <!-- </v-card-title> -->

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="create_expense.branch_id"
                          :items="branches"
                          dense
                          label="Branch"
                          item-value="id"
                          outlined
                          item-text="name"
                          :error-messages="create_expense_errors.branch_id"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="create_expense.expense_title_id"
                          :items="expensesTitle"
                          dense
                          label="Expense Title"
                          item-value="id"
                          outlined
                          item-text="name"
                          :error-messages="
                            create_expense_errors.expense_title_id
                          "
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="Date"
                          type="date"
                          id="Date"
                          v-model="create_expense.expense_date"
                          :error-messages="create_expense_errors.expense_date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="₦ Amount"
                          type="number"
                          id="Amount"
                          v-model="create_expense.amount"
                          :error-messages="create_expense_errors.amount"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          label="Description"
                          id="name"
                          v-model="create_expense.description"
                          :error-messages="create_expense_errors.description"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <!-- <v-card-actions> -->

                <p class="text-center">
                  <v-btn color="primary" @click="save" :loading="isSubmitted" :disabled="isSubmitted" block> update </v-btn>
                </p>
                <p class="text-center">
                  <v-btn text @click="close" depressed> Cancel </v-btn>
                </p>
                <!-- </v-card-actions> -->
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this expense?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.expense_date`]="{ item }">
          {{ getFormattedDate(item.expense_date) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          ₦{{ getFormattedAmount(item.amount) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="bg-transparent" depressed>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="
                  canManageIncomeReport || canManageExpenseReport || noRoles
                "
              >
                <v-list-item-title>
                  <a @click="editItem(item)">
                    <v-icon small class="mr-2"> mdi-pencil </v-icon>Edit</a
                  >
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="
                  canManageIncomeReport || canManageExpenseReport || noRoles
                "
              >
                <v-list-item-title>
                  <a @click="deleteItem(item)">
                    <v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <div class="text-end d-flex justify-end">
        <v-pagination
          v-model="page"
          :length="lastPage"
          @input="initExpenses"
        ></v-pagination>
        <span class="mt-3">Showing {{ from }} - {{ to }} of {{ total }} </span>
      </div>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedDate,
  getFormattedAmount,
} from "../../__helpers/handelExceptions";
import skeletonLoader from "../../components/DataTableLoader.vue";
import { mapGetters } from "vuex";

export default {
  components: { SmartChurchLayout, skeletonLoader },
  data() {
    return{
      search: "",
      dialog: false,
      dialog2: false,
      dialogDelete: false,
  
      page: 1,
      lastPage: 1,
      from: 0,
      to: 0,
      total: 0,
  
      branch_id: 0,
  
      headers: [
        { text: "Date", value: "expense_date" },
        { text: "Branch", value: "branch.name" },
        { text: "Expense title", value: "expense_title.name" },
        { text: "Amount", value: "amount" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions", sortable: false },
      ],
  
      expenses: [],
      branches: [],
      overlay: true,
  
      create_expense: {
        branch_id: null,
        expense_date: "",
        amount: "",
        expense_title_id: null,
  
        description: "",
        error: "",
        response: "",
      },
  
      defaultItem: {
        name: "",
      },
  
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
  
      isSubmitted: false,
  
      response: "",
  
      expensesTitle: [],
  
      display_expense_error: [],
  
      create_expense_errors: {},
  
      editedIndex: -1,
  
      isLoading: false,
      mountedError: null,
  
      userPermissionNames: [],
      user: this.$store.state.userDetail.user,
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Expense" : "Edit Expense";
    },

    ...mapGetters([
      "canManageIncomeReport",
      "canViewIncomeReport",
      "canManageExpenseReport",
      "canViewExpenseReport",
      "noRoles",
      "canManageReports",
      "canViewReports",
      "isAdmin",
    ]),
  },

  watch: {
    dialog2(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async initExpenses(page = 1) {
      this.isLoading = true;
      this.overlay = true;

      try {
        const displayExpense = await this.$http.get(
          `admin/expense-report?branch_id=${this.branch_id}&page=${page}`
        );
        const expenseTitles = await this.$http.get("admin/expense-title");
        const user = this.$store.state.userDetail.user;

        this.expenses = displayExpense.data.data;
        this.branches = user.branches;
        this.expensesTitle = expenseTitles.data.data;

        this.page = displayExpense.data.meta.current_page;
        //  console.log(this.page);
        this.lastPage = displayExpense.data.meta.last_page;
        this.from = displayExpense.data.meta.from;
        this.to = displayExpense.data.meta.to;
        this.total = displayExpense.data.meta.total;

        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        // this.mountedError = error.response.data.message;
        this.isLoading = false;
        this.overlay = false;

        catchAxiosExceptions(error);
      }
    },

    editItem(item) {
      this.editedIndex = this.expenses.indexOf(item);
      this.create_expense = Object.assign({}, item);
      this.dialog2 = true;
    },

    save() {
      this.isSubmitted = true;
      this.$http
        .put(
          "admin/expense-report/" + this.create_expense.slug,
          this.create_expense
        )
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.isSubmitted = false;
          this.initExpenses();
          this.close();
        })

        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
          this.isSubmitted = false;
          this.create_expense_errors = error.response.data.errors;
          catchAxiosExceptions(error);
        });
    },

    cancel() {
      this.dialog = false;
    },

    deleteItem(item) {
      this.editedIndex = this.expenses.indexOf(item);
      this.create_expense = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.create_expense.response = "";

      this.$http
        .delete("admin/expense-report/" + this.create_expense.slug)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.initExpenses();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          catchAxiosExceptions(error);
        });
    },

    close() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.create_expense = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.create_expense = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },
  },

  created() {
    this.$store.commit("allPermissions");
    this.branches = this.user.branches;
  },

  mounted() {
    this.overlay = false;
  },
};
</script>

<style>
h3 {
  font-size: 24px !important;
}
/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
