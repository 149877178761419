const axios = require("axios");

module.exports = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 5000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    // 'branch': `branchSlug` + JSON.parse(localStorage.getItem('user')).branch.slug,
  },
});
