<template>
  <smart-church-layout page="Opening Balance">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <v-container class="fill-height d-background" fluid>
      <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout">{{ snackbar.msg
      }}</v-snackbar>

      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8" lg="7">
          <v-card class="pa-8 rounded-lg">
            <h3 class="primary--text font-weight-bold text-center mb-6">
              SmartChurch Initial Opening Balance
            </h3>
            <v-form class="px-5" ref="form" @submit.prevent="createOpeningBalance">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete v-model="create_opening_balance.branch_id" :items="branches" dense label="Branch"
                    id="branch_id" item-value="id" outlined item-text="name"
                    :error-messages="create_opening_balance_errors.branch_id">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined type="number" label="Balance" v-model="create_opening_balance.balance"
                    :error-messages="create_opening_balance_errors.balance"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="create_opening_balance.month" required type="month" label="Month and Year"
                    outlined dense :error-messages="create_opening_balance_errors.month"></v-text-field>
                </v-col>
              </v-row>

              <v-btn type="submit" block class="white--text" color="primary white--text" :loading="isSubmitted"
                :disabled="isSubmitted">Submit</v-btn>
              <v-dialog v-model="editDialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <p class="text-center">
                    <v-btn @click="editItem(item)" class="mt-4" color="primary white--text" outlined v-bind="attrs" v-on="on">
                    Edit
                  </v-btn>
                  </p>
                </template>
                <v-card>
                  <v-form class="pa-10" ref="form" @submit.prevent="createOpeningBalance">
                    <v-row>
                      <h3 class="primary--text font-weight-bold text-center my-4">
                        Edit Opening Balance
                      </h3>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete v-model="create_opening_balance.branch_id" :items="branches" dense label="Branch"
                          id="branch_id" item-value="id" outlined item-text="name"
                          :error-messages="create_opening_balance_errors.branch_id">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field dense outlined type="number" label="Balance"
                          v-model="create_opening_balance.balance"
                          :error-messages="create_opening_balance_errors.balance"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="create_opening_balance.month" required type="month" label="Month and Year"
                          outlined dense :error-messages="create_opening_balance_errors.month"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn @click="editOpeningBalance"  class="mt-4" color="primary" :loading="loading" :disabled="loading" >
                      Save
                    </v-btn>
                    <v-btn @click="close"  class="mt-4 mx-2" outlined color="primary white--text">
                      Cancel
                    </v-btn>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";

export default {
  components: { SmartChurchLayout },
  data() {
    return {
      create_opening_balance: {
        branch_id: "",
        balance: 0,
        month: null,
      },
      overlay: true,
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      isSubmitted: false,
      loading:false,
      branches: [],
      user: this.$store.state.userDetail.user,
      create_opening_balance_errors: {},
      editDialog: false,
    };
  },

  methods: {
    getBranches() {
      this.branches = this.user.branches;
      this.overlay = false;
    },
    createOpeningBalance() {
      this.isSubmitted = true;
      this.$http
        .post("admin/setOpeningBalance", this.create_opening_balance)
        .then((response) => {
          this.isSubmitted = false;
          this.snackbar.status = true;
          this.snackbar.msg = "Successfully Added!";
          this.snackbar.color = "green";

          this.$refs.form.reset();
        })
        .catch((error) => {
          this.isSubmitted = false;
          catchAxiosExceptions(error);
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },
    editOpeningBalance() {
      this.loading = true;
      this.$http
        .post("admin/setOpeningBalance", this.create_opening_balance)
        .then((response) => {
          this.loading = false;
          this.snackbar.status = true;
          this.snackbar.msg = "Successfully Editted!";
          this.snackbar.color = "green";
          this.$refs.form.reset();
        })
        .catch((error) => {
          this.loading = false;
          catchAxiosExceptions(error);
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },
    close(){
      this.editDialog = false
    }
  },
  created() {
    this.getBranches();
  },
};
</script>