<template>
  <smart-church-layout page="Add User">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container class="my-8">
      <div class="d-flex justify-space-between">
        <v-btn
          class="bg-transparent ms-7 mt-5"
          small
          :to="{ name: 'AdminUsers' }"
          ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
        >
      </div>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <v-card
            class="d-flex justify-center mt-12 rounded-lg text-capitalize"
          >
            <v-form class="pa-9" ref="form" @submit.prevent="createUser">
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                Add User
              </h2>
              <v-row class="d-flex justify-center mt-7">
                

                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                    type="text"
                    v-model="create_user.first_name"
                    :error-messages="create_user_errors.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Last Name"
                    type="text"
                    v-model="create_user.last_name"
                    :error-messages="create_user_errors.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    type="email"
                    v-model="create_user.email"
                    :error-messages="create_user_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Phone Number"
                    type="number"
                    v-model="create_user.phone"
                    :error-messages="create_user_errors.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    outlined
                    dense
                    label="Gender"
                    :items="gender"
                    v-model="create_user.gender"
                    :error-messages="create_user_errors.gender"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    type="text"
                    v-model="create_user.state"
                    :error-messages="create_user_errors.state"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    type="text"
                    v-model="create_user.city"
                    :error-messages="create_user_errors.city"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12">
                  <v-autocomplete
                    label="Country"
                    v-model="create_user.country"
                    :error-messages="create_user_errors.country"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                   <div>
                          <v-spacer></v-spacer>
                          <v-checkbox
                            v-model="selectAll"
                            @change="selectAllOptions"
                            label="tick all branches"
                            class="d-flex justify-end text-right"
                          >
                          </v-checkbox>
                        </div>
                  <v-select
                    v-model="create_user.branches"
                    :items="branchesData"
                    attach
                    chips
                    label="Branches"
                    item-text="name"
                    item-value="id"
                    multiple
                    outlined
                    :error-messages="create_user_errors.branches"
                  >

                </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="create_user.roles"
                    :items="rolesData"
                    attach
                    chips
                    label="Roles"
                    item-text="name"
                    item-value="id"
                    multiple
                    outlined
                    :error-messages="create_user_errors.roles"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    dense
                    label="Address"
                    v-model="create_user.address"
                    :error-messages="create_user_errors.address"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :value="userPassword"
                    required
                    label="Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    v-model="create_user.password"
                    :error-messages="create_user_errors.password"
                    @click:append="show1 = !show1"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Confirm Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    v-model="create_user.password_confirmation"
                    :error-messages="create_user_errors.password_confirmation"
                    @click:append="show2 = !show2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Create</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
import Countries from "@/models/countries";

export default {
  components: { SmartChurchLayout },
  data() {
    return {
      create_user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        // city: "",
        password: "",
        password_confirmation: "",
        gender: "",
        branches: [],
        roles: [],
      },
      gender: ["male", "female"],
      show1: false,
      show2: false,
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      isSubmitted: false,

      selectAll: false,
      countries: [],

      rolesData: [],
      branchesData: [],

      create_user_errors: {},
      userPassword: "",
    };
  },

  computed: {
    isMismatched() {
      if (
        this.create_user.password !== this.create_user.password_confirmation
      ) {
        return false;
      }
    },
  },
  methods: {
    async initUsers() {
      try {
        const roles = await this.$http.get(`admin/roles`);
        const branches = await this.$http.get(`admin/getBranches`);

        const rolesInArray = [roles.data];
        this.rolesData = rolesInArray[0].data;
        this.branchesData = branches.data.data;

        // console.log(this.rolesData);
        console.log(this.branchesData);
      } catch (error) {
        catchAxiosExceptions(error);
      }
    },
    createUser() {
      this.create_user.error = "";

      this.isSubmitted = true;

      this.$http
        .post("admin/users", this.create_user)
        .then((response) => {
          
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          // this.isSubmitted = false;
          this.isSubmitted = false;
          this.$refs.form.reset();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
          this.isSubmitted = false;
          this.create_user_errors = error.response.data.errors;
          catchAxiosExceptions(error);
        });
    },

     selectAllOptions() {
      if (this.selectAll) {
        this.create_user.branches = this.branchesData.map(
          (branch) => branch.id
        );
      } else {
         this.create_user.branches = [];
      }
    },
  },
  mounted() {
    this.countries = Countries;
    this.initUsers();
  },
};
</script>

<style></style>
