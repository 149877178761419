
<template>
  <smart-church-layout page="Email Communication">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container class="my-10">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10" lg="10">
          <!-- <p class="text-right mb-0 mt-5">
            <v-btn color="primary white--text" :to="{ name: 'emailAutomation' }"><v-icon>mdi-send</v-icon>Create Birthday Template</v-btn>
          </p> -->
          <v-card
            class="d-flex justify-center mt-2 rounded-lg text-capitalize elevation-0"
          >
            <v-form class="my-6 pa-13" ref="form">
              <h2 class="mb-4 font-weight-bold primary--text">Send Email</h2>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    label="to"
                    outlined
                    :items="items"
                    v-model="selectedRecipient"
                    :error-messages="errors.selectedRecipient"
                    @change="loadEmails"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    label="Subject"
                    outlined
                    v-model="subject"
                    :error-messages="errors.subject"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="selectedEmails"
                    item-text="name"
                    item-value="email"
                    :items="emails"
                    chips
                    label="Email Address"
                    multiple
                    outlined
                    dense
                    :error-messages="errors.selectedEmails"
                  >
                    <template #prepend-item>
                      <v-list-item @click="selectAllEmails">
                        <v-list-item-action>
                          <v-checkbox v-model="selectAll"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>Select All</v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <vue-editor
                    v-model="content"
                    :error-messages="errors.content"
                  />
                </v-col>
              </v-row>
              <div>
                <v-btn
                  color="primary white--text"
                  :loading="isSubmitted"
                  :disabled="isSubmitted"
                  @click="sendEmail()"
                  >Send Message</v-btn
                >
              </div>
            </v-form>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="allEmails"
            class="elevation-1 mt-5"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ getFormattedDate(item.created_at) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { VueEditor } from "vue2-editor";
import { getFormattedDate } from "../../__helpers/handelExceptions";

import StarRating from "vue-star-rating";

export default {
  components: { SmartChurchLayout, VueEditor, StarRating },
  data() {
    return {
      items: [
        { text: "Users", value: "pastors" },
        { text: "Members", value: "members" },
        { text: "First Timers", value: "firstTimer" },
      ],
      subject: "",
      content: "",
      emails: [],
      pastors: [],
      members: [],
      firstTimers: [],
      headers: [
        {
          text: "Date",
          value: "created_at",
          align: "start",
        },
        {
          text: "Email Address",
          value: "recipients",
        },
        {
          text: "To",
          value: "type",
        },
        {
          text: "Subject",
          value: "subject",
        },
        // { text: "Status", value: "mail", sortable: false },
      ],
      selectedRecipient: null,
      selectedEmails: [],
      selectAll: false,
      allEmails: [],
      isSubmitted: false,
      errors: {},
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      dialog: false,

      showNumber: false,
      starSize: 45,
      glow: 4,

      feedBack: {
        rating: 0,
        message: "",
      },
    };
  },
  methods: {
    async initialize() {
      await this.loadusers();
      await this.loadMembers();
      await this.loadFirstTimers();
      await this.displayEmails();
    },
    async loadusers() {
      try {
        const response = await this.$http.get(`admin/users`);
        this.pastors = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async loadMembers() {
      try {
        const response = await this.$http.get("admin/getMembers");
        this.members = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async loadFirstTimers() {
      try {
        const response = await this.$http.get("admin/firstTimers");
        this.firstTimers = response.data.data;
        console.log(this.firstTimers);
      } catch (error) {
        console.log(error);
      }
    },

    loadEmails() {
      if (this.selectedRecipient === "pastors") {
        this.emails = this.pastors.map((pastor) => pastor.email);
      } else if (this.selectedRecipient === "members") {
        this.emails = this.members.map((member) => member.email);
      } else if (this.selectedRecipient === "firstTimer") {
        this.emails = this.firstTimers.map((firstTimer) => firstTimer.email);
      } else {
        this.emails = [];
      }
      this.selectedEmails = [];
    },

    sendEmail() {
      // this.isSubmitted = true;
      const payload = {
        subject: this.subject,
        body: this.content,
        recipients: this.selectedEmails,
        type: this.selectedRecipient,
      };

      if (
        this.selectedRecipient === "pastors" ||
        this.selectedRecipient === "members" ||
        this.selectedRecipient === "firstTimer"
      ) {
        // payload["users"] = this.selectedEmails;
        this.isSubmitted = true;
        this.$http
          .post("admin/announcement", payload)
          .then((response) => {
            this.isSubmitted = false;
            this.snackbar.status = true;
            this.snackbar.msg = response.data.message;
            this.snackbar.color = "green";
            this.$refs.form.reset();

            if (!localStorage.getItem("checkedRatingForm")) {
              this.dialog = true;
            }
          })
          .catch((error) => {
            this.snackbar.status = true;
            this.snackbar.msg = error.response.data.message;
            this.snackbar.color = "red";
            this.isSubmitted = false;
            this.errors = error.response.data.errors;
          });
      }
    },
    selectAllEmails() {
      if (this.selectAll) {
        this.selectedEmails = this.emails.slice();
      } else {
        this.selectedEmails = [];
      }
    },
    async displayEmails() {
      this.$http.get("admin/announcement").then((response) => {
        this.allEmails = response.data.data;
        console.log(this.allEmails);
        this.sendEmail();
      });
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },

    addFeedback() {
      this.isSubmitted = true;

      this.$http
        .post("feedback", this.feedBack)
        .then((response) => {
          // console.log(response.data.message);
          this.isSubmitted = false;
          this.dialog = false;
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.$refs.ratingForm.reset();
          this.feedBack.rating = 0;
          localStorage.setItem("checkedRatingForm", true);
        })
        .catch((error) => {
          // console.log(error);
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

