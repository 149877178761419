<template>
  <smart-church-layout page="Global Reports">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" class="mt-7" /> -->

    <v-container fluid class="my-8">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card class="mt-12 rounded-lg text-capitalize p-5">
            <h3 class="primary--text my-4 font-weight-bold text-center">
              SmartChurch Global Report
            </h3>
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedData.from"
                  required
                  item-text="month"
                  item-value="value"
                  label="From"
                  outlined
                  return-object
                  dense
                  type="date"
                  @input="clearData"
                  :error-messages="globalReport_errors.from"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedData.to"
                  required
                  item-text="month"
                  item-value="value"
                  label="To"
                  outlined
                  return-object
                  dense
                  type="date"
                  @input="clearData"
                  :error-messages="globalReport_errors.to"
                ></v-text-field>
              </v-col>

              <v-btn
                color="primary"
                @click="getGlobalReport"
                :loading="loading"
                :disabled="loading"
                >generate report</v-btn
              >
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="showReport">
          <div id="pdf-content">
            <div>
              <h3
                class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
              >
                Branch Reports
              </h3>
              <template>
                <v-data-table
                  :headers="branchReportsHeaders"
                  :items="branchReportDetails"
                  class="elevation-1 py-2"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="text-capitalize"> {{ item.name }}</span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ getFormattedDate(item.created_at) }}
                  </template>
                  <template v-slot:[`item.highest_attendance`]="{ item }">
                    {{
                      item.highest_attendance
                        ? getFormattedAmount(Math.ceil(item.highest_attendance))
                        : "0"
                    }}
                  </template>
                  <template v-slot:[`item.lowest_attendance`]="{ item }">
                    {{
                      item.lowest_attendance
                        ? getFormattedAmount(Math.ceil(item.lowest_attendance))
                        : "0"
                    }}
                  </template>
                  <template v-slot:[`item.avg_attendance`]="{ item }">
                    {{
                      item.avg_attendance
                        ? getFormattedAmount(Math.ceil(item.avg_attendance))
                        : "0"
                    }}
                  </template>
                  <template v-slot:[`item.new_convert`]="{ item }">
                    {{
                      item.new_convert
                        ? getFormattedAmount(Math.ceil(item.new_convert))
                        : "0"
                    }}
                  </template>
                  <template v-slot:[`item.first_timers`]="{ item }">
                    {{
                      item.first_timers
                        ? getFormattedAmount(item.first_timers)
                        : "0"
                    }}
                  </template>
                  <template v-slot:[`item.total_income`]="{ item }">
                    ₦{{
                      item.total_income
                        ? getFormattedAmount(Math.ceil(item.total_income))
                        : "0"
                    }}
                  </template>
                  <template v-slot:[`item.total_expense`]="{ item }">
                    ₦{{
                      item.total_expense
                        ? getFormattedAmount(Math.ceil(item.total_expense))
                        : "0"
                    }}
                  </template>
                </v-data-table>
              </template>

              <v-row>
                <v-col cols="12">
                  <h3
                    class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                  >
                    Total Income and Expense Chart
                  </h3>
                  <v-card class="p-3">
                    <bar-chart
                      :chart-data="{
                        labels: [churchName],
                        datasets: [
                          {
                            label: 'Income',
                            backgroundColor: '#3366CC',
                            data: [Number(incomeExpenseChartIncomeValue)],
                          },
                          {
                            label: 'Expenditure',
                            backgroundColor: '#880808',
                            data: [Number(incomeExpenseChartExpenseValue)],
                          },
                        ],
                      }"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                          padding: {
                            left: 40,
                            right: 40,
                            top: 40,
                            bottom: 40,
                          },
                        },
                      }"
                    />
                    <p class="text-center fw-bold p-2 fs-5">
                      This shows the summation of all Branch Income and Expense
                    </p>
                    <p class="text-center fs-6 p-2 mt-0">
                      You spent
                      {{
                        percentageTotal
                          ? getFormattedAmount(percentageTotal)
                          : "0"
                      }}% of Income on Expenditure
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" lg="6">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Income Chart
                </h3>
                <v-card class="p-1">
                  <div v-if="checkIfBranchReportsIsPresent">
                    <pie-chart
                      :chart-data="pieChartBranchIncomeData"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                          padding: {
                            left: 40,
                            right: 40,
                            top: 40,
                            bottom: 40,
                          },
                        },
                      }"
                    />
                  </div>
                  <p class="text-center p-2 fw-bold fs-5">
                    This shows all Incomes for each Branch
                  </p>
                </v-card>
              </v-col>
              <v-col cols="12" lg="6">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Expense Chart
                </h3>
                <v-card class="p-1">
                  <div v-if="checkIfBranchReportsIsPresent">
                    <pie-chart
                      :chart-data="pieChartBranchExpenseData"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                          padding: {
                            left: 40,
                            right: 40,
                            top: 40,
                            bottom: 40,
                          },
                        },
                      }"
                    />
                  </div>
                  <p class="text-center p-2 fw-bold fs-5">
                    This shows all Expenses for each Branch
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="text-end justify-end d-none d-md-none d-lg-flex">
            <v-btn @click="downloadPDF" color="primary" class="fw-bold"
              >Download Report as PDF</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue";
import BarChart from "../../components/charts/BarChart.vue";
import PieChart from "../../components/charts/PieChart.vue";
import LineChart from "../../components/charts/LineChart.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import {
  catchAxiosExceptions,
  getReportYears,
  getFormattedAmount,
  getFormattedDate,
  getTotalAttendance,
} from "../../__helpers/handelExceptions";

export default {
  components: {
    SmartChurchLayout,

    BarChart,
    PieChart,
    LineChart,
  },

  data() {
    return {
      overlay: true,

      years: [],

      globalReport_errors: {},

      // selectedData: {
      //   year: null
      // },
      selectedData: {
        from: null,
        to: null,
      },

      showReport: false,
      loading: false,

      globalReports: [],
      serviceReports: [],
      serviceData: [],

      branchIncomeChart: [],
      branchIncomeChartData: [],
      incomeValues: [],
      incomeData: [],

      expenseData: [],
      expenseChart: [],
      expenseChartData: [],
      expenseValues: [],

      incomeExpenseChartIncomeValue: [],
      incomeExpenseChartExpenseValue: [],
      user: this.$store.state.userDetail.user,

      percentageTotal: 0,

      pieChartBranchIncomeData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      serviceAttendanceStatistics: [],
      branchReportsHeaders: [
        { text: "Branch", value: "name" },
        { text: "Service Report Count", value: "service_reports_count" },
        { text: "Highest Attendance", value: "highest_attendance" },
        { text: "Lowest Attendance", value: "lowest_attendance" },
        { text: "Average Attendance", value: "avg_attendance" },
        { text: "New Convert", value: "new_convert" },
        { text: "First Timer", value: "first_timers" },

        { text: "Total Income", value: "total_income" },
        { text: "Total Expenditure", value: "total_expense" },
      ],
      branchReportDetails: [],

      pieChartBranchExpenseData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      churchName: "",

      branchesIncomeData: [],
      branchesExpenseData: [],

      isLoading: false,
      // mountedError: null,
    };
  },

  computed: {
    checkIfBranchReportsIsPresent() {
      return this.branchReportDetails.length > 0;
    },
  },

  methods: {
    async initGlobalReport() {
      this.isLoading = true;

      try {
        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        this.isLoading = false;
        this.overlay = false;

        catchAxiosExceptions(error);
      }
    },
    getGlobalReport() {
      this.loading = true;
      this.$http
        .get(
          `/admin/churchReportRange?from=${this.selectedData.from}&to=${this.selectedData.to}`
        )
        .then((response) => {
          this.loading = false;
          this.showReport = true;
          this.globalReports = response.data.data;
          // console.log(this.globalReports)
          this.churchName = this.globalReports.data.name;
          this.serviceData = this.globalReports.data;
          this.serviceAttendanceStatistics = this.globalReports.services;

          this.incomeExpenseChartIncomeValue = this.serviceData.total_income;
          this.incomeExpenseChartExpenseValue = this.serviceData.total_expense;

          let percentageTotal =
            (this.incomeExpenseChartExpenseValue /
              this.incomeExpenseChartIncomeValue) *
            100;

          this.percentageTotal = Math.ceil(percentageTotal);
          // branch Report
          this.branchReportDetails = this.globalReports.branches;

          // Branch Income
          let branchIncomeArray = [];

          this.branchReportDetails.forEach((branchIncome) => {
            // console.log(branchIncome.name, branchIncome.total_income);
            if (
              branchIncome.name !== null ||
              branchIncome.total_income !== null ||
              branchIncome.name != "" ||
              branchIncome.total_income != ""
            ) {
              branchIncomeArray.push([
                branchIncome.name,
                Number(branchIncome.total_income),
              ]);
            }
            return branchIncomeArray;
          });
          // console.log(branchIncomeArray);

          const reducedBranchIncomeData = branchIncomeArray.reduce(
            (accumulator, [income, value]) => {
              if (accumulator[income]) {
                accumulator[income] += value;
              } else {
                accumulator[income] = value;
              }

              return accumulator;
            },
            []
          );

          this.branchIncomeChartData = reducedBranchIncomeData;

          this.pieChartBranchIncomeData.labels = Object.keys(
            this.branchIncomeChartData
          );
          // console.log(this.pieChartBranchIncomeData.labels);

          this.pieChartBranchIncomeData.datasets[0].data = Object.values(
            this.branchIncomeChartData
          );

          this.pieChartBranchIncomeData.datasets[0].backgroundColor =
            this.generateBackgroundColors(
              this.pieChartBranchIncomeData.labels.length
            );

          // Branch Expense
          let branchExpenseArray = [];

          this.branchReportDetails.forEach((branchExpense) => {
            // console.log(branchIncome.name, branchIncome.total_income);
            if (
              branchExpense.name !== null ||
              branchExpense.total_expense !== null ||
              branchExpense.name != "" ||
              branchExpense.total_expense != ""
            ) {
              branchExpenseArray.push([
                branchExpense.name,
                Number(branchExpense.total_expense),
              ]);
            }
            return branchExpenseArray;
          });
          // console.log(branchExpenseArray, 12345);

          const reducedBranchExpenseData = branchExpenseArray.reduce(
            (accumulator, [expense, value]) => {
              if (accumulator[expense]) {
                accumulator[expense] += value;
              } else {
                accumulator[expense] = value;
              }

              return accumulator;
            },
            []
          );

          this.branchExpenseChartData = reducedBranchExpenseData;

          this.pieChartBranchExpenseData.labels = Object.keys(
            this.branchExpenseChartData
          );
          // console.log(this.pieChartBranchExpenseData.labels);

          this.pieChartBranchExpenseData.datasets[0].data = Object.values(
            this.branchExpenseChartData
          );

          this.pieChartBranchExpenseData.datasets[0].backgroundColor =
            this.generateBackgroundColors(
              this.pieChartBranchExpenseData.labels.length
            );
        })
        .catch((error) => {
          this.loading = false;
          catchAxiosExceptions(error);
        });
    },
    downloadPDF() {
      const pdf = new jsPDF("p", "mm", "a4");

      html2canvas(document.getElementById("pdf-content")).then((canvas) => {
        const imgData = canvas.toDataURL("image/JPEG");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("document.pdf");
      });
    },

    generateBackgroundColors(count) {
      const backgroundColors = [];
      // Generate a random color for each label
      for (let i = 0; i < count; i++) {
        const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        backgroundColors.push(color);
      }
      return backgroundColors;
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    getTotalAttendance(report) {
      return getTotalAttendance(report);
    },

    clearData() {
      this.serviceData = [];

      this.branchReportDetails = [];
      this.incomeExpenseChartIncomeValue = 0;
      this.incomeExpenseChartExpenseValue = 0;
      this.percentageTotal = 0;
    },
  },

  mounted() {
    this.initGlobalReport();
  },
};
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
#report {
  border-collapse: collapse;
  width: 100%;
}
.pdf-content-white {
  background-color: white;
}

#report td,
#report th {
  padding: 8px;
  font-size: 1.15rem;
}

#report tr:nth-child(even) {
  /* background-color: #f2f2f2; */
  border: 1px solid #ddd;
}
</style>
