<template>
  <smart-church-layout page="Edit Service Reports">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <v-container fluid class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminServiceReport' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initEditServiceReport"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="mt-12 rounded-lg text-capitalize">
            <v-form
              class="my-6 pa-13"
              ref="form"
              @submit.prevent="updateServiceReport"
            >
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                Edit service report
              </h2>
              <v-row class="mt-4">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="serviceReport_details.branch_id"
                    :items="branches"
                    dense
                    label="Branch"
                    id="branch_id"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="serviceReport_details_errors.branch_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="serviceReport_details.service_type_id"
                    :items="services"
                    dense
                    id="service_type_id"
                    item-value="id"
                    outlined
                    label="Services"
                    item-text="name"
                    :error-messages="
                      serviceReport_details_errors.service_type_id
                    "
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    dense
                    outlined
                    :items="days"
                    label="Service Day"
                    v-model="serviceReport_details.service_day"
                    :error-messages="serviceReport_details_errors.service_day"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="date"
                    dense
                    label="Service Date"
                    outlined
                    v-model="serviceReport_details.service_date"
                    :error-messages="serviceReport_details_errors.service_date"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <div>Church Attendance</div>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Male Attendance"
                    v-model="serviceReport_details.male_attendance"
                    :error-messages="
                      serviceReport_details_errors.male_attendance
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Female Attendance"
                    v-model="serviceReport_details.female_attendance"
                    :error-messages="
                      serviceReport_details_errors.female_attendance
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Children Attendance"
                    v-model="serviceReport_details.children_attendance"
                    :error-messages="
                      serviceReport_details_errors.children_attendance
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="Teen Attendance"
                    v-model="serviceReport_details.teenager_attendance"
                    :error-messages="
                      serviceReport_details_errors.teenager_attendance
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="First Timers"
                    v-model="serviceReport_details.first_timers"
                    :error-messages="serviceReport_details_errors.first_timers"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    label="New Convert"
                    v-model="serviceReport_details.new_convert"
                    :error-messages="serviceReport_details_errors.new_convert"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <div>About sermon</div>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    label="Service Title"
                    v-model="serviceReport_details.service_title"
                    :error-messages="serviceReport_details_errors.service_title"
                  ></v-text-field>
                  <small class="mb-4">Service Title Not Compulsory</small>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    label="Topic"
                    v-model="serviceReport_details.topic"
                    :error-messages="serviceReport_details_errors.topic"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    dense
                    outlined
                    label="Speaker"
                    v-model="serviceReport_details.speaker"
                    :error-messages="serviceReport_details_errors.speaker"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    dense
                    outlined
                    label="Notes"
                    v-model="serviceReport_details.notes"
                    :error-messages="serviceReport_details_errors.notes"
                  ></v-textarea>
                </v-col>

                <v-btn
                  type="submit"
                  color="primary white--text"
                  :loading="isSubmitted"
                  :disabled="isSubmitted"
                  >save</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
export default {
  components: { SmartChurchLayout },

  data() {
    return {
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      serviceReports: [],
      serviceReport_details: {},
      serviceReport_details_errors: {},

      incomeTitles: [],
      income_titles_error: [],
      branches: [],
      services: [],

      routeParamSlug: this.$route.params.serviceReport_slug,

      isLoading: false,

      isSubmitted: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      mountedError: null,
      overlay: true
    }
  },

  methods: {
    async initEditServiceReport() {
      this.isLoading = true

      if (this.routeParamSlug === undefined || this.routeParamSlug.length < 1) {
        this.$router.push({ name: "service-report" })
      } else {
        try {
          const displayService = await this.$http.get("admin/service-type")
          
          const displayIncome = await this.$http.get("admin/income-title")
          const serviceReport = await this.$http.get(
            "admin/service-report/" + this.routeParamSlug
            )
            const user = this.$store.state.userDetail.user;

          this.services = displayService.data.data
          this.serviceReport_details = serviceReport.data.data
          this.serviceReport_details.incomes = []

          this.branches = user.branches;
          this.incomeTitles = displayIncome.data.data

          this.isLoading = false
          this.overlay = false
        } catch (error) {
          // this.mountedError = error.response.data.message
          this.isLoading = false
          this.overlay = false

          catchAxiosExceptions(error)
        }
      }
    },

    updateServiceReport() {
      this.serviceReport_details.error = ""

      this.isSubmitted = true

      this.$http
        .put(
          "admin/service-report/" + this.serviceReport_details.slug,
          this.serviceReport_details
        )
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          this.isSubmitted = false
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.serviceReport_details_errors = error.response.data.errors
          this.isSubmitted = false
          catchAxiosExceptions(error)
        })
    },
  },
  mounted() {
    this.initEditServiceReport()
  },
}
</script>

<style></style>
