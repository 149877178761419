<template>
  <smart-church-layout page="Branches">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by Branch, Email, Phone"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-content-around mb-5 mt-10">
        <h3 class="primary--text font-weight-bold">Branches</h3>
        <v-btn
          color="primary text--white"
          class="mx-5 font-weight-bold"
          :to="{ name: 'AdminCreateBranch' }"
          v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles"
          ><v-icon left>mdi-plus</v-icon><span>Create branch</span></v-btn
        >
        <v-spacer></v-spacer>
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search by Branch, Email, Phone "
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

      <v-data-table
        :headers="headers"
        :items="branches"
        :search="search"
        class="elevation-1"
        sort-by="id"
        sort-desc.sync="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                  {{ branch_items.head_pastor_id }}
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="branch_items.name"
                          :error-messages="branch_items_errors.name"
                          label=" name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="branch_items.email"
                          :error-messages="branch_items_errors.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="branch_items.address"
                          :error-messages="branch_items_errors.address"
                          label="address"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="branch_items.phone"
                          :error-messages="branch_items_errors.phone"
                          label="phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="branch_items.country"
                          :error-messages="branch_items_errors.country"
                          label="country"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="branch_items.state"
                          :error-messages="branch_items_errors.state"
                          label="state"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="branch_items.city"
                          :error-messages="branch_items_errors.city"
                          label="city"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="branch_items.year"
                          :error-messages="branch_items_errors.year"
                          label="year"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-switch
                          v-model="branch_items.is_head_branch"
                          label="Is this the head branch?"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-switch
                          v-model="branch_items.is_active"
                          label="Is this branch active?"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close"> Cancel </v-btn>
                  <v-btn color="primary" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this branch?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="p-0">
              <v-list-item  v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none textColor--text"
                    :to="{
                      name: 'AdminEditBranch',
                      params: { branch_slug: item.slug },
                    }"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                    Edit
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item  v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none text-dark"
                    :to="{
                      name: 'AdminViewBranch',
                      params: { branch_slug: item.slug },
                    }"
                  >
                    <v-icon small class="mr-1"> mdi-eye </v-icon>
                    <span class="textColor--text">View</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <a @click="deleteItem(item)"
                    ><v-icon small class="mr-1"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/DataTableLoader.vue"
import { mapGetters } from "vuex";

export default {
  components: { SmartChurchLayout },
  data() {
    return {
      search: "",
      items: ["yes", "no"],
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Address", value: "address" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      branches: [],
      editedIndex: -1,
      overlay: true,

      branch_items_errors: {},

      branch_items: {
        name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        year: null,
        is_head_branch: false,
        is_active: "",
        response: "",
        error: "",
      },

      defaultItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        year: null,
        is_head_branch: false,
        response: "",
        error: "",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isLoading: false,
      mountedError: null,

      user: this.$store.state.userDetail.user,

      userPermissionNames: [],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Branch" : "Edit Branch";
    },

   ...mapGetters(['noRoles','canManageUsers','canViewUsers','canManageRoles','canViewRoles']),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {},

  methods: {
    allBranches() {
      this.isLoading = true;

      this.$http
        .get("admin/getBranches")
        .then((response) => {
          this.branches = response.data.data;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.mountedError = error.response.data.message;
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.branches.indexOf(item);
      this.branch_items = Object.assign({}, item);
      this.dialog = true;

      this.$http
        .put("admin/branches/" + this.branch_items.slug)
        .then((response) => {
          this.branch_items.response = response.data.message;

          this.allBranches();
        })
        .catch((error) => {
          this.branch_items.error = error.response.data.message;

          catchAxiosExceptions(error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.branches.indexOf(item);
      this.branch_items = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/branches/" + this.branch_items.slug)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.allBranches();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          catchAxiosExceptions(error);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.branch_items = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.branch_items = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$http
        .put("admin/branches/" + this.branch_items.slug, this.branch_items)
        .then((response) => {
          this.branch_items.response = response.data.message;

          this.close();
          this.allBranches();
        })

        .catch((error) => {
          this.branch_items.error = error.response.data.message;
          catchAxiosExceptions(error);
        });
    },
  },

  created() {
    this.$store.commit("allPermissions");
  },
  mounted() {
    this.allBranches();
  },
};
</script>

<style >
.bg-transparent {
  background: transparent;
}
h3 {
  font-size: 24px !important;
}
/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
.router-link {
  text-decoration: none;
}
</style>
