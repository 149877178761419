<template>
  <content-loader
    viewBox="0 0 900 800"
    :speed="2"
    primaryColor="#3333"
     secondaryColor="#fff"
  >
    <rect x="55" y="6" rx="4" ry="4" width="280" height="150" />
    <!-- <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
    <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
    <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
    <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
    <rect x="8" y="104" rx="4" ry="4" width="35" height="38" /> -->
  </content-loader>
</template>
<script>
import { ContentLoader } from "vue-content-loader"
export default {
  components: { ContentLoader },
}
</script>

<style></style>
