<template>
  <smart-church-layout page="Subscription">
    <skeletonLoader v-if="overlay" />
    <!--when skeleton Loader stops displaying the main content is rendered-->
    <v-container v-else>
      <v-dialog v-model="unConfirmedPaymentDialog" persistent max-width="600">
        <v-card class="pa-3">
          <v-card-actions class="justify-end">
            <v-btn text @click="closeConfirmationPaymentDialog">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text class="fs-4 pa-2">
            Please the payment for subscription was not confirmed.
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-row class="my-5" v-if="showWelcomeSection">
        <v-col cols="12" lg="6">
          <v-img
            src="../../assets/Welcome 2.png"
            width="500"
            class="mb-5"
          ></v-img>
        </v-col>
        <v-col cols="12" lg="6" justify="center" class="mt-lg-6 mt-0">
          <h2 class="primary--text fw-bold mt-6">
            You have no Active Subscription!
          </h2>

          <p class="mt-4 fs-4">
            Please take advantage of this opportunity to subscribe to any plan
            of your choice to enjoy unlimited access to all the features
          </p>

          <v-dialog
            transition="dialog-bottom-transition"
            max-width="1000"
            persistent
            v-model="createSubscriptionDialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" large v-bind="attrs" v-on="on"
                >Create Subscription
                <v-icon right large> mdi-menu-right </v-icon></v-btn
              >
            </template>

            <v-card class="rounded-lg">
              <v-toolbar color="primary" dark class="text-center">
                <v-spacer></v-spacer>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closeCreateSubDialog">
                    <v-icon>mdi-window-close</v-icon></v-btn
                  >
                </v-card-actions>
              </v-toolbar>
              <v-row class="p-2">
                <v-col cols="12" lg="8" class="w-80 mx-auto">
                  <!-- <div v-if="createdSuccessful" class="alert alert-success">
                {{ create_subscription.response }}
              </div>
              <div v-if="cancelSubscription" class="alert alert-success">
                {{ cancelSubscription }}
              </div> -->

                  <v-form
                    class="pa-7"
                    ref="form"
                    @submit.prevent="createSubscription"
                  >
                    <h3
                      class="primary--text d-flex justify-center font-weight-bold text-center"
                    >
                      Payment Details
                    </h3>

                    <v-row class="mt-6">
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="create_subscription.subscription_plan_id"
                          :items="plans"
                          required
                          dense
                          label="Subscription Plan"
                          @change="viewCreateSubscriptionPlan"
                          item-text="name"
                          item-value="id"
                          outlined
                          :error-messages="errors.subscription_plan_id"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-select
                          :items="type"
                          label="Subscription Type"
                          v-model="create_subscription.type"
                          @change="viewCreateSubscriptionPlan"
                          :error-messages="errors.type"
                          outlined
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          outlined
                          dense
                          label="Quantity"
                          type="number"
                          min="0"
                          v-model="create_subscription.qty"
                          @keyup="viewCreateSubscriptionPlan"
                          :error-messages="errors.qty"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-btn
                      color="primary"
                      class="mt-3"
                      block
                      :loading="isLoading"
                      :disabled="isCreateSubscriptionFormInvalid"
                      @click="createSubscription"
                    >
                      Proceed to Payment
                    </v-btn>
                  </v-form>
                  <!-- </v-card> -->
                </v-col>

                <v-col cols="12" lg="4">
                  <!-- <v-card class="p-3 mt-5"> -->
                  <h3 class="text-center primary--text fw-bold mt-4">
                    Transaction Summary
                  </h3>
                  <v-simple-table class="mt-3">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td class="text-capitalize">
                            {{ create_subscription.subscription_plan_name }}
                          </td>
                        </tr>
                        <tr>
                          <td>Branch Limit</td>
                          <td>
                            {{
                              getFormattedAmount(
                                create_subscription.subscription_plan_branch_limit
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Member Limit</td>
                          <td>
                            {{
                              getFormattedAmount(
                                create_subscription.subscription_plan_member_limit
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Pastor Limit</td>
                          <td>
                            {{
                              create_subscription.subscription_plan_pastor_limit
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Price per Month</td>
                          <td>
                            ₦{{
                              getFormattedAmount(
                                create_subscription.subscription_plan_price_per_month
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Price per Year</td>
                          <td>
                            ₦{{
                              getFormattedAmount(
                                create_subscription.subscription_plan_price_per_year
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fw-bold fs-5">Total Amount</td>
                          <td class="fw-bold fs-5">
                            ₦{{
                              getFormattedAmount(
                                create_subscription.subscription_plan_total
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!-- </v-card> -->
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <div v-if="showCurrentSubscription">
        <v-row>
          <v-col cols="12" lg="6">
            <div class="d-flex d-lg-flex">
              <h3 class="mt-5 fw-bold primary--text d-none d-md-flex d-lg-flex">
                Current Subscription<v-icon color="primary"
                  >mdi-calendar-clock-outline</v-icon
                >
              </h3>
              <v-spacer></v-spacer>
              <v-dialog
                transition="dialog-bottom-transition"
                persistent
                v-model="dialogForUgradeSubscription"
                max-width="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mt-lg-6 mt-md-3 mb-1 mb-lg-1"
                    small
                    v-bind="attrs"
                    v-on="on"
                    >Upgrade Subscription</v-btn
                  >
                </template>

                <v-card class="rounded-lg">
                  <v-toolbar color="primary" dark class="text-center">
                    <v-spacer></v-spacer>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="closeUpgradeForm">
                        <v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </v-card-actions>
                  </v-toolbar>
                  <v-row class="p-2">
                    <v-col cols="12" lg="8" class="w-80 mx-auto">
                      <v-form
                        class="pa-7"
                        ref="upgradeForm"
                        @submit.prevent="upgradeSubscription"
                      >
                        <h3
                          class="primary--text d-flex justify-center font-weight-bold text-center"
                        >
                          Payment Details
                        </h3>

                        <v-row class="mt-6">
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="
                                upgrade_subscription_form.subscription_plan_id
                              "
                              :error-messages="
                                upgradeErrors.subscription_plan_id
                              "
                              @change="viewUpgradeSusbcriptionPlan"
                              :items="plans"
                              required
                              dense
                              label="Subscription Plan"
                              item-text="name"
                              item-value="id"
                              outlined
                            >
                            </v-select>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-select
                              @change="viewUpgradeSusbcriptionPlan"
                              :items="upgradeType"
                              label="Subscription Type"
                              v-model="upgrade_subscription_form.type"
                              :error-messages="upgradeErrors.type"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="upgrade_subscription_form.qty"
                              :error-messages="upgradeErrors.qty"
                              outlined
                              dense
                              min="0"
                              @keyup="viewUpgradeSusbcriptionPlan"
                              label="Quantity"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-btn
                          color="primary"
                          class="mt-3"
                          block
                          @click="upgradeSubscription"
                          :disabled="isUpgradeSubscriptionFormInvalid"
                        >
                          Upgrade Subscription
                        </v-btn>
                      </v-form>
                    </v-col>

                    <v-col cols="12" lg="4">
                      <h3 class="text-center primary--text fw-bold mt-4">
                        Transaction Summary
                      </h3>
                      <v-simple-table class="mt-3">
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td class="text-capitalize">
                                {{
                                  upgrade_subscription_form.subscription_plan_name
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Branch Limit</td>
                              <td>
                                {{
                                  getFormattedAmount(
                                    upgrade_subscription_form.subscription_plan_branch_limit
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Member Limit</td>
                              <td>
                                {{
                                  getFormattedAmount(
                                    upgrade_subscription_form.subscription_plan_member_limit
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Pastor Limit</td>
                              <td>
                                {{
                                  upgrade_subscription_form.subscription_plan_pastor_limit
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Price per Month</td>
                              <td>
                                ₦{{
                                  getFormattedAmount(
                                    upgrade_subscription_form.subscription_plan_price_per_month
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Price per Year</td>
                              <td>
                                ₦{{
                                  getFormattedAmount(
                                    upgrade_subscription_form.subscription_plan_price_per_year
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fw-bold fs-5">Total Amount</td>
                              <td class="fw-bold fs-5">
                                ₦{{
                                  getFormattedAmount(
                                    upgrade_subscription_form.subscription_plan_total
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </div>

            <h3 class="mt-5 fw-bold primary--text d-flex d-md-none d-lg-none">
              Current Subscription<v-icon color="primary"
                >mdi-calendar-clock-outline</v-icon
              >
            </h3>

            <!-- <v-card> -->
            <v-simple-table class="pa-3 elevation-3">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-tag-multiple-outline</v-icon
                      ><span class="fs-6 ms-2">Name</span>
                    </td>
                    <td class="text-capitalize">
                      {{ currentSubscriptionPlanName }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-home-city-outline</v-icon
                      ><span class="fs-6 ms-2">Branch Limit</span>
                    </td>
                    <td>
                      {{
                        getFormattedAmount(currentSubscriptionPlanBranchLimit)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-account-multiple-outline</v-icon
                      ><span class="fs-6 ms-2">Pastor Limit</span>
                    </td>
                    <td>
                      {{
                        getFormattedAmount(currentSubscriptionPlanPastorLimit)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-account-multiple-outline</v-icon
                      ><span class="fs-6 ms-2">Member Limit</span>
                    </td>
                    <td>
                      {{
                        getFormattedAmount(currentSubscriptionPlanMemberLimit)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-cash-multiple</v-icon
                      ><span class="fs-6 ms-2">Amount</span>
                    </td>
                    <td>
                      ₦{{ getFormattedAmount(currentSubscriptionAmount) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-calendar-multiple</v-icon
                      ><span class="fs-6 ms-2">Type</span>
                    </td>
                    <td class="text-capitalize">
                      {{ currentSubscriptionType }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-timer-outline</v-icon
                      ><span class="fs-6 ms-2">Duration</span>
                    </td>
                    <td>{{ currentSubscriptionDuration }}</td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-calendar-outline</v-icon
                      ><span class="fs-6 ms-2">Date of Creation</span>
                    </td>
                    <td>
                      {{ getFormattedDate(currentSubscriptionDateOfCreation) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mb-1">mdi-calendar-outline</v-icon
                      ><span class="fs-6 ms-2">Expiry Date</span>
                    </td>
                    <td>
                      {{ getFormattedDate(currentSubscriptionExpiryDate) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-alert
              prominent
              type="warning"
              class="mt-3 elevation-2"
              v-if="currentSubscriptionExpired"
            >
              <v-row align="center">
                <v-col class="grow">
                  Your Current Subscription has Expired
                </v-col>
                <v-col class="shrink">
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="1000"
                    persistent
                    v-model="createSubscriptionDialog"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="black" small v-bind="attrs" v-on="on"
                        >Create Subscription
                      </v-btn>
                    </template>

                    <v-card class="rounded-lg">
                      <v-toolbar color="primary" dark class="text-center">
                        <v-spacer></v-spacer>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="closeCreateSubDialog">
                            <v-icon>mdi-window-close</v-icon></v-btn
                          >
                        </v-card-actions>
                      </v-toolbar>
                      <v-row class="p-2">
                        <v-col cols="12" lg="8" class="w-80 mx-auto">
                          <!-- <div v-if="createdSuccessful" class="alert alert-success">
                {{ create_subscription.response }}
              </div>
              <div v-if="cancelSubscription" class="alert alert-success">
                {{ cancelSubscription }}
              </div> -->

                          <v-form
                            class="pa-7"
                            ref="form"
                            @submit.prevent="createSubscription"
                          >
                            <h3
                              class="primary--text d-flex justify-center font-weight-bold text-center"
                            >
                              Payment Details
                            </h3>

                            <v-row class="mt-6">
                              <v-col cols="12" md="6">
                                <v-select
                                  v-model="
                                    create_subscription.subscription_plan_id
                                  "
                                  :items="plans"
                                  required
                                  dense
                                  label="Subscription Plan"
                                  @change="viewCreateSubscriptionPlan"
                                  item-text="name"
                                  item-value="id"
                                  outlined
                                  :error-messages="errors.subscription_plan_id"
                                >
                                </v-select>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-select
                                  :items="type"
                                  label="Subscription Type"
                                  v-model="create_subscription.type"
                                  @change="viewCreateSubscriptionPlan"
                                  :error-messages="errors.type"
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col cols="12">
                                <v-text-field
                                  outlined
                                  dense
                                  label="Quantity"
                                  type="number"
                                  min="0"
                                  v-model="create_subscription.qty"
                                  @input="viewCreateSubscriptionPlan"
                                  :error-messages="errors.qty"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-btn
                              color="primary"
                              class="mt-3"
                              block
                              :loading="isLoading"
                              :disabled="isCreateSubscriptionFormInvalid"
                              @click="createSubscription"
                            >
                              Proceed to Payment
                            </v-btn>
                          </v-form>
                          <!-- </v-card> -->
                        </v-col>

                        <v-col cols="12" lg="4">
                          <!-- <v-card class="p-3 mt-5"> -->
                          <h3 class="text-center primary--text fw-bold mt-4">
                            Transaction Summary
                          </h3>
                          <v-simple-table class="mt-3">
                            <template v-slot:default>
                              <tbody>
                                <tr>
                                  <td>Name</td>
                                  <td class="text-capitalize">
                                    {{
                                      create_subscription.subscription_plan_name
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Branch Limit</td>
                                  <td>
                                    {{
                                      getFormattedAmount(
                                        create_subscription.subscription_plan_branch_limit
                                      )
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Member Limit</td>
                                  <td>
                                    {{
                                      getFormattedAmount(
                                        create_subscription.subscription_plan_member_limit
                                      )
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pastor Limit</td>
                                  <td>
                                    {{
                                      create_subscription.subscription_plan_pastor_limit
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Price per Month</td>
                                  <td>
                                    ₦{{
                                      getFormattedAmount(
                                        create_subscription.subscription_plan_price_per_month
                                      )
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Price per Year</td>
                                  <td>
                                    ₦{{
                                      getFormattedAmount(
                                        create_subscription.subscription_plan_price_per_year
                                      )
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="fw-bold fs-5">Total Amount</td>
                                  <td class="fw-bold fs-5">
                                    ₦{{
                                      getFormattedAmount(
                                        create_subscription.subscription_plan_total
                                      )
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <!-- </v-card> -->
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>

          <v-col cols="12" lg="6">
            <h3 class="mb-1 mt-6 fw-bold primary--text">
              Available Plans<v-icon color="primary">mdi-gift-outline</v-icon>
            </h3>

            <v-data-table
              :headers="planHeaders"
              :items="allSubscriptionPlans"
              hide-default-footer
              class="elevation-2 p-3"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="subscriptionPlanDialog" max-width="500px">
                    <v-card class="pa-2">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="subscriptionPlanDialog = false">
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-card-actions>

                      <h2 class="text-center primary--text text-capitalize">
                        {{ matchingPlanItemName }} Plan
                        <v-icon class="mb-1 fs-3" color="primary"
                          >mdi-tag-multiple-outline</v-icon
                        >
                      </h2>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <v-icon class="mb-1 me-2"
                                  >mdi-cash-multiple</v-icon
                                >Price Per Month
                              </td>
                              <td>
                                ₦{{
                                  getFormattedAmount(
                                    matchingPlanItemPricePerMonth
                                  )
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <v-icon class="mb-1 me-2"
                                  >mdi-cash-multiple</v-icon
                                >Price Per Year
                              </td>
                              <td>
                                ₦{{
                                  getFormattedAmount(
                                    matchingPlanItemPricePerYear
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <v-icon class="mb-1 me-2"
                                  >mdi-home-city-outline</v-icon
                                >Branch Limit
                              </td>
                              <td>
                                {{
                                  getFormattedAmount(
                                    matchingPlanItemBranchLimit
                                  )
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <v-icon class="mb-1 me-2"
                                  >mdi-account-multiple-outline</v-icon
                                >Member Limit
                              </td>
                              <td>
                                {{
                                  getFormattedAmount(
                                    matchingPlanItemMemberLimit
                                  )
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <v-icon class="mb-1 me-2"
                                  >mdi-account-multiple-outline</v-icon
                                >Pastor Limit
                              </td>
                              <td>
                                {{
                                  getFormattedAmount(
                                    matchingPlanItemPastorLimit
                                  )
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <v-icon class="mb-1 me-2"
                                  >mdi-cash-multiple</v-icon
                                >Turnover Limit
                              </td>
                              <td>
                                ₦{{
                                  getFormattedAmount(
                                    matchingPlanItemTurnoverLimit
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span class="text-capitalize">{{ item.name }}</span>
              </template>
              <template v-slot:[`item.price_per_month`]="{ item }">
                <span class="text-capitalize">
                  ₦{{ getFormattedAmount(item.price_per_month) }}</span
                >
              </template>
              <template v-slot:[`item.price_per_year`]="{ item }">
                <span class="text-capitalize">
                  ₦{{ getFormattedAmount(item.price_per_year) }}</span
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <span
                  depressed
                  @click="showFullPlanDetails(item)"
                  class="cursor-pointer"
                >
                  <v-icon class="mb-1"> mdi-eye-outline </v-icon>
                  <span>View</span>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <h3 class="mb-1 mt-6 fw-bold primary--text">
          Subscription History<v-icon color="primary">mdi-history</v-icon>
        </h3>
        <v-data-table
          :headers="subscriptionHistoryHeaders"
          :items="allSubscription"
          class="elevation-3"
        >
          <template v-slot:[`item.amount`]="{ item }">
            ₦{{ getFormattedAmount(item.amount) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ getFormattedDate(item.created_at) }}
          </template>
          <template v-slot:[`item.expiry_date`]="{ item }">
            {{ getFormattedDate(item.expiry_date) }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <span class="text-capitalize">{{ item.type }}</span>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import {
  catchAxiosExceptions,
  getFormattedAmount,
  getFormattedDate,
} from "../../__helpers/handelExceptions"
import skeletonLoader from "../../components/DataTableLoader.vue"

export default {
  components: { SmartChurchLayout, skeletonLoader },

  data() {
    return {
      dialog: false,
      createSubscriptionDialog: false,
      dialogForUgradeSubscription: false,
      subscriptionPlanDialog: false,
      unConfirmedPaymentDialog: false,
      dialogDelete: false,
      showWelcomeSection: false,
      showCurrentSubscription: false,
      isLoading: false,
      currentSubscriptionExpired: false,
      // createdSuccessful: false,
      // cancelSubscription: false,
      paymentDetailSection: false,

      type: ["monthly", "yearly"],
      upgradeType: ["monthly", "yearly"],
      plans: [],
      allSubscription: [],

      public_api_key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
      // create subscription
      amount: 0,
      dateOfCreation: "",
      subscriptionPlan: "",
      quantity: 0,
      subscriptionType: "",
      id: "",
      reference: "",

      // upgrade

      upgradeAmount: 0,
      upgradeDateOfCreation: "",
      upgradeSubscriptionPlan: "",
      upgradeQuantity: 0,
      upgradeSubscriptionType: "",
      upgradeId: "",
      upgradeSubscriptionPlanId: "",
      upgradeReference: "",

      email: this.$store.state.userDetail.user.church.email,
      churchSlug: this.$store.state.userDetail.user.church.slug,
      create_subscription: {
        subscription_plan_id: [],
        subscription_plan_name: "",
        subscription_plan_total: 0,
        subscription_plan_branch_limit: 0,
        subscription_plan_member_limit: 0,
        subscription_plan_pastor_limit: 0,
        subscription_plan_turnover_limit: 0,
        subscription_plan_price_per_month: 0,
        subscription_plan_price_per_year: 0,
        type: "",
        qty: "",
        response: "",
      },

      upgrade_subscription_form: {
        subscription_plan_id: "",
        subscription_plan_name: "",
        subscription_plan_total: 0,
        subscription_plan_branch_limit: 0,
        subscription_plan_member_limit: 0,
        subscription_plan_pastor_limit: 0,
        subscription_plan_turnover_limit: 0,
        subscription_plan_price_per_month: 0,
        subscription_plan_price_per_year: 0,
        qty: "",
        type: "",
      },

      errors: {},
      upgradeErrors: {},

      headers: [
        { text: "Date of Creation", value: "created_at" },
        { text: "Expiration Date", value: "expiry_date" },
        { text: "Amount", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Duration", value: "qty" },

        // { text: "Actions", value: "actions", sortable: false },
      ],

      subscriptionHistoryHeaders: [
        { text: "Date of Creation", value: "created_at" },
        { text: "Expiration Date", value: "expiry_date" },
        { text: "Amount", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Duration", value: "qty" },
      ],

      planHeaders: [
        { text: "Name", value: "name" },
        { text: "Price Per Month", value: "price_per_month" },
        { text: "Price Per Year", value: "price_per_year" },
        { text: "Actions", value: "actions" },
      ],

      allSubscriptionPlans: [],
      allSubscription: [],

      currentSubscription: [],
      currentSubscriptionPlanName: "",
      currentSubscriptionPlanBranchLimit: 0,
      currentSubscriptionPlanPastorLimit: 0,
      currentSubscriptionPlanMemberLimit: 0,
      currentSubscriptionPlanTurnoverLimit: 0,
      currentSubscriptionAmount: 0,
      currentSubscriptionDuration: 0,
      currentSubscriptionType: "",
      currentSubscriptionDateOfCreation: "",
      currentSubscriptionExpiryDate: "",
      overlay: true,

      //
      matchingPlanItemName: "",
      matchingPlanItemPricePerMonth: "",
      matchingPlanItemPricePerYear: "",
      matchingPlanItemBranchLimit: "",
      matchingPlanItemPastorLimit: "",
      matchingPlanItemMemberLimit: "",
      matchingPlanItemTurnoverLimit: "",
    }
  },

  computed: {
    isCreateSubscriptionFormInvalid() {
      return (
        !this.create_subscription.subscription_plan_id ||
        !this.create_subscription.type ||
        !this.create_subscription.qty
      )
    },

    isUpgradeSubscriptionFormInvalid() {
      return (
        !this.upgrade_subscription_form.subscription_plan_id ||
        !this.upgrade_subscription_form.type ||
        !this.upgrade_subscription_form.qty
      )
    },
  },

  methods: {
    showPaymentDetails() {
      this.paymentDetailSection = true
    },

    createSubscription() {
      this.loading = true
      this.$http
        .post(
          `admin/churches/${this.churchSlug}/subscriptions`,
          this.create_subscription
        )
        .then((response) => {
          // this.createdSuccessful = true
          // this.cancelSubscription = false
          this.isLoading = false
          this.amount = response.data.data.amount
          this.subscriptionPlan = response.data.data.plan.name
          this.quantity = response.data.data.qty
          this.subscriptionType = response.data.data.type
          this.reference = response.data.data.tx_ref
          this.id = response.data.data.id
          this.dateOfCreation = response.data.data.created_at

          this.create_subscription.response = response.data.message

          let vm = this
          var handler = PaystackPop.setup({
            key: vm.public_api_key,
            email: vm.email,
            amount: vm.amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
            currency: "NGN",
            ref: vm.reference,
            callback: function (payStackResponse) {
              console.log(payStackResponse)
              vm.verifyPayment()
              vm.viewAllSubscription()
              vm.createSubscriptionDialog = false
              vm.$refs.form.reset()
            },
            onClose: function () {
              vm.close()
              vm.$refs.form.reset()
            },
          })
          handler.openIframe()
        })
        .catch((error) => {
          catchAxiosExceptions(error)
          this.isLoading = false
          this.upgradeErrors = error.response.data.errors
        })
    },

    upgradeSubscription() {
      this.$http
        .post(`admin/upgradeSubscription`, this.upgrade_subscription_form)
        .then((response) => {
          this.upgradeAmount = response.data.data.amount
          this.upgradeDateOfCreation = this.upgradeSubscriptionPlanId =
            response.data.data.subscription_plan_id
          this.upgradeQuantity = response.data.data.qty
          this.upgradeSubscriptionType = response.data.data.type
          this.id = response.data.data.id
          this.upgradeReference = response.data.data.tx_ref

          if (this.upgradeAmount > 1) {
            let vm = this
            var handler = PaystackPop.setup({
              key: vm.public_api_key,
              email: vm.email,
              amount: vm.upgradeAmount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
              currency: "NGN",
              ref: vm.upgradeReference,
              callback: function (payStackResponse) {
                console.log(payStackResponse)
                vm.verifyPayment()
                vm.dialogForUgradeSubscription = false
                // vm.viewCurrentSubscription()
                vm.viewAllSubscription()
                vm.$refs.upgradeForm.reset()
              },
              onClose: function () {
                vm.close()
                vm.viewCurrentSubscription()
                vm.viewAllSubscription()
                vm.$refs.upgradeForm.reset()
              },
            })
            handler.openIframe()
          }
        })
        .catch((error) => {
          catchAxiosExceptions(error)
          this.upgradeErrors = error.response.data.errors
        })
    },

    viewCurrentSubscription() {
      this.$http
        .get(`admin/currentSubscription`)
        .then((response) => {
          this.currentSubscription = [response.data]

          if (
            this.currentSubscription == "" &&
            this.allSubscription.length == 0
          ) {
            this.showWelcomeSection = true
            this.showCurrentSubscription = false
          } else {
            this.showWelcomeSection = false
            this.showCurrentSubscription = true
          }
          // console.log(this.currentSubscription)

          if (
            this.currentSubscription == "" &&
            this.allSubscription.length > 0 &&
            this.currentSubscription.is_confirmed == true
          ) {
            this.showWelcomeSection = false
            this.showCurrentSubscription = true
          }
          if (this.currentSubscription.is_confirmed == false) {
            this.unConfirmedPaymentDialog == true
          }

          if (this.currentSubscription.is_expired == true) {
            this.currentSubscriptionExpired == true
          }
          // console.log(this.allSubscription);

          // if(this.allSubscription == null){
          //   this.showWelcomeSection = false;
          //   this.showCurrentSubscription = true;
          // }

          this.currentSubscriptionPlanName =
            this.currentSubscription[0].plan.name
          this.currentSubscriptionPlanBranchLimit =
            this.currentSubscription[0].plan.branch_limit
          this.currentSubscriptionPlanPastorLimit =
            this.currentSubscription[0].plan.pastor_limit
          this.currentSubscriptionPlanMemberLimit =
            this.currentSubscription[0].plan.member_limit
          this.currentSubscriptionAmount = this.currentSubscription[0].amount
          this.currentSubscriptionDuration = this.currentSubscription[0].qty
          this.currentSubscriptionType = this.currentSubscription[0].type
          this.currentSubscriptionDateOfCreation =
            this.currentSubscription[0].created_at
          this.currentSubscriptionExpiryDate =
            this.currentSubscription[0].expiry_date
          this.overlay = false
        })
        .catch((error) => {
          catchAxiosExceptions(error)
          this.overlay = false
        })
    },
    closeConfirmationPaymentDialog() {
      this.unConfirmedPaymentDialog = false
    },

    viewAllSubscription() {
      this.$http
        .get(`admin/churches/${this.churchSlug}/subscriptions`)
        .then((response) => {
          this.allSubscription = response.data.data
          this.overlay = false
        })
        .catch((error) => {
          this.overlay = false
          catchAxiosExceptions(error)
        })
    },
    verifyPayment() {
      this.$http
        .get(`admin/paymentConfirmation/${this.id}`)
        .then((response) => {
          console.log(response)
          this.viewCurrentSubscription()
          this.showWelcomeSection = false
          this.showCurrentSubscription = true
        })
        .catch((error) => {
          catchAxiosExceptions(error)
        })
    },

    close() {
      this.$http
        .delete(`admin/onClose/${this.id}`)
        .then((response) => {
          this.cancelSubscription = response.data.message
          this.create_subscription.subscription_plan_name = ""
          this.create_subscription.subscription_plan_branch_limit = 0
          this.create_subscription.subscription_plan_pastor_limit = 0
          this.create_subscription.subscription_plan_member_limit = 0
          this.create_subscription.subscription_plan_price_per_month = 0
          this.create_subscription.subscription_plan_price_per_year = 0
          this.create_subscription.subscription_plan_total = 0
        })
        .catch((error) => {
          catchAxiosExceptions(error)
        })
    },

    getSubscriptionPlan() {
      this.$http
        .get(`admin/subscriptionPlans`)
        .then((response) => {
          this.plans = response.data.data
        })
        .catch((error) => {
          catchAxiosExceptions(error)
        })
    },

    viewCreateSubscriptionPlan() {
      let selectedId = this.create_subscription.subscription_plan_id

      let matchingItems = this.plans.filter((plan) => plan.id == selectedId)

      this.create_subscription.subscription_plan_name = matchingItems
        .map((item) => item.name)
        .toString()
      this.create_subscription.subscription_plan_branch_limit = matchingItems
        .map((item) => item.branch_limit)
        .toString()

      this.create_subscription.subscription_plan_member_limit = matchingItems
        .map((item) => item.member_limit)
        .toString()

      this.create_subscription.subscription_plan_pastor_limit = matchingItems
        .map((item) => item.pastor_limit)
        .toString()

      this.create_subscription.subscription_plan_price_per_month = matchingItems
        .map((item) => item.price_per_month)
        .toString()
      this.create_subscription.subscription_plan_price_per_year = matchingItems
        .map((item) => item.price_per_year)
        .toString()

      if (this.create_subscription.type === "monthly") {
        this.create_subscription.subscription_plan_total =
          this.create_subscription.subscription_plan_price_per_month *
          this.create_subscription.qty
      }

      if (this.create_subscription.type === "yearly") {
        this.create_subscription.subscription_plan_total =
          this.create_subscription.subscription_plan_price_per_year *
          this.create_subscription.qty
      }
    },

    viewUpgradeSusbcriptionPlan() {
      let selectedId = this.upgrade_subscription_form.subscription_plan_id

      let matchingItems = this.plans.filter((plan) => plan.id == selectedId)

      this.upgrade_subscription_form.subscription_plan_name = matchingItems
        .map((item) => item.name)
        .toString()
      this.upgrade_subscription_form.subscription_plan_branch_limit =
        matchingItems.map((item) => item.branch_limit).toString()

      this.upgrade_subscription_form.subscription_plan_member_limit =
        matchingItems.map((item) => item.member_limit).toString()

      this.upgrade_subscription_form.subscription_plan_pastor_limit =
        matchingItems.map((item) => item.pastor_limit).toString()

      this.upgrade_subscription_form.subscription_plan_price_per_month =
        matchingItems.map((item) => item.price_per_month).toString()
      this.upgrade_subscription_form.subscription_plan_price_per_year =
        matchingItems.map((item) => item.price_per_year).toString()

      if (this.upgrade_subscription_form.type === "monthly") {
        this.upgrade_subscription_form.subscription_plan_total =
          this.upgrade_subscription_form.subscription_plan_price_per_month *
          this.upgrade_subscription_form.qty
      }

      if (this.upgrade_subscription_form.type === "yearly") {
        this.upgrade_subscription_form.subscription_plan_total =
          this.upgrade_subscription_form.subscription_plan_price_per_year *
          this.upgrade_subscription_form.qty
      }
    },

    viewAllSubscriptionPlans() {
      this.$http
        .get(`admin/subscriptionPlans`)
        .then((response) => {
          this.allSubscriptionPlans = response.data.data
        })
        .catch((error) => {
          catchAxiosExceptions(error)
        })
    },

    showFullPlanDetails(item) {
      let matchingPlanDetails = this.allSubscriptionPlans.filter(
        (obj) => obj.id === item.id
      )
      this.matchingPlanItemName = matchingPlanDetails[0].name
      this.matchingPlanItemPricePerMonth =
        matchingPlanDetails[0].price_per_month
      this.matchingPlanItemPricePerYear = matchingPlanDetails[0].price_per_year
      this.matchingPlanItemBranchLimit = matchingPlanDetails[0].branch_limit
      this.matchingPlanItemMemberLimit = matchingPlanDetails[0].member_limit
      this.matchingPlanItemPastorLimit = matchingPlanDetails[0].pastor_limit
      this.matchingPlanItemTurnoverLimit = matchingPlanDetails[0].turnover_limit
      this.subscriptionPlanDialog = true
    },

    closeCreateSubDialog() {
      this.createSubscriptionDialog = false
      this.$refs.form.reset()
      this.create_subscription.subscription_plan_name = ""
      this.create_subscription.subscription_plan_branch_limit = 0
      this.create_subscription.subscription_plan_pastor_limit = 0
      this.create_subscription.subscription_plan_member_limit = 0
      this.create_subscription.subscription_plan_price_per_month = 0
      this.create_subscription.subscription_plan_price_per_year = 0
      this.create_subscription.subscription_plan_total = 0
    },

    closeUpgradeForm() {
      this.dialogForUgradeSubscription = false
      this.$refs.upgradeForm.reset()

      this.upgrade_subscription_form.subscription_plan_name = ""
      this.upgrade_subscription_form.subscription_plan_branch_limit = 0
      this.upgrade_subscription_form.subscription_plan_pastor_limit = 0
      this.upgrade_subscription_form.subscription_plan_member_limit = 0
      this.upgrade_subscription_form.subscription_plan_price_per_month = 0
      this.upgrade_subscription_form.subscription_plan_price_per_year = 0
      this.upgrade_subscription_form.subscription_plan_total = 0
    },

    getFormattedDate(date) {
      return getFormattedDate(date)
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount)
    },
  },

  mounted() {
    this.viewAllSubscription()
    this.viewCurrentSubscription()
    this.getSubscriptionPlan()
    this.viewAllSubscriptionPlans()
  },
}
</script>

<style>
.border-top {
  border-top: 9px solid #c73539 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
