<template>
  <v-container class="fill-height d-background" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
         <a href="https://smartchurch.ng/" target="_blank">
          <v-img
            src="@/assets/smartchurch-logo.png"
            max-height="180"
            max-width="180"
            class="d-block mx-auto mb-6"
          ></v-img>
        </a>
      </v-col>
      <v-col cols="12" lg="7">
       <v-img src="@/assets/Thank You.png"></v-img>
      </v-col>
      <v-col cols="12" lg="5">
        <!-- <v-card class="p-5"> -->
          <h1 class="text-center fw-bold primary--text">
            Thank You For Signing Up!
          </h1>
          <p class="text-center fs-4 mt-5">
            Enjoy the seamless and stress-free administration of your church
            with our reporting and analytics software.
          </p>
          <router-link :to="{ name: 'Login', query: { signup: 'success' } }"
            ><v-btn class="primary elevation-2 mt-9" block
              >Proceed to Login</v-btn
            ></router-link
          >
        <!-- </v-card> -->
      </v-col>

     
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped>
h3 {
  text-align: left;
  padding: 20px;
  color: #c73436;
  font-weight: bold;
}

.v-btn {
  background: rgba(203, 68, 71, 1) !important;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  height: 40px !important;
}
label,
p {
  margin: 10px;
  text-transform: capitalize !important;
}
label span {
  color: rgba(203, 68, 71, 1);
  font-size: 1.3em;
}
a {
  text-decoration: none;
}
.d-background {
  background: #fef6f6;
}

.mt-7 {
  margin-top: 6rem;
}
</style>
