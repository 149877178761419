<template>
  <content-loader
    viewBox="0 0 900 800"
    :speed="2"
     primaryColor="#3333"
     secondaryColor="#fff"
  >
     <rect x="10" y="10" rx="2" ry="2" width="380" height="30" />
     <rect x="10" y="50" rx="2" ry="2" width="100" height="10" />
     <rect x="120" y="50" rx="2" ry="2" width="100" height="10" />
     <rect x="230" y="50" rx="2" ry="2" width="100" height="10" /> 
    <circle cx="350" cy="55" r="6" />
    <circle cx="370" cy="55" r="6" />

    <!-- <circle cx="914" cy="123" r="11" /> -->
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
export default {
  components: { ContentLoader },
}
</script>

<style>

</style>
