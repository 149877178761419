<template>
  <SmartChurchLayout>
    <v-container>
      <ul>
        <li v-for="log in activityLogs" :key="log.id">
          {{ log.action }} - {{ log.timestamp }} {{ log }}
        </li>
      </ul>
    </v-container>
  </SmartChurchLayout>
</template>

<script>
import SmartChurchLayout from "../components/Layouts/SmartChurchLayout.vue"

export default {
  components: { SmartChurchLayout },
  computed: {
    activityLogs() {
      return this.$store.state.activityLogs
    },
  },
}
</script>
