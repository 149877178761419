<template>
  <v-app>
    <v-snackbar v-model="snackbar"  :color="color" top>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
   <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: "App",

  data() {
    return{
      snackbar: false,
      message: '',
      timeout: 6000,
      color: '',
    }
  },

  created() {
    window.addEventListener('online', this.handleNetworkChange)
    window.addEventListener('offline', this.handleNetworkChange)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleNetworkChange)
    window.removeEventListener('offline', this.handleNetworkChange)
  },

  // mounted() {
  //   this.handleNetworkChange()
  // },

  methods: {
    handleNetworkChange() {
      if (navigator.onLine) {
        this.snackbar = true
        this.message = 'Network is available.'
        this.color = 'success'
      } else {
        this.snackbar = true
        this.message = 'Network is unavailable.'
        this.color = 'error'
      }
    },
  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

*{
    font-family: 'Fira Sans', sans-serif;
}
</style>
