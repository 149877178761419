<template>
  <smart-church-layout page="Change Password">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <v-container class="my-8">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5" lg="5">
          <v-card class="pa-6 rounded-lg">
            <v-form class="px-5" ref="form" @submit.prevent="changePassword">
              <h3>Change Password</h3>
              <div
                class="alert alert-danger mt-5 text-center"
                v-if="change_password_errors.length > 1"
              >
                {{ change_password_errors }}
              </div>
              <label><v-icon>mdi-lock</v-icon>old password</label>
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                v-model="change_password.old"
                :error-messages="change_password_errors.old"
                outlined
                dense
              ></v-text-field>
              <label><v-icon>mdi-lock</v-icon>new password</label>
              <v-text-field
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                v-model="change_password.password"
                :error-messages="change_password_errors.password"
                outlined
                dense
              ></v-text-field>
              <label><v-icon>mdi-lock</v-icon>confirm new password</label>
              <v-text-field
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                @click:append="show3 = !show3"
                v-model="change_password.password_confirmation"
                :error-messages="change_password_errors.password_confirmation"
                outlined
                dense
              ></v-text-field>
              <div
                class="alert alert-success mt-5 text-center"
                v-if="change_password.response"
              >
                {{ change_password.response }}
              </div>
              <div
                class="alert alert-danger mt-5 text-center"
                v-if="change_password.error"
              >
                {{ change_password.error.data.message }}
              </div>
              <v-btn
                type="submit"
                block
                class="white--text"
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >change password</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"

export default {
  components: { SmartChurchLayout },
  data() {
    return {
      change_password: {
        old: "",
        password: "",
        password_confirmation: "",
        response: "",
        error: "",
      },

      overlay: false,

      show1: false,
      show2: false,
      show3: false,

      isSubmitted: false,
      change_password_errors: {},
    }
  },
  methods: {
    changePassword() {
      this.isSubmitted = true
      if (
        this.change_password.password !==
        this.change_password.password_confirmation
      ) {
        this.change_password_errors = "Password does not match"
      } else {
        this.$http
          .post("admin/change-password", this.change_password)
          .then((response) => {
            this.change_password.response = response.data.message
            this.isSubmitted = false
          })
          .catch((error) => {
            this.isSubmitted = false
            if (error.response) {
              this.change_password.error = error.response
            }

            catchAxiosExceptions(error)
          })
      }
    },
  },
}
</script>

<style scoped>
h3 {
  text-align: left;
  padding: 20px;
  color: #c73436;
  font-weight: bold;
}

.v-btn {
  background: rgba(203, 68, 71, 1) !important;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  height: 40px !important;
}
label,
p {
  margin: 10px;
  text-transform: capitalize !important;
}
label span {
  color: rgba(203, 68, 71, 1);
  font-size: 1.3em;
}
a {
  text-decoration: none;
}
.d-background {
  background: #fef6f6;
}
.login-btn {
  background-color: white !important;
}
</style>
