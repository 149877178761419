<template>
  <smart-church-layout page="Edit First Timer">
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout">{{ snackbar.msg
    }}</v-snackbar>
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <v-container fluid class="my-8">
      <v-btn class="bg-transparent ms-7 mt-5" small
        :to="{ name: 'AdminFirstTimers' }"><v-icon>mdi-arrow-u-left-top</v-icon></v-btn>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn color="primary white--text" :loading="isLoading" @click="initFirstTimerInfo">
              Fetch again
            </v-btn>
          </div>

          <v-card class="d-flex justify-center mt-12 rounded-lg text-capitalize">
            <v-form class="my-6 pa-13" ref="form" @submit.prevent="updateFirstTimers">
              <h2 class="primary--text d-flex justify-center my-4 font-weight-bold">
                Edit First Timer
              </h2>

              <v-row class="mt-6">
                <v-col cols="12" md="12">
                  <v-text-field outlined dense label="Date" type="date" id="Date"  v-model="firstTimersDetails.attendance_date"
                    :error-messages="firstTimersDetails.attendance_date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field outlined dense label="First Name" id="fname" v-model="firstTimersDetails.first_name"
                    :error-messages="firstTimersDetails_errors.first_name"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field outlined dense label="Last Name" id="lname" v-model="firstTimersDetails.last_name"
                    :error-messages="firstTimersDetails_errors.last_name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete v-model="firstTimersDetails.branch_id" :items="branches" dense label="Branch"
                    id="branch_id" item-value="id" outlined item-text="name"
                    :error-messages="firstTimersDetails_errors.branch_id">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field outlined dense label="Email" id="email" v-model="firstTimersDetails.email"
                    :error-messages="firstTimersDetails_errors.email"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select outlined dense label="Gender" id="sex" :items="items" v-model="firstTimersDetails.sex"
                    :error-messages="firstTimersDetails_errors.sex"></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field type="number" outlined dense label="Phone Number" id="phone"
                    v-model="firstTimersDetails.phone" :error-messages="firstTimersDetails_errors.phone"></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field type="date" outlined dense label="Date Of Birth"
                    v-model="firstTimersDetails.date_of_birth"
                    :error-messages="firstTimersDetails_errors.date_of_birth"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field outlined dense label="Occupation" id="Occupation" v-model="firstTimersDetails.occupation"
                    :error-messages="firstTimersDetails_errors.occupation"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select :items="marital_status" outlined dense label="Marital Status" id="marital status"
                    v-model="firstTimersDetails.marital_status"
                    :error-messages="firstTimersDetails_errors.marital_status"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select :items="employment" id="Employment Status" label="Employment status"
                    v-model="firstTimersDetails.employment_status" :error-messages="firstTimersDetails_errors.employment_status
                      " outlined dense></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea outlined label="Residential Address" v-model="firstTimersDetails.address"
                    :error-messages="firstTimersDetails_errors.address"></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete  v-model="firstTimersDetails.first_timer_class_id" :items="migrateMember" dense
                    label="Choose Transition Class" id="Translate_id" item-value="id" outlined item-text="name"
                    :error-messages="firstTimersDetails_errors.first_timer_class_id">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <p class="warning--text text-capitalize">Please, click on the migrate button if a first timer has completed all transition class</p>
                  <template>
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary"  v-bind="attrs" v-on="on">
                        Migrate to member
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to migrate this first timer to be a
                        member?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="closeDelete">No</v-btn>
                        <v-btn color="primary" text @click="migrate">Yes</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                </v-col>

                <v-col col="12" md="12">
                  <v-switch v-model="firstTimersDetails.under_forty" label="Are you Under the age of 40"></v-switch>
                </v-col>

              </v-row>
              <v-btn type="submit" depressed block :loading="isSubmitted" :disabled="isSubmitted"
                color="primary white--text">Edit</v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      firstTimersDetails: {},
      dialog: false,
      items: ["male", "female"],
      status: ["Active", "Inactive"],
      employment: ["employed", "unemployed"],
      marital_status: ["single", "married"],
      overlay: true,

      firstTimersDetails_errors: {},
      branches: [],
      migrateMember: [],
      routeParamSlug: this.$route.params.firstTimer_slug,

      isLoading: false,

      isSubmitted: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      mountedError: null,
    }
  },

  methods: {
    async initFirstTimerInfo() {
      if (this.routeParamSlug === undefined || this.routeParamSlug.length < 1) {
        this.$router.push({ name: "AdminFirstTimers" })
      } else {
        this.isLoading = true

        try {
          const firstTimer_details = await this.$http.get(
            "admin/firstTimers/" + this.routeParamSlug
          )
          const user = this.$store.state.userDetail.user;

          this.firstTimersDetails = firstTimer_details.data.data
          this.branches = user.branches;
          const migrateMember = await this.$http.get('admin/first-timer-class')
          this.migrateMember = migrateMember.data.data

          this.isLoading = false
          this.overlay = false
        } catch (error) {
          this.mountedError = error.response.data.message
          this.isLoading = false
          this.overlay = false

          catchAxiosExceptions(error)
        }
      }
    },

    updateFirstTimers() {
      this.isSubmitted = true

      this.$http
        .put(
          "admin/firstTimers/" + this.routeParamSlug,
          this.firstTimersDetails
        )
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.firstTimersDetails_errors = error.response.data.errors
          this.isSubmitted = false

          catchAxiosExceptions(error)
        })
    },
    migrate() {
      this.$http.post("admin/firstTimerMigration/" + this.routeParamSlug,
        this.firstTimersDetails)
        .then((response) => {
          this.migrateMember = response.data.data
          console.log(this.migrateMember)
        })
    },
    closeDelete(){
      this.dialog = false
    }
  },

  mounted() {
    this.initFirstTimerInfo()
  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}

.v-icon {
  color: #c73438;
}

.v-text-field--outlined>>>fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.itk {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.v-text-field::-moz-focus-inner {
  background-color: red !important;
}

.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot:focus {
  background: #eedddd !important;
}

.img-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
