<template>
  <smart-church-layout page="Range Report">
    <v-container class="my-8">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="mt-12 rounded-lg text-capitalize p-5">
            <v-row align="center" justify="center">
              <h3
                class="primary--text d-flex justify-center my-4 font-weight-bold text-center"
              >
                SmartChurch Range Report
              </h3>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedData.branch"
                  :items="branches"
                  required
                  dense
                  label="Branch"
                  item-value="id"
                  outlined
                  item-text="name"
                  return-object
                  @input="clearData"
                  :error-messages="monthlyRange_errors.branch"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedData.from"
                  required
                  item-text="month"
                  item-value="value"
                  label="Start Month"
                  outlined
                  return-object
                  dense
                  type="date"
                  @input="clearData"
                  :error-messages="monthlyRange_errors.from"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedData.to"
                  required
                  item-text="month"
                  item-value="value"
                  label="End Month"
                  outlined
                  return-object
                  dense
                  type="date"
                  @input="clearData"
                  :error-messages="monthlyRange_errors.to"
                ></v-text-field>
              </v-col>

              <v-btn
                color="primary"
                @click="getRangeReports"
                :loading="loading"
                :disabled="loading"
                >Generate report</v-btn
              >
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="showRangeReport">
          <div id="pdf-content">
            <div>
              <p
                class="primary fs-3 mb-0 text-white p-2 text-center fw-bold text-uppercase"
              >
                Reports From {{ getFormattedDate(selectedData.from) }} -
                {{ getFormattedDate(selectedData.to) }}
              </p>
              <v-card class="text-capitalize p-3 mb-4">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="fs-5">Number of Meetings</td>
                        <td class="fs-5">
                          {{ serviceData.service_reports_count }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-5">Highest Attendance</td>
                        <td class="fs-5">
                          {{
                            serviceData.highest_attendance
                              ? getFormattedAmount(
                                  serviceData.highest_attendance
                                )
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-5">Lowest Attendance</td>
                        <td class="fs-5">
                          {{
                            serviceData.lowest_attendance
                              ? getFormattedAmount(
                                  serviceData.lowest_attendance
                                )
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-5">Average Income</td>
                        <td class="fs-5">
                          ₦{{
                            serviceData.avg_income
                              ? getFormattedAmount(
                                  Math.ceil(serviceData.avg_income)
                                )
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-5">Total First Timers</td>
                        <td class="fs-5">
                          {{
                            serviceData.first_timers
                              ? getFormattedAmount(serviceData.first_timers)
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-5">Total New Converts</td>
                        <td class="fs-5">
                          {{
                            serviceData.new_convert
                              ? getFormattedAmount(serviceData.new_convert)
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fs-5">Average Attendance</td>
                        <td class="fs-5">
                          {{
                            serviceData.avg_attendance
                              ? getFormattedAmount(
                                  Math.round(Number(serviceData.avg_attendance))
                                )
                              : "0"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>

            <v-row>
              <v-col cols="12">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Income and Expense Chart
                </h3>
                <v-card class="p-3">
                  <bar-chart
                    :chart-data="{
                      labels: [churchName],
                      datasets: [
                        {
                          label: 'Income',
                          backgroundColor: '#3366CC',
                          data: [Number(incomeExpenseChartIncomeValue)],
                        },
                        {
                          label: 'Expenditure',
                          backgroundColor: '#880808',
                          data: [Number(incomeExpenseChartExpenseValue)],
                        },
                      ],
                    }"
                    :chartOptions="{
                      responsive: true,
                      maintainAspectRatio: false,
                    }"
                  />
                  <p class="text-center fw-bold fs-5 p-2">
                    You spent {{ percentageTotal ? percentageTotal : "0" }}% of
                    income on Expenditure
                  </p>
                </v-card>
              </v-col>

              <v-col cols="12" lg="6">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Income Chart
                </h3>
                <v-card class="p-2">
                  <div v-if="checkIfIncomeExpenseIsPresent">
                    <pie-chart
                      :chart-data="pieChartIncomeData"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                          padding: {
                            left: 40,
                            right: 40,
                            top: 40,
                            bottom: 40,
                          },
                        },
                      }"
                    />
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" lg="6">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Expense Chart
                </h3>
                <v-card class="p-2">
                  <div v-if="checkIfIncomeExpenseIsPresent">
                    <pie-chart
                      :chart-data="pieChartExpenseData"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                          padding: {
                            left: 40,
                            right: 40,
                            top: 40,
                            bottom: 40,
                          },
                        },
                      }"
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Sunday Attendance Chart
                </h3>
                <v-card class="p-1">
                  <div v-if="checkIfAttendanceIsPresent">
                    <line-chart
                      :chart-data="sundayLineChartData"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          y: {
                            beginAtZero: true,
                          },
                        },
                      }"
                    />
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" lg="6">
                <h3
                  class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                >
                  Weekdays Attendance Chart
                </h3>
                <v-card class="p-1">
                  <div v-if="checkIfAttendanceIsPresent">
                    <line-chart
                      :chart-data="weekdaysLineChartData"
                      :chartOptions="{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          y: {
                            beginAtZero: true,
                          },
                        },
                      }"
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p
                  class="primary fs-3 mb-0 text-white p-2 text-center fw-bold text-uppercase"
                >
                  Income Report
                </p>

                <v-card class="text-capitalize p-3 mb-4">
                  <v-simple-table v-if="checkIfIncomeExpenseIsPresent">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="incomeValue in incomeValues"
                          :key="incomeValue[0]"
                        >
                          <td class="incomeKey fs-5">{{ incomeValue[0] }}</td>
                          <td class="text-right incomeValue fs-5">
                            ₦{{
                              incomeValue
                                ? getFormattedAmount(incomeValue[1])
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fw-bold text-uppercase fs-5">
                            Total Income
                          </td>
                          <td class="text-right fw-bold fs-5">
                            ₦{{
                              getFormattedAmount(incomeExpenseChartIncomeValue)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col cols="12">
                <p
                  class="primary fs-3 mb-0 text-white p-2 text-center fw-bold text-uppercase"
                >
                  Expense Report
                </p>
                <v-card class="text-capitalize p-3 mb-4">
                  <v-simple-table v-if="checkIfIncomeExpenseIsPresent">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="expenseValue in expenseValues"
                          :key="expenseValue[0]"
                        >
                          <td class="incomeKey fs-5">{{ expenseValue[0] }}</td>
                          <td class="text-right incomeValue fs-5">
                            ₦{{
                              expenseValue
                                ? getFormattedAmount(expenseValue[1])
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fw-bold text-uppercase fs-5">
                            Total Expense
                          </td>
                          <td class="text-right fw-bold fs-5">
                            ₦{{
                              getFormattedAmount(incomeExpenseChartExpenseValue)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>

            <p
              class="primary fs-3 mb-0 text-white p-2 text-center fw-bold text-uppercase"
            >
              Service Reports
            </p>
            <template>
              <v-data-table
                :headers="headers"
                :items="serviceAttendanceStatistics"
                class="elevation-1 py-2"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.service_date`]="{ item }">
                  {{ getFormattedDate(item.service_date) }}
                </template>
                <template v-slot:[`item.total_attendance`]="{ item }">
                  {{ getTotalAttendance(item) }}
                </template>
                <template v-slot:[`item.total_income`]="{ item }">
                  ₦{{ getFormattedAmount(item.total_income) }}
                </template>
              </v-data-table>
            </template>
          </div>
          <div class="text-end justify-end d-none d-md-none d-lg-flex">
            <v-btn @click="downloadPDF" color="primary" class="fw-bold"
              >Download Report as PDF</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getReportYears,
  getFormattedAmount,
  getFormattedDate,
  getTotalAttendance,
} from "../../__helpers/handelExceptions";
import BarChart from "../../components/charts/BarChart.vue";
import PieChart from "../../components/charts/PieChart.vue";
import LineChart from "../../components/charts/LineChart.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  components: { SmartChurchLayout, BarChart, PieChart, LineChart },
  data() {
    return {
      selectedData: {
        branch: null,
        from: null,
        to: null,
      },

      headers: [
        {
          text: "Service Date",
          align: "start",
          sortable: false,
          value: "service_date",
        },
        // { text: "Service Day", value: "service_date" },
        { text: "Service Type", value: "service_type.name" },

        { text: "Service Topic", value: "topic" },
        { text: "Service Speaker", value: "speaker" },

        { text: "Male Attendance", value: "male_attendance" },
        { text: "Female Attendance", value: "female_attendance" },

        { text: "Children Attendance", value: "children_attendance" },
        { text: "Teenage Attendance", value: "teenager_attendance" },
        { text: "New Convert", value: "new_convert" },
        { text: "First Timer", value: "first_timers" },
        { text: "Total Attendance", value: "total_attendance" },
        { text: "Total Service Income", value: "total_income" },
      ],
      monthlyRange_errors: {},

      years: [],

      churchName: "",

      branches: [],
      isLoading: false,
      loading: false,
      percentageTotal: "",

      serviceData: [],

      serviceAttendanceStatistics: [],

      pieChartIncomeData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      pieChartExpenseData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      sundayLineChartData: {
        labels: [],
        datasets: [
          {
            label: "Sunday",
            backgroundColor: "blue",
            data: [],
            borderColor: "#244AFB",
          },
        ],
      },

      weekdaysLineChartData: {
        labels: [],
        datasets: [
          {
            label: "Week Days",
            backgroundColor: "#EA5F82",
            data: [],
            borderColor: "#EA5F82",
          },
        ],
      },

      incomeData: [],
      incomeChart: [],
      incomeChartData: [],
      incomeValues: [],
      incomeExpenseChartIncomeValue: [],
      incomeExpenseChartExpenseValue: [],

      expenseData: [],
      expenseChart: [],
      expenseChartData: [],
      expenseValues: [],

      attendanceData: [],
      attendanceChartData: [],

      showRangeReport: false,
      // mountedError: null,
    };
  },

  computed: {
    checkIfIncomeExpenseIsPresent() {
      return this.incomeData.length > 0 || this.expenseData.length > 0;
    },
    checkIfAttendanceIsPresent() {
      return this.attendanceData.length > 0;
    },
  },

  methods: {
    async initYear() {
      try {
        const user = this.$store.state.userDetail.user;

        this.branches = user.branches;
      } catch (error) {
        catchAxiosExceptions(error);
      }
    },
    getReports() {
      this.loading = true;
      this.$http
        .get(
          `admin/churchReportRange?branch_id=${this.selectedData.branch.id}&from=${this.selectedData.from}&to=${this.selectedData.to}`
        )
        .then((response) => {
          this.loading = false;
          this.showRangeReport = true;

          this.churchName = response.data.data.data.name;
          this.serviceAttendanceStatistics = response.data.data.services;

          this.serviceData = response.data.data.data;
          this.incomeExpenseChartIncomeValue =
            response.data.data.data.total_income;
          this.incomeExpenseChartExpenseValue =
            response.data.data.data.total_expense;

          let percentageTotal =
            (this.incomeExpenseChartExpenseValue /
              this.incomeExpenseChartIncomeValue) *
            100;

          this.percentageTotal = Math.ceil(percentageTotal);

          // Income
          this.incomeData = response.data.data.incomes;

          let incomeArray = [];

          this.incomeData.forEach((income) => {
            if (
              income.income_title.name !== null ||
              income.amount !== null ||
              income.income_title.name != "" ||
              income.amount != ""
            ) {
              incomeArray.push([
                income.income_title.name,
                Number(income.amount),
              ]);
            }
            return incomeArray;
          });

          const reducedIncomeData = incomeArray.reduce(
            (acc, [income, value]) => {
              if (acc[income]) {
                acc[income] += value;
              } else {
                acc[income] = value;
              }

              return acc;
            },
            []
          );

          this.incomeChartData = reducedIncomeData;

          this.pieChartIncomeData.labels = Object.keys(this.incomeChartData);

          this.pieChartIncomeData.datasets[0].data = Object.values(
            this.incomeChartData
          );

          this.pieChartIncomeData.datasets[0].backgroundColor =
            this.generateBackgroundColors(
              this.pieChartIncomeData.labels.length
            );
          this.incomeValues = Object.entries(this.incomeChartData);

          // Expense

          this.expenseData = response.data.data.expenses;

          let expenseArray = [];
          this.expenseData.forEach((expense) => {
            if (
              expense.expense_title.name !== null ||
              expense.amount !== null ||
              expense.expense_title.name != "" ||
              expense.amount != ""
            ) {
              expenseArray.push([
                expense.expense_title.name,
                Number(expense.amount),
              ]);
            }

            return expenseArray;
          });

          const reducedExpenseData = expenseArray.reduce(
            (acc, [expense, value]) => {
              if (acc[expense]) {
                acc[expense] += value;
              } else {
                acc[expense] = value;
              }

              return acc;
            },
            []
          );

          this.expenseChartData = reducedExpenseData;
          this.pieChartExpenseData.labels = Object.keys(this.expenseChartData);

          this.pieChartExpenseData.datasets[0].data = Object.values(
            this.expenseChartData
          );

          this.pieChartExpenseData.datasets[0].backgroundColor =
            this.generateBackgroundColors(
              this.pieChartExpenseData.labels.length
            );
          this.expenseValues = Object.entries(this.expenseChartData);

          // Sunday and Weekdays Line chart

          this.attendanceData = response.data.data.services;

          let sundayAttendanceArray = [];
          let otherWeekDaysArray = [];
          let attendanceLabelsArray = [];
          this.attendanceData.forEach((attendance) => {
            attendanceLabelsArray.push(attendance.service_day);

            if (attendance.service_day && attendance.service_day == "Sunday") {
              sundayAttendanceArray.push([
                getFormattedDate(attendance.service_date),
                attendance.total_attendance,
              ]);
            }

            if (attendance.service_day && attendance.service_day != "Sunday") {
              otherWeekDaysArray.push([
                getFormattedDate(attendance.service_date),
                attendance.total_attendance,
              ]);
            }
          });

          sundayAttendanceArray.sort((a, b) => {
            let dateA = new Date(a[0]);
            let dateB = new Date(b[0]);
            return dateA - dateB;
          });

          const sundayReducedData = sundayAttendanceArray.reduce(
            (acc, [attendance, value]) => {
              if (acc[attendance]) {
                acc[attendance] += value;
              } else {
                acc[attendance] = value;
              }

              return acc;
            },
            []
          );

          this.sundayLineChartData.labels = Object.keys(sundayReducedData);
          this.sundayLineChartData.datasets[0].data =
            Object.values(sundayReducedData);

          otherWeekDaysArray.sort((a, b) => {
            let dateA = new Date(a[0]);
            let dateB = new Date(b[0]);
            return dateA - dateB;
          });

          const weekdaysReducedData = otherWeekDaysArray.reduce(
            (acc, [attendance, value]) => {
              if (acc[attendance]) {
                acc[attendance] += value;
              } else {
                acc[attendance] = value;
              }

              return acc;
            },
            []
          );

          this.weekdaysLineChartData.labels = Object.keys(weekdaysReducedData);
          this.weekdaysLineChartData.datasets[0].data =
            Object.values(weekdaysReducedData);

          this.years = getReportYears(this.serviceReports);
        })
        .catch((error) => {
          this.loading = false;
          // this.monthlyRange_errors = error.response.data.errors
          catchAxiosExceptions(error);
        });
    },
    downloadPDF() {
      const pdf = new jsPDF("p", "mm", "a4");

      html2canvas(document.getElementById("pdf-content")).then((canvas) => {
        const imgData = canvas.toDataURL("image/JPEG");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("document.pdf");
      });
    },

    generateBackgroundColors(count) {
      const backgroundColors = [];
      // Generate a random color for each label
      for (let i = 0; i < count; i++) {
        const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        backgroundColors.push(color);
      }
      return backgroundColors;
    },

    clearData() {
      this.serviceData = [];
      this.incomeData = [];
      this.expenseData = [];
      this.attendanceData = [];
      this.serviceAttendanceStatistics = [];
      this.incomeExpenseChartIncomeValue = 0;
      this.incomeExpenseChartExpenseValue = 0;
      this.percentageTotal = 0;
      this.sundayLineChartData.datasets[0].data = [];
      this.weekdaysLineChartData.datasets[0].data = [];
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },
    getTotalAttendance(report) {
      return getTotalAttendance(report);
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    getRangeReports() {
      this.getReports();
    },
  },

  mounted() {
    this.initYear();
  },
};
</script>

<style scoped>
/* * {
  outline: 1px solid blue;
} */
#report {
  border-collapse: collapse;
  width: 100%;
}

#report td,
#report th {
  padding: 8px;
  font-size: 1.15rem;
}

#report tr:nth-child(even) {
  /* background-color: #f2f2f2; */
  border: 1px solid #ddd;
}
#report th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
.monthlyReport th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #c73539;
  color: white;
}
</style>
