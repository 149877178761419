<template>
  <smart-church-layout page="Service">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Service"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex mb-5 mt-10">
        <h3 class="primary--text font-weight-bold">Service</h3>
        <v-dialog v-model="dialog" max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-5 font-weight-bold"
              v-bind="attrs"
              v-on="on"
              color="primary text--white"
              v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles"
              ><v-icon>mdi-plus</v-icon> create service</v-btn
            >
          </template>
          <v-card class="rounded-lg text-capitalize">
            <v-form class="pa-10" ref="form" @submit.prevent="createService">
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                create service
              </h2>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="Service Name"
                    id="service"
                    v-model="create_service.name"
                    :error-messages="create_service_errors.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                :loading="isSubmitted"
                :disabled="isSubmitted"
                block
                >Create</v-btn
              >
              <div
                class="alert alert-success mt-3"
                v-if="create_service.response"
              >
                {{ create_service.response }}
              </div>
              <div class="alert alert-success mt-3" v-if="create_service.error">
                {{ create_service.error }}
              </div>
              <p class="text-center my-3">
                <v-btn depressed small @click="cancel">Close</v-btn>
              </p>
            </v-form>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search  Service "
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

      <v-data-table :headers="headers" :items="services" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="500px">
              <v-card>
                <v-card-title class="text-center">
                  <span class="text-h5 primary--text">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row class="mt-4">
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          label="Service Name"
                          v-model="create_service.name"
                          :error-messages="create_service_errors.name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close"> Cancel </v-btn>
                  <v-btn color="primary" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this service?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item  v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <a @click="editItem(item)"
                    ><v-icon small class="mr-2"> mdi-pencil </v-icon>Edit</a
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item  v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <a @click="deleteItem(item)">
                    <v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
import skeletonLoader from "../../components/DataTableLoader.vue";
import { mapGetters } from "vuex";

export default {
  components: { SmartChurchLayout, skeletonLoader },
  data: () => ({
    search: "",
    dialog: false,

    dialog2: false,
    dialogDelete: false,
    overlay: true,

    headers: [
      { text: "Name", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    services: [],

    create_service: {
      name: "",
      error: "",
      response: "",
    },

    snackbar: {
      status: false,
      msg: "",
      color: "",
      timeout: 3000,
    },

    isSubmitted: false,

    defaultItem: {
      name: "",
    },

    response: "",

    create_service_errors: {},
    display_service_errors: {},

    editedIndex: -1,

    isLoading: false,

    userPermissionNames: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Service" : "Edit Service";
    },
    ...mapGetters(['noRoles','canManageUsers','canViewUsers','canManageRoles','canViewRoles']),
  },

  watch: {
    dialog2(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getServices() {
      this.isLoading = true;

      this.$http
        .get("admin/service-type")
        .then((response) => {
          this.services = response.data.data;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

    createService() {
      this.create_service_errors = "";

      this.isSubmitted = true;

      this.$http
        .post("admin/service-type", this.create_service)
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getServices();
          this.$refs.form.reset();

          this.cancel();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.isSubmitted = false;
          this.create_service_errors = error.response.data.errors;

          catchAxiosExceptions(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.create_service = Object.assign({}, item);
      this.dialog2 = true;
    },

    save() {
      this.create_service_errors = "";

      this.$http
        .put(
          "admin/service-type/" + this.create_service.slug,
          this.create_service
        )
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getServices();
          this.close();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.isSubmitted = false;
          this.create_service_errors = error.response.data.errors;

          catchAxiosExceptions(error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.create_service = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/service-type/" + this.create_service.slug)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getServices();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          catchAxiosExceptions(error);
        });
    },

    cancel() {
      this.dialog = false;
    },

    close() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.create_service = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.create_service = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  created() {
    this.$store.commit("allPermissions");
  },

  mounted() {
    this.getServices();
  },
};
</script>

<style>
h3 {
  font-size: 24px !important;
}
/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
