<template>
  <smart-church-layout page="Users">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search User"
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <h3 class="primary--text font-weight-bold d-lg-none mt-6">User</h3>

      <div class="d-flex mb-5 mt-8">
        <h3 class="primary--text font-weight-bold d-none d-lg-block">User</h3>
        <v-btn
          class="mx-5 font-weight-bold"
          :to="{ name: 'AdminAddUsers' }"
          color="primary text--white"
          v-if="canManageUsers || noRoles"
          ><v-icon>mdi-plus</v-icon> Add a User
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search User"
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

     

      <v-data-table
        :headers="headers"
        :items="allUsers"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="600px">
              <v-card>
                <v-card-text>
                  <v-form class="pa-9" ref="form">
                    <h2
                      class="mb-4 primary--text d-flex justify-center font-weight-bold"
                    >
                      Update User
                    </h2>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="First Name"
                          type="text"
                          v-model="create_user.first_name"
                          :error-messages="create_user_errors.first_name"
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="Last Name"
                          type="text"
                          v-model="create_user.last_name"
                          :error-messages="create_user_errors.last_name"
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="Email"
                          type="email"
                          v-model="create_user.email"
                          :error-messages="create_user_errors.email"
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="Phone Number"
                          type="number"
                          v-model="create_user.phone"
                          :error-messages="create_user_errors.phone"
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          dense
                          label="State"
                          type="text"
                          v-model="create_user.state"
                          :error-messages="create_user_errors.state"
                          rounded
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn
                      color="primary white--text"
                      :loading="isSubmitted"
                      :disabled="isSubmitted"
                      block
                      >Update</v-btn
                    >
                    <p class="text-center my-3">
                      <v-btn depressed small @click="dialog2 = false"
                        >Close</v-btn
                      >
                    </p>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this User?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.roles`]="{ item }">
          <v-chip  color="textColor" outlined class="me-3 text-capitalize"
            v-for="eachRole in item.roles"
            :key="eachRole.id"
          >
            {{ eachRole.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.branches`]="{ item }">
          <v-chip  color="textColor" outlined class="me-1 p-2 m-1 text-capitalize"
            v-for="(eachBranch , index) in item.branches"
            :key="index"
          >
            {{ eachBranch.name }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-hover>

            <template v-slot:activator="{ on, attrs }">
             <v-btn
               depressed
               v-bind="attrs"
               v-on="on"
               class="bg-transparent"
               icon
             >
               <v-icon>mdi-phone-outline</v-icon>
             </v-btn>
           </template>
             <v-list v-if="canManageUsers || noRoles">
             <v-list-item>
               <v-list-item-title>
                 <a
                   :href="`tel:${item.phone}`"
                   class="phone text-decoration-none text-secondary"
                 >
                   <v-icon small class="mr-2"> mdi-phone-plus </v-icon>Call</a
                 >
               </v-list-item-title>
             </v-list-item>
             <v-list-item>
               <v-list-item-title>
                 <a
                   @click="openWhatsApp(item.phone)"
                   color="success"
                   class="text-decoration-none text-secondary"
                 >
                   <v-icon small class="mr-2"> mdi-whatsapp </v-icon
                   >Whatsapp</a
                 >
               </v-list-item-title>
             </v-list-item>
           </v-list>
          </v-menu>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="bg-transparent" depressed>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canManageUsers || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none textColor--text"
                    :to="{
                      name: 'AdminEditUser',
                      params: { user_id: item.id },
                    }"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                    Edit
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canManageUsers || canViewUsers || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none textColor--text"
                    :to="{
                      name: 'AdminViewUser',
                      params: { user_id: item.id },
                    }"
                  >
                    <v-icon small> mdi-eye </v-icon>
                    View
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canManageUsers || noRoles">
                <v-list-item-title>
                  <a @click="deleteItem(item)"
                    ><v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";

import {
  catchAxiosExceptions,
  getFormattedDate,
  getFormattedAmount,
} from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/DataTableLoader.vue"
import { mapGetters } from 'vuex';


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      search: "",
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      switch1: true,
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Roles", value: "roles" },
        { text: "Branch", value: "branches" },
        { text: "Phone Number", value: "phone" },

        { text: "Actions", value: "actions", sortable: false },
      ],

      branches: [],
      overlay: true,

      create_user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        password: "",
        gender: "",
        branches: [],
        roles: [],
      },

      defaultItem: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        password: "",
        gender: "",
        branches: [],
        roles: [],
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      isSubmitted: false,

      allUsers: [],
      editedIndex: -1,
      create_user_errors: {},

      isLoading: false,
    };
  },

  watch: {
    dialog2(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

   computed: {
    ...mapGetters(['canManageUsers','canViewUsers','noRoles','isAdmin']),
  },

  methods: {
    getAllUsers() {
      //   this.isLoading = true
      this.$http
        .get(`admin/users`)
        .then((response) => {
          const usersInArray = [response.data];

          this.allUsers = usersInArray[0].data;
          // console.log(this.allUsers);
          this.overlay = false;
        })
        .catch((error) => {
          catchAxiosExceptions(error);
          this.overlay = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.allUsers.indexOf(item);
      this.create_user = Object.assign({}, item);
      this.dialog2 = true;
    },

    cancel() {
      this.dialog = false;
    },

    deleteItem(item) {
      this.editedIndex = this.allUsers.indexOf(item);
      this.create_user = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/users/" + this.create_user.id)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.getAllUsers();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          catchAxiosExceptions(error);
        });
    },

    close() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.create_user = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.create_user = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },
     openWhatsApp(phone) {
      const whatsappUrl = `https://wa.me/${phone}`

      // Open the WhatsApp URL in a new tab or redirect the user to the WhatsApp application
      window.open(whatsappUrl)
    },
  },


  created(){
this.$store.commit('allPermissions');
    
  },

  mounted() {
    this.getAllUsers();
  },
};
</script>

<style>
h3 {
  font-size: 24px !important;
}

</style>
