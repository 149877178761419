<template>
  <smart-church-layout page="View Staff">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-btn class="bg-transparent ms-7 mt-5" small :to="{ name: 'AdminStaff' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
         

          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="
                  primary--text
                  font-weight-bold
                  d-flex
                  justify-center
                  mb-5
                "
              >
                view Staff
              </h2>
              <v-img
                :src="staff_details.logo"
                max-height="120"
                max-width="120"
                class="d-block mx-auto rounded-circle"
              ></v-img>
              <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ staff_details.first_name }}</span>
                <span class="me-3">{{ staff_details.last_name }}</span>
              </h2>
              <v-row class="mt-6 primary--text pa-4">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ staff_details.phone }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"><v-icon>mdi-home</v-icon>Branch</label>
                  <p>{{ staff_details.branch.name }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ staff_details.email }}</p>
                </v-col>

                 <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"><v-icon>mdi-party-popper</v-icon>Birthday</label>
                  <p>{{ getFormattedDate(staff_details.date_of_birth) }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="country"><v-icon>mdi-map</v-icon>Country</label>
                  <p>{{ staff_details.country }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="state"><v-icon>mdi-map</v-icon>State</label>
                  <p>{{ staff_details.state }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>City</label>
                  <p>{{ staff_details.city }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>Nationality</label>
                  <p>{{ staff_details.nationality }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-account</v-icon>Status</label>
                  <p>{{ staff_details.status }}</p>
                </v-col>

                <v-btn
                  block
                  :to="{
                    name: 'AdminEditStaff',
                    params: { staff_slug: routeParamSlug },
                  }"
                  color="primary"
                  >Edit Staff</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>


<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions,getFormattedDate } from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      staff_details: [],
      overlay: true,

      response: "",
      staff_details_errors: {},

      routeParamSlug: this.$route.params.staff_slug,

      isLoading: false,
    };
  },

  methods: {
    viewStaff() {
      this.isLoading = true;

      this.$http
        .get("admin/staffs/" + this.routeParamSlug)
        .then((response) => {
          this.staff_details = response.data.data;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

      getFormattedDate(date) {
      return getFormattedDate(date);
    },
  },

  mounted() {
    this.viewStaff();
  },
};
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
