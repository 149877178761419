<template>
  <smart-church-layout>
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-dialog
      v-model="dialog"
       max-width="900"
      height="700"
      overlay
      retain-focus
      transition="dialog-bottom-transition"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <v-card class="p-1 p-lg-3">
         <div class="d-flex justify-content-end">
          <v-btn icon dark @click="dialog = false" color="black" class="p-3">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row align="center" justify="center" class="mt-0 mt-lg-5 w-90 mx-auto">
          <v-col cols="12">
            <a href="https://smartchurch.ng/" target="_blank">
              <v-img
                src="@/assets/smartchurch-logo.png"
                max-height="180"
                max-width="180"
                class="d-block mx-auto"
              ></v-img>
            </a>
          </v-col>
          <v-col cols="12" lg="7">
            <v-form class="p-4" @submit.prevent="addFeedback" ref="ratingForm">
              <v-row>
                <v-col cols="12">
                  <h3 class="text-h4 text-bold text-center">
                    What is your rating?
                  </h3>
                  <p class="text-secondary fs-5 text-center">
                    Please drop a comment or remark about your 
                    experience using SmartChurch
                  </p>
                  <div class="w-75 mx-auto m-2">
                    <star-rating
                      animate
                      :glow="glow"
                      :show-rating="showNumber"
                      :star-size="starSize"
                      v-model="feedBack.rating"
                      class="d-flex justify-content-center"
                    />
                  </div>
                </v-col>
                <v-col cols="12" class="mt-5">
                  <v-textarea outlined v-model="feedBack.message" hint="Kindly complete the comment section" required> </v-textarea>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                class="fw-bold"
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Submit</v-btn
              >
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-container class="my-8">
      <div class="d-flex justify-space-between">
        <v-btn
          class="bg-transparent ms-7 mt-5"
          small
          :to="{ name: 'AdminExpenses' }"
          ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
        >
      </div>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <v-card
            class="d-flex justify-center mt-12 rounded-lg text-capitalize"
          >
            <v-form
              class="pa-10"
              ref="form"
              @submit.prevent="createExpenseReport"
            >
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                create expense
              </h2>
              <v-row v-for="(expense, index) in create_expense" :key="index">
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="create_expense[index].branch_id"
                    :items="branches"
                    dense
                    label="Branch"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="create_expense_errors.branch_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="create_expense[index].expense_title_id"
                    :items="expensesTitle"
                    dense
                    label="Expense Title"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="create_expense_errors.expense_title_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Date"
                    type="date"
                    id="Date"
                    v-model="create_expense[index].expense_date"
                    :error-messages="create_expense_errors.expense_date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="₦ Amount"
                    type="number"
                    id="Amount"
                    v-model="create_expense[index].amount"
                    :error-messages="create_expense_errors.amount"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    label="Description"
                    id="name"
                    v-model="create_expense[index].description"
                    :error-messages="create_expense_errors.description"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-btn
                    color="primary white--text"
                    class="m-2"
                    @click="addMore"
                    >Add more</v-btn
                  >
                  <v-btn
                    outlined
                    color="primary white--text"
                    class="m-2"
                    @click="removeExpense"
                    >remove</v-btn
                  >
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Create</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import StarRating from "vue-star-rating";

export default {
  components: { SmartChurchLayout, StarRating },
  data() {
    return {
      create_expense: [
        {
          amount: "",
          expense_title_id: null,
          description: "",
          branch_id: null,
          expense_date: "",
        },
      ],
      isSubmitted: false,
      create_expense_errors: {},
      branches: [],
      expensesTitle: [],

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      dialog: false,

      showNumber: false,
      starSize: 45,
      glow: 4,

      feedBack: {
        rating: 0,
        message: "",
      },
    };
  },
  methods: {
    async initExpenses() {
      this.isLoading = true;

      try {
        const expenseTitles = await this.$http.get("admin/expense-title");
        const user = this.$store.state.userDetail.user;

        this.branches = user.branches;
        this.expensesTitle = expenseTitles.data.data;
        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        this.mountedError = error.response.data.message;
        this.isLoading = false;
        this.overlay = false;
      }
    },

    addFeedback() {
      this.isSubmitted = true;

      this.$http
        .post("feedback", this.feedBack)
        .then((response) => {
          // console.log(response.data.message);
          this.isSubmitted = false;
          this.dialog = false;
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.$refs.ratingForm.reset();
          this.feedBack.rating = 0;
          localStorage.setItem("checkedRatingForm", true);
        })
        .catch((error) => {
          // console.log(error);
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },

    createExpenseReport() {
      this.create_expense.error = "";
      this.create_expense.response = "";

      this.isSubmitted = true;

      this.$http
        .post("admin/expense-report", { expense: this.create_expense })
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          // this.initExpenses();
          this.$refs.form.reset();

          if (!localStorage.getItem("checkedRatingForm")) {
            this.dialog = true;
          }
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";

          this.isSubmitted = false;
          this.create_expense_errors = error.response.data.errors;

          catchAxiosExceptions(error);
          // console.log(error.response.status);
          if (error.response.status === 402) {
            alert(
              "Please upgrade your Subscription Plan to enjoy unlimited access. Thank You!"
            );
            this.$refs.form.reset();
          }
          if (error.response.status === 400) {
            alert("The report for the month has been approved");
            this.$refs.form.reset();
          }
        });
    },
    addMore() {
      this.create_expense.push({
        branch_id: null,
        expense_date: "",
        amount: "",
        expense_title_id: null,
        description: "",
      });
    },
    removeExpense() {
      if (this.create_expense.length > 1) {
        this.create_expense.pop({
          branch_id: null,
          expense_date: "",
          amount: "",
          expense_title_id: null,
          description: "",
        });
      }
    },
  },
  mounted() {
    this.initExpenses();
  },
};
</script>

<style></style>
