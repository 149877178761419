<template>
  <smart-church-layout page="View User">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-btn class="bg-transparent ms-7 mt-5" small :to="{ name: 'AdminUsers' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
         
          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="primary--text font-weight-bold d-flex justify-center mb-5"
              >
                 User
              </h2>

              <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ user_details.first_name }}</span>
                <span class="me-3">{{ user_details.last_name }}</span>
              </h2>
              <v-row class="mt-6 primary--text pa-4">
                <!-- <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"><v-icon>mdi-home</v-icon>Branch</label>
                  <p>{{ user_details.branch.name }}</p>
                </v-col> -->
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ user_details.email }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ user_details.phone }}</div>
                </v-col>

                <!-- <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"
                    ><v-icon>mdi-party-popper</v-icon>Birthday</label
                  >
                  <p>{{ getFormattedDate(user_details.date_of_birth) }}</p>
                </v-col> -->

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="country"><v-icon>mdi-home</v-icon>Church</label>
                  <p>{{ churchName }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="state"><v-icon>mdi-map</v-icon>Address</label>
                  <p>{{ user_details.address }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>Country</label>
                  <p>{{ user_details.country }}</p>
                </v-col>

                <!-- <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>Nationality</label>
                  <p>{{ user_details.nationality }}</p>
                </v-col> -->

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-account</v-icon>Gender</label>
                  <p>{{ user_details.gender }}</p>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>


<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedDate,
} from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      user_details: [],
      overlay: true,

      churchName: "",

      response: "",
      user_details_errors: {},

      routeParamId: this.$route.params.user_id,

      isLoading: false,
    };
  },

  methods: {
    viewUser() {
      this.isLoading = true;

      this.$http
        .get("admin/users/" + this.routeParamId)
        .then((response) => {
          this.user_details = response.data.data;
          console.log(this.user_details);
          this.churchName = this.user_details.church.name;
          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

    getFormattedDate(date) {
      return getFormattedDate(date);
    },
  },

  mounted() {
    this.viewUser();
  },
};
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}

label {
  margin-bottom: 0.5em;
}
</style>
