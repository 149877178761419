<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
   
  />
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    //  :chart-id="chartId"
    // :dataset-id-key="datasetIdKey"
    // :plugins="plugins"
    // :css-classes="cssClasses"
    // :styles="styles"
    // :width="width"
    // :height="height"
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
     chartData:{
        type: Object,
        required: true,
    },
     chartOptions: {
        type: Object,
        default: () => {}
     },
    // chartId: {
    //   type: String,
    //   default: 'pie-chart'
    // },
    // datasetIdKey: {
    //   type: String,
    //   default: 'label'
    // },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    // cssClasses: {
    //   default: '',
    //   type: String
    // },
    // styles: {
    //   type: Object,
    //   default: () => {}
    // },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
   return{

   }
  }
}
</script>
