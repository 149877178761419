<template>
  <smart-church-layout page="Staff">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by Name, Email, Phone "
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-content-around mb-5 mt-10">
        <h3 class="primary--text font-weight-bold">Staff</h3>
        <v-btn
          color="primary text--white"
          class="mx-5 font-weight-bold"
          :to="{ name: 'AdminAddStaff' }"
          v-if="canManageStaffs || canManageMembers || noRoles"
          ><v-icon left>mdi-plus</v-icon><span>add Staff</span></v-btn
        >
        <v-spacer></v-spacer>
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search by Name, Email, Phone "
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

      

      <v-data-table
        :headers="headers"
        :items="staffs"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this staff?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent" icon>
                <v-icon>mdi-phone-outline</v-icon>
              </v-btn>
            </template>
            <v-list v-if="canManageStaffs || canManageMembers || noRoles">
              
              <v-list-item>
                <v-list-item-title>                 
                  <a :href="`tel:${item.phone}`" class="phone">
                    <v-icon small class="mr-2">
                      mdi-phone-plus </v-icon
                    >Call</a
                  >                 
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>                  
                  <a @click="openWhatsApp(item.phone)" color="success">
                    <v-icon small class="mr-2">
                      mdi-whatsapp </v-icon
                    >Whatsapp</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent" icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canManageStaffs || canManageMembers || noRoles">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none"
                    :to="{
                      name: 'AdminEditStaff',
                      params: { staff_slug: item.slug },
                    }"
                  >
                    <v-icon small class="mr-2"> mdi-pencil </v-icon>

                    <span class="textColor--text">Edit</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canViewStaffs || canViewMembers || noRoles || canManageStaffs || canManageMembers">
                <v-list-item-title>
                  <router-link
                    class="text-decoration-none"
                    :to="{
                      name: 'AdminViewStaff',
                      params: { staff_slug: item.slug },
                    }"
                  >
                    <v-icon small class="mr-2" >
                      mdi-eye
                    </v-icon>

                    <span class="textColor--text">View</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="canManageStaffs || canManageMembers || noRoles">
                <v-list-item-title>
                  <!-- <v-tooltip bottom> -->
                  <!-- <template v-slot:activator="{ on, attrs }"> -->

                  <!-- </template> -->
                  <a @click="deleteItem(item)">
                    <v-icon small class="mr-2" >
                      mdi-delete </v-icon
                    >Delete</a
                  >
                  <!-- </v-tooltip> -->
                </v-list-item-title>
              </v-list-item>
             
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
import skeletonLoader from "../../components/DataTableLoader.vue"
import { mapGetters } from 'vuex';


export default {
  components: { SmartChurchLayout,skeletonLoader },

  data() {
    return {
      search: "",
      dialogDelete: false,

      overlay: true,
      headers: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        // { text: "Address", value: "address" },
        // { text: "Unit in Church", value: "unit_in_church" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      staffs: [],

      staff_details: {},

      isLoading: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },


      user: this.$store.state.userDetail.user,

      userPermissionNames: [],
    }
  },

 computed: {
    ...mapGetters(['canViewMembers','canManageMembers','canManageStaffs','canViewStaffs','noRoles','isAdmin']),
  },

  methods: {
    async initStaff() {
      this.isLoading = true

      try {
        const allStaff = await this.$http.get("admin/staffs")

        this.staffs = allStaff.data.data

        this.isLoading = false
        this.overlay = false
      } catch (error) {
        // this.mountedError = error.response.data.message
        this.overlay = false
        this.isLoading = false

        catchAxiosExceptions(error)
      }
    },

    deleteItem(item) {
      this.editedIndex = this.staffs.indexOf(item)
      this.staff_details = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/staffs/" + this.staff_details.slug)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.closeDelete()
          this.initStaff()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          catchAxiosExceptions(error)
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.staff_details = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    openWhatsApp(phone) {
      const whatsappUrl = `https://wa.me/${phone}`;

      // Open the WhatsApp URL in a new tab or redirect the user to the WhatsApp application
      window.open(whatsappUrl);
    },
  },

  created(){
this.$store.commit('allPermissions');
    
  },

  mounted() {
    this.initStaff()
  },
}
</script>

<style scoped>
h3 {
  font-size: 24px !important;
}
.phone{
  color: black;
  text-decoration: none
}
/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
