<template>
  <smart-church-layout page="Edit Staff">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container fluid  class="my-8">
      <v-btn class="bg-transparent ms-7 mt-5" small :to="{ name: 'AdminStaff' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initStaffInfo"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="mt-12 rounded-lg text-capitalize">
            <v-form class="my-6 pa-10" ref="form" @submit.prevent="updateStaff">
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                edit Staff
              </h2>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                    v-model="staff_details.first_name"
                    :error-messages="staff_details_errors.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Last Name"
                    v-model="staff_details.last_name"
                    :error-messages="staff_details_errors.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    outlined
                    dense
                    label="Branch"
                    v-model="staff_details.branch_id"
                    :items="branches"
                    item-value="id"
                    item-text="name"
                    :error-messages="staff_details_errors.branch_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    v-model="staff_details.email"
                    :error-messages="staff_details_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Address"
                    v-model="staff_details.address"
                    :error-messages="staff_details_errors.address"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Phone Number"
                    type="number"
                    v-model="staff_details.phone"
                    :error-messages="staff_details_errors.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="date"
                    outlined
                    dense
                    label="Date Of Birth"
                    v-model="staff_details.date_of_birth"
                    :error-messages="staff_details_errors.date_of_birth"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Country"
                    v-model="staff_details.country"
                    :error-messages="staff_details_errors.country"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    v-model="staff_details.state"
                    :error-messages="staff_details_errors.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    v-model="staff_details.city"
                    :error-messages="staff_details_errors.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Nationality"
                    v-model="staff_details.nationality"
                    :error-messages="staff_details_errors.nationality"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Unit in Church"
                    id="unit in church"
                    v-model="staff_details.unit_in_church"
                    :error-messages="staff_details_errors.unit_in_church"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Occupation"
                    id="Occupation"
                    v-model="staff_details.occupation"
                    :error-messages="staff_details_errors.occupation"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" >
                  <v-select
                    :items="marital_status"
                    outlined
                    dense
                    label="Marital Status"
                    id="marital status"
                    v-model="staff_details.marital_status"
                    :error-messages="staff_details_errors.marital_status"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-select
                    :items="employment"
                    outlined
                    dense
                    label="Employment Status"
                    id="marital status"
                    v-model="staff_details.employment_status"
                    :error-messages="staff_details_errors.employment_status"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="status"
                    id="status"
                    label="Status"
                    value="Active"
                    v-model="staff_details.status"
                    :error-messages="staff_details_errors.status"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div class="img-content">
                    <v-icon x-large>mdi-image-outline</v-icon>
                    <p class="text--secondary">
                      Please upload as a JPG, <br />
                      JPEG or PNG format <br />
                      (not more than 2MB)
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex align-end">
                  <v-file-input
                    ref="fileInput"
                    :rules="rules"
                    show-size
                    accept="image/png, image/jpeg,"
                    small-chips
                    outlined
                    label="Upload Image"
                    prepend-icon="mdi-camera"
                    dense
                    v-model="photo.logo"
                    class="mx-auto upload-body"
                    :error-messages="photo_errors.logo"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn
                  @click="uploadPicture"
                  depressed
                  color="primary white--text"
                  class="mb-2"
                >
                  upload
                </v-btn>
              </div>
              <v-btn
                type="submit"
                depressed
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                color="primary white--text"
                >save</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout, skeletonLoader },

  data() {
    return {
      rules: [
        (value) =>
          !value ||
          !value.length ||
          value[0].size < 200000 ||
          "Avatar size should be less than 2 MB!",
      ],
      staff_details: {},

      items: ["yes", "no"],
      status: ["Active", "Inactive", "Suspended"],
      employment: ["Permanent Staff", "Part Time", "Volunteer"],
      marital_status: ["Single", "Married"],
      overlay: true,

      staff_details_errors: {},
      branches: [],

      routeParamSlug: this.$route.params.staff_slug,

      isLoading: false,

      isSubmitted: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      mountedError: null,
      photo: {
        logo: "",
      },
      photo_errors: {},
    }
  },

  methods: {
    async initStaffInfo() {
      if (this.routeParamSlug === undefined || this.routeParamSlug.length < 1) {
        this.$router.push({ name: "AdminStaff" })
      } else {
        this.isLoading = true

        try {
          const staff_details = await this.$http.get(
            "admin/staffs/" + this.routeParamSlug
          )
        const user = this.$store.state.userDetail.user;
           
          this.staff_details = staff_details.data.data
          this.branches = user.branches;

          this.isLoading = false
          this.overlay = false
        } catch (error) {
          this.mountedError = error.response.data.message
          this.isLoading = false
          this.overlay = false

          catchAxiosExceptions(error)
        }
      }
    },

    updateStaff() {
      this.isSubmitted = true

      this.$http
        .put("admin/staffs/" + this.routeParamSlug, this.staff_details)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.staff_details_errors = error.response.data.errors
          this.isSubmitted = false

          catchAxiosExceptions(error)
        })
    },
    uploadPicture() {
      const headers = { "Content-Type": "multipart/form-data" }
      this.$http
        .post(`admin/staffImageUpdate/${this.routeParamSlug}`, this.photo, {
          headers,
        })
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = "Picture uploaded successfully"
          this.snackbar.color = "green"
          console.log(response)
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
          this.photo_errors = error.response.data.errors
          console.log(error)
        })
    },
  },

  mounted() {
    this.initStaffInfo()
  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
.v-icon {
  color: #c73438;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.itk {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.v-text-field::-moz-focus-inner {
  background-color: red !important;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
}
.img-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
