<template>
  <v-container class="fill-height d-background" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-6 rounded-lg">
          <v-form @submit.prevent="submitForm" ref="form" class="px-5">
            <h2>Reset Password</h2>
            <p>
              Create a new account to be able to access your Smart Church
              account
            </p>

            <p class="emailHead mb-3"><v-icon>mdi-lock</v-icon>New Password</p>
            <v-text-field
              v-model="resetPasswordForm.password"
              :error-messages="church_form_errors.password"
              outlined
              dense
              rounded
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
            ></v-text-field>

            <p class="emailHead mb-3">
              <v-icon>mdi-lock</v-icon>Confirm Password
            </p>
            <v-text-field
              v-model="resetPasswordForm.password_confirmation"
              :error-messages="church_form_errors.password_confirmation"
              outlined
              dense
              rounded
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
            ></v-text-field>
            <v-btn
              type="submit"
              :loading="isSubmitted"
              :disabled="isSubmitted"
              block
              class="btn-request"
              >Reset Password</v-btn
            >
            <p class="text-center mt-4">
              haven't receive any link?
              <router-link :to="{ name: 'forgotten password' }"
                >Resend Link?</router-link
              >
            </p>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-text>
          <div class="text-h6">Password reset successful!</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="white darken-1" text @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "ResetPassword",

  data() {
    return {
      show1: false,
      show2: false,

      resetPasswordForm: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },

      church_form_errors: {},
      dialog: false,
      isSubmitted: false,
    }
  },

  methods: {
    initForgotPassword() {
      var routeQueryToken = this.$route.query.token
      var routeQueryEmail = this.$route.query.email

      if (
        routeQueryToken === undefined ||
        (routeQueryToken.length < 5 && routeQueryEmail === undefined) ||
        routeQueryEmail.length < 1
      ) {
        // redirect to the login page
        this.$router.push({ name: "Login" })
      } else {
        this.resetPasswordForm.token = routeQueryToken
        this.resetPasswordForm.email = routeQueryEmail.replace("%40", "@")
      }
    },

    submitForm() {
      this.isSubmitted = true
      this.$http
        .post("reset-password", this.resetPasswordForm)
        .then((response) => {
          this.dialog = true
          this.email = ""

          console.log(response.data.message)
          this.isSubmitted = false
          this.$store.commit("removeUserEmail")
          this.$router.push({ name: "Login" })
        })
        .catch((error) => {
          this.church_form_errors = error.response.data.message
          this.isSubmitted = false
        })
    },
  },

  mounted() {
    this.initForgotPassword()
  },
}
</script>

<style scoped>
.d-background {
  background: #fef6f6;
}
h2 {
  text-align: left;
  padding: 20px;
  color: #c73436;
  font-weight: bold;
}

.emailHead {
  margin-top: 1.6rem;
}

.v-btn {
  background: rgba(203, 68, 71, 1) !important;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  height: 40px !important;
  color: #fff;
  padding: 1rem 2rem !important;
}
p {
  margin: 10px;
  text-transform: capitalize !important;
  font-size: 1.2rem;
}
p span {
  color: rgba(203, 68, 71, 1);
  font-size: 1em;
}
a {
  text-decoration: none;
}
.btn-request {
  margin-top: 1rem;
}
</style>
