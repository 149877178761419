<template>
  <smart-church-layout>
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container fluid class="my-8">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <v-card class="mt-12 rounded-lg text-capitalize">
            <v-form
              class="my-6 pa-13"
              ref="form"
              @submit.prevent="updateProfile"
            >
              <h2 class="text-center my-8 primary--text font-weight-bold">
                Update Church profile account
              </h2>
              <v-img
                :src="getDetail.logo"
                class="mx-auto rounded-circle pa-6"
                max-height="120"
                max-width="120"
              >
              </v-img>
              <v-row class="mt-8">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="first name"
                    v-model="getDetail.first_name"
                    :error-messages="getDetail_errors.first_name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="last name"
                    v-model="getDetail.last_name"
                    :error-messages="getDetail_errors.last_name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="church name"
                    v-model="getDetail.name"
                    :error-messages="getDetail_errors.name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="address"
                    v-model="getDetail.address"
                    :error-messages="getDetail_errors.address"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="email"
                    v-model="getDetail.email"
                    :error-messages="getDetail_errors.email"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="phone number"
                    v-model="getDetail.phone"
                    :error-messages="getDetail_errors.phone"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    label="role in church"
                    v-model="getDetail.role_in_church"
                    :error-messages="getDetail_errors.role_in_church"
                    outlined
                    dense
                    required
                    :items="role"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="country"
                    v-model="getDetail.country"
                    :error-messages="getDetail_errors.country"
                    :items="availableCountries"
                    item-text="name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="state"
                    v-model="getDetail.state"
                    :error-messages="getDetail_errors.state"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="city"
                    v-model="getDetail.city"
                    :error-messages="getDetail_errors.city"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Gender"
                    :items="gender"
                    v-model="getDetail.gender"
                    :error-messages="getDetail_errors.gender"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Year"
                    v-model="getDetail.year"
                    :error-messages="getDetail_errors.year"
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <div class="img-content">
                    <v-icon x-large>mdi-image-outline</v-icon>
                    <p class="text--secondary">
                      Please upload as a JPG, <br />
                      JPEG or PNG format <br />
                      (not more than 2MB)
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex align-end">
                  <v-file-input
                    ref="fileInput"
                    
                    show-size
                    accept="image/png, image/jpeg,"
                    small-chips
                    outlined
                    label="Upload Image"
                    prepend-icon="mdi-camera"
                    dense
                    v-model="photo.logo"
                    class="mx-auto upload-body"
                    :error-messages="photo_errors.logo"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn
                  @click="uploadPicture"
                  depressed
                  color="primary white--text"
                  class="mb-2"
                >
                  upload
                </v-btn>
              </div>
              <!-- <div class="alert alert-success my-3" v-if="formMessage.success">
                {{ formMessage.success }}
              </div>
              <div
                class="alert alert-danger my-3 mb-3"
                v-if="formMessage.errors"
              >
                {{ formMessage.errors }}
              </div> -->
              <v-btn
                type="submit"
                block
                depressed
                color="primary white--text "
                :loading="isSubmitted"
                :disabled="isSubmitted"
                large
                >update profile</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
export default {
  components: { SmartChurchLayout },
  data() {
    return {
      // rules: [
      //   (value) =>
      //     !value ||
      //     !value.length ||
      //     value[0].size < 200000 ||
      //     "Avatar size should be less than 2 MB!",
      // ],
      role: [
        "Admin",
        "Branch Head",
        "Branch Assistant",
        "HR",
        "Finance Head",
        "Viewer",
      ],
      gender: ["Male", "Female"],

      getDetail: {
        name: "",
        last_name: "",
        first_name: "",
        role_in_church: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        role: "",
        city: "",
        address: "",
        gender: "",
        year: "",
        slug: "",
        logo: "",
        response: "",
        error: "",
      },
      photo: {
        logo: "",
      },
      photo_errors: {},

      show1: false,
      show2: false,

      isSubmitted: false,

      formMessage: {
        success: null,
        error: null,
      },
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      getDetail_errors: {},
      user: this.$store.state.userDetail.user,
    }
  },

  methods: {
    initProfile() {
      let userUseable = this.$store.state.userDetail.user

      this.getDetail.first_name = userUseable ? userUseable.church.first_name : ""
      this.getDetail.last_name = userUseable ? userUseable.church.last_name : ""
      this.getDetail.name = userUseable ? userUseable.church.name : ""
      this.getDetail.address = userUseable ? userUseable.church.address : ""
      this.getDetail.phone = userUseable ? userUseable.church.phone : ""
      this.getDetail.email = userUseable ? userUseable.church.email : ""
      this.getDetail.country = userUseable ? userUseable.church.country : ""
      this.getDetail.state = userUseable ? userUseable.church.state : ""
      this.getDetail.city = userUseable ? userUseable.church.city : ""
      this.getDetail.role = userUseable ? userUseable.church.role_in_church : ""
      this.getDetail.gender = userUseable ? userUseable.church.gender : ""
      this.getDetail.year = userUseable ? userUseable.church.year : ""
      this.getDetail.slug = userUseable ? userUseable.church.slug : ""
    },

    updateProfile() {
      this.formMessage.errors = ""
      this.formMessage.success = ""

      this.isSubmitted = true
      // const headers = { "Content-Type": "multipart/form-data" };

      this.$http
        .put("churches/" + this.getDetail.slug, this.getDetail)
        .then((response) => {
          this.getDetail.response = response.data
          // this.formMessage.success = response.data.message

          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0

          this.isSubmitted = false
        })
        .catch((error) => {
          // this.formMessage.errors = error.response.data.message
          this.getDetail_errors = error.response.data.errors

          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.isSubmitted = false

          catchAxiosExceptions(error)
        })
    },
    uploadPicture() {
      const headers = { "Content-Type": "multipart/form-data" }
      this.$http
        .post(`admin/churchImageUpdate/${this.getDetail.slug}`, this.photo, {
          headers,
        })
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = "Picture uploaded successfully"
          this.snackbar.color = "green"
          console.log(response)
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
          this.photo_errors = error.response.data.errors
          console.log(error)
        })
    },
    viewAdmin() {
      this.$http
        .get("churches/" + this.user.church.slug)
        .then((response) => {
          this.getDetail = response.data.data
          // console.log(this.getDetail)

          this.isLoading = false
          this.overlay = false
        })
        .catch((error) => {
          this.getDetail_errors = error.response.data.message
          catchAxiosExceptions(error)
        })
    },
  },

  mounted() {
    this.initProfile()
    this.viewAdmin()
  },
}
</script>
<style scoped>
.img-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
