<template>
  <smart-church-layout page="Email Template">
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout">{{ snackbar.msg
    }}</v-snackbar>
    <v-container class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'emailCommunication' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10" lg="10">
          <v-card class="
              d-flex
              justify-center
              mt-12
              rounded-lg
              text-capitalize
              elevation-0
            ">
            <v-form class="my-6 pa-13" ref="form">
              <v-row>
                <h2 class="mb-4 font-weight-bold primary--text">Create Birthday Template</h2>
                <v-col cols="12" md="12">
                  <v-text-field dense label="Subject" outlined v-model="birthdatTemplate.subject">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <vue-editor v-model="birthdatTemplate.body" />
                </v-col>
              </v-row>

              <v-btn color="primary white--text" @click="createTemplate()" :loading="isSubmitted">Save template</v-btn>

            </v-form>
          </v-card>
          <v-data-table :headers="headers" :items="emailTemplate" class="elevation-1 mt-5">
            <template v-slot:[`item.created_at`]="{ item }">
              {{ getFormattedDate(item.created_at) }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-form class="pa-9" ref="form" @submit="save">
                          <h2 class="mb-4 primary--text d-flex justify-center font-weight-bold">
                            Update Email Template
                          </h2>
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-text-field dense label="Subject" outlined v-model="birthdatTemplate.subject">
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <vue-editor v-model="birthdatTemplate.body" />
                            </v-col>

                          </v-row>

                          <v-btn type="submit" color="primary white--text" block :loading="isSubmitted"
                            :disabled="isSubmitted" @click="save">Update</v-btn>
                          <p class="text-center mt-3">
                            <v-btn depressed @click="closeUpdateForm">close</v-btn>
                          </p>
                        </v-form>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h6 text-center">Are you sure you want to delete this template
                      ?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <a @click="editItem(item)"><v-icon small class="mr-2">
                        mdi-pencil </v-icon>Edit</a>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <a @click="deleteItem(item)"><v-icon small class="mr-2">
                        mdi-delete </v-icon>Delete</a>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
import skeletonLoader from "../../components/DataTableLoader.vue"
import { getFormattedDate } from "../../__helpers/handelExceptions";
export default {
  components: { SmartChurchLayout, skeletonLoader },

  data() {
    return {
      search: "",
      dialog: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Date",
          value: "created_at",
          align: "start",
        },
        {
          text: "Body",
          value: "body",
        },

        {
          text: "Subject",
          value: "subject",
        },
        { text: "Status", value: "is_active", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],

      emailTemplate: [],
      overlay: true,

      birthdatTemplate: {
        subject: "",
        body: ""

      },

      defaultItem: {
        subject: "",
        body: ""
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      editedIndex: -1,
      birthdatTemplate_errors: {},

      isLoading: false,
      mountedError: null,
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    async inittemplate() {
      this.isLoading = true

      try {
        const template = await this.$http.get(`admin/birthdayTemplate`)

        this.emailTemplate = template.data.data
        console.log(this.emailTemplate)

        this.isLoading = false
        this.overlay = false
      } catch (error) {
        this.mountedError = error.response.data.message
        this.isLoading = false
        this.overlay = false

        catchAxiosExceptions(error)
      }
    },

    createTemplate() {

      this.isSubmitted = true

      this.$http
        .post("admin/birthdayTemplate", this.birthdatTemplate)
        .then((response) => {
          this.isSubmitted = false

          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.cancel()


        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.isSubmitted = false

          this.birthdatTemplate_errors = error.response.data.errors

          catchAxiosExceptions(error)
        })
    },

    editItem(item) {
      this.editedIndex = this.emailTemplate.indexOf(item)
      this.birthdatTemplate = Object.assign({}, item)
      this.dialog = true
    },

    save() {


      this.isSubmitted = true

      this.$http
        .put(
          "admin/birthdayTemplate/" + this.birthdatTemplate.id,
          this.birthdatTemplate
        )
        .then((response) => {
          this.isSubmitted = false

          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.inittemplate()
          this.close()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
          this.isSubmitted = false

          this.birthdatTemplate_errors = error.response.data.errors
          catchAxiosExceptions(error)
        })
    },

    cancel() {
      this.dialog = false
    },

    closeUpdateForm() {
      this.dialog = false
    },

    deleteItem(item) {
      this.editedIndex = this.emailTemplate.indexOf(item)
      this.birthdatTemplate = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/birthdayTemplate/" + this.birthdatTemplate.id)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.inittemplate()
          this.closeDelete()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          catchAxiosExceptions(error)
        })
    },

    close() {


      this.dialog = false
      this.$nextTick(() => {
        this.birthdatTemplate = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.birthdatTemplate = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
  },

  mounted() {
    this.inittemplate()
  },
}
</script>

