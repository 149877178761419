<template>
  <smart-church-layout page="Transition Template">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <v-container class="my-8">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex d-md-none d-lg-none mt-5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Transition Template "
            outlined
            rounded
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <h3 class="primary--text font-weight-bold d-lg-none mt-6">
        Transition Template
      </h3>

      <div class="d-flex mb-5 mt-8">
        <h3 class="primary--text font-weight-bold d-none d-lg-block">
          Transition Template
        </h3>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-5 font-weight-bold"
              v-bind="attrs"
              v-on="on"
              color="primary text--white"
              v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles"
              ><v-icon>mdi-plus</v-icon> Create Transition Template</v-btn
            >
          </template>
          <v-card class="rounded-lg text-capitalize">
            <v-form class="pa-9" ref="form" @submit.prevent="createTransitionTemplate">
              <h2
                class="mb-4 primary--text d-flex justify-center font-weight-bold"
              >
                Create Transition Template
              </h2>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    label="Transition Template"
                    id="Transition Template"
                    v-model="create_transition_template.name"
                    :error-messages="create_transition_template_errors.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Create</v-btn
              >

              <p class="text-center my-3">
                <v-btn depressed small @click="cancel">Close</v-btn>
              </p>
            </v-form>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Transition Template "
          outlined
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>

      
      <v-data-table
        :headers="headers"
        :items="transitionTemplate"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog2" max-width="600px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-form class="pa-9" ref="form" @submit.prevent="save">
                      <h2
                        class="mb-4 primary--text d-flex justify-center font-weight-bold"
                      >
                        Update Transition Template
                      </h2>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            outlined
                            dense
                            label="Transition Template"
                            id="Transition Template"
                            v-model="create_transition_template.name"
                            :error-messages="create_transition_template_errors.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-btn
                        type="submit"
                        color="primary white--text"
                        block
                        :loading="isSubmitted"
                        :disabled="isSubmitted"
                        >Update</v-btn
                      >
                      <p class="text-center mt-3">
                        <v-btn depressed @click="closeUpdateForm">close</v-btn>
                      </p>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 text-center"
                  >Are you sure you want to delete this Template?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on" class="bg-transparent">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item   v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <a @click="editItem(item)"
                    ><v-icon small class="mr-2"> mdi-pencil </v-icon>Edit</a
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item   v-if="canManageUsers || canViewUsers || canManageRoles || canViewRoles || noRoles">
                <v-list-item-title>
                  <a @click="deleteItem(item)"
                    ><v-icon small class="mr-2"> mdi-delete </v-icon>Delete</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
import { mapGetters } from 'vuex';


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      search: "",
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      transitionTemplate: [],
      overlay: true,

      create_transition_template: {
        name: "",
      },

      defaultItem: {
        name: "",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      editedIndex: -1,
      create_transition_template_errors: {},

      isLoading: false,

      user: this.$store.state.userDetail.user,

      userPermissionNames: [],
    }
  },

  watch: {
    dialog2(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

 computed: {
   ...mapGetters(['noRoles','canManageUsers','canViewUsers','canManageRoles','canViewRoles']),
  },

  methods: {
    async inittransitionTemplate() {
      this.isLoading = true

      try {
        const transitionTemplate = await this.$http.get(`admin/first-timer-class`)

        this.transitionTemplate = transitionTemplate.data.data
        this.isLoading = false
        this.overlay = false
      } catch (error) {
        this.isLoading = false
        this.overlay = false

        catchAxiosExceptions(error)
      }
    },

    createTransitionTemplate() {
      this.create_transition_template_errors = {}

      this.isSubmitted = true

      this.$http
        .post("admin/first-timer-class", this.create_transition_template)
        .then((response) => {
          this.isSubmitted = false

          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.cancel()

          this.create_transition_template.name = ""

          this.inittransitionTemplate()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.isSubmitted = false

          this.create_transition_template_errors = error.response.data.errors

          catchAxiosExceptions(error)
        })
    },

    editItem(item) {
      this.editedIndex = this.transitionTemplate.indexOf(item)
      this.create_transition_template = Object.assign({}, item)
      this.dialog2 = true
    },

    save() {
      this.create_transition_template_errors = {}

      this.isSubmitted = true

      this.$http
        .put(
          "admin/first-timer-class/" + this.create_transition_template.id,
          this.create_transition_template
        )
        .then((response) => {
          this.isSubmitted = false

          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.inittransitionTemplate()
          this.close()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
          this.isSubmitted = false

          this.create_transition_template_errors = error.response.data.errors
          catchAxiosExceptions(error)
        })
    },

    cancel() {
      this.create_transition_template_errors = {}

      this.dialog = false
    },

    closeUpdateForm() {
      this.dialog2 = false
    },

    deleteItem(item) {
      this.editedIndex = this.transitionTemplate.indexOf(item)
      this.create_transition_template = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$http
        .delete("admin/first-timer-class/" + this.create_transition_template.id)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.inittransitionTemplate()
          this.closeDelete()
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          catchAxiosExceptions(error)
        })
    },

    close() {
      this.create_transition_template_errors = {}

      this.dialog2 = false
      this.$nextTick(() => {
        this.create_transition_template = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.create_transition_template = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },

    created(){
this.$store.commit('allPermissions');
    
  },

  mounted() {
    this.inittransitionTemplate()
  },
}
</script>

<style>
h3 {
  font-size: 24px !important;
}

/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}

.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}

tr:nth-child(odd) {
  background-color: #fff !important;
}

tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
