<template>
  <smart-church-layout page="Bulk Import">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
     <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" class="mt-7" /> -->

    <v-container class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminMembers' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="7">
          <v-card class="my-8 pa-5 rounded-lg text-capitalize">
            <v-form>
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                Import Member From Excel
              </h2>
            </v-form>
            <v-row class="my-10">
              <v-col cols="12" lg="11">
                <v-autocomplete
                  v-model="import_excel.branch_id"
                  :items="branches"
                  dense
                  prepend-icon="mdi-home"
                  label="Branch"
                  id="branch_id"
                  item-value="id"
                  outlined
                  item-text="name"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="11">
                <v-file-input
                  ref="fileInput"
                  v-model="import_excel.excel_sheet"
                  show-size
                  label="Import"
                  density="compact"
                  dense
                  outlined
                  @click="onFileSelected"
                ></v-file-input>
              </v-col>
              <v-col cols="12" >
                <p class="text-capitalize">Please fill in the date in the excel sheet using this format <span class="text-lowercase">dd/mm/yyyy</span></p>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-btn
                      color="primary"
                      elevation="0"
                      outlined
                      @click="downloadSampleTemplate"
                    >
                      <v-icon left> mdi-download-outline </v-icon>
                      <small> Download sample Excel template</small>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-btn
                      color="primary white--text"
                      :loading="isSubmitted"
                      :disabled="isSubmitted"
                      @click="memberImport"
                      >Import</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"

// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout },
  data() {
    return {
      branches: [],
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      overlay: true,

      isSubmitted: false,

      import_excel: {
        excel_sheet: "",
        branch_id: "",
      },
    }
  },
  methods: {
    async initAddMember() {
      this.isLoading = true

      try {
        const user = this.$store.state.userDetail.user;
         
        this.branches = user.branches;
        this.isLoading = false

        this.overlay = false
      } catch (error) {
        this.mountedError = error.response.data.message
        this.isLoading = false
        this.overlay = false
      }
    },

    downloadSampleTemplate() {
      let headers =
        "first_name, last_name, email, phone, date_of_birth, sex, nationality, country, state, city, employment_status, occupation, marital_status, unit_in_church "
      const theSheet = new Blob([headers], { type: "text/csv" })

      var FILE = window.URL.createObjectURL(new Blob([theSheet]))

      var docUrl = document.createElement("a")
      docUrl.href = FILE
      docUrl.setAttribute(
        "download",
        "smart-church-member-excel-import-sample.csv"
      )
      document.body.appendChild(docUrl)
      docUrl.click()
    },

    memberImport() {
      // this.import_excel = "";
      this.isSubmitted = true
      const headers = { "Content-Type": "multipart/form-data" }

      this.$http
        .post("admin/membersImport", this.import_excel, {
          headers,
        })
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false
        })
        .catch((error) => {
          this.isSubmitted = false
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
          catchAxiosExceptions(error)
        })
    },
    onFileSelected() {
      console.log(this.import_excel)
    },
  },
  mounted() {
    this.initAddMember()
  },
}
</script>

<style></style>
