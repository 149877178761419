<template>
  <smart-church-layout page="View Member">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container  class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminMembers' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="viewMember"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="
                  primary--text
                  font-weight-bold
                  d-flex
                  justify-center
                  mb-5
                "
              >
                view Member
              </h2>
              
              <v-img
              :src="member_details.logo"
              class="mx-auto rounded-circle pa-6"
              max-height="120"
              max-width="120"
              >
              </v-img>

              <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ member_details.first_name }}</span>
                <span class="me-3">{{ member_details.last_name }}</span>
              </h2>
              <v-row class="mt-6 primary--text pa-4">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ member_details.phone }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"><v-icon>mdi-home</v-icon>Branch</label>
                  <p>{{ member_details_branch_name  }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ member_details.email }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"><v-icon>mdi-party-popper</v-icon>Birthday</label>
                  <p>{{ getFormattedDate(member_details.date_of_birth) }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="country"><v-icon>mdi-map</v-icon>Country</label>
                  <p>{{ member_details.country }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="state"><v-icon>mdi-map</v-icon>State</label>
                  <p>{{ member_details.state }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>City</label>
                  <p>{{ member_details.city }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>Nationality</label>
                  <p>{{ member_details.nationality }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"
                    ><v-icon>mdi-account</v-icon>Unit in Church</label
                  >
                  <p>{{ member_details.unit_in_church }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-account</v-icon>Status</label>
                  <p>{{ member_details.status }}</p>
                </v-col>

                <v-btn
                  block
                  :to="{
                    name: 'AdminEditMember',
                    params: { member_slug: routeParamSlug },
                  }"
                  color="primary"
                  >Edit Member</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions,getFormattedDate } from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      member_details: {},
      overlay: true,

      routeParamSlug: this.$route.params.member_slug,
      member_details_branch_name : "",

      isLoading: false,
      mountedError: null,
    };
  },

  methods: {
    viewMember() {
      this.isLoading = true;

      // const headers = { "Content-Type": "multipart/form-data" };

      this.$http
        .get("admin/members/" + this.routeParamSlug )
        .then((response) => {
          this.member_details = response.data.data;
            // console.log(this.member_details);
          this.isLoading = false;
          this.overlay = false;
          this.member_details_branch_name = this.member_details.branch.name
        })
        .catch((error) => {
          this.mountedError = error.response.data.message;
          this.isLoading = false;
          this.overlay = false;

          catchAxiosExceptions(error);
        });
    },

     getFormattedDate(date) {
      return getFormattedDate(date);
    },
  },

  mounted() {
    this.viewMember();
  },
};
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
