<template>
  <smart-church-layout page="View Service Report">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <v-container class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminServiceReport' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="viewAdminServiceReport"
            >
              Fetch again
            </v-btn>
          </div>
          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="primary--text font-weight-bold d-flex justify-center mb-5"
              >
                view Service Report
              </h2>
              <v-row class="mt-6 pa-4 mb-0">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-home-outline</v-icon
                    >Branch</label
                  >
                  <p class="textColor--text">{{ branchName }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-file-outline</v-icon
                    >Service</label
                  >
                  <p>{{ serviceName }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-calendar-range-outline</v-icon
                    >Service Date</label
                  >
                  <p>
                    {{ getFormattedDate(serviceReport_items.service_date) }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="service Day"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-weather-cloudy</v-icon
                    >Service Day</label
                  >
                  <p>{{ serviceReport_items.service_day }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="service title"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-file-outline</v-icon
                    >Service Title</label
                  >
                  <p>{{ serviceReport_items.service_title }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="service Income"
                    ><v-icon class="textColor--text mb-1">mdi-cash</v-icon
                    >Service Income</label
                  >
                  <p>
                    {{ getFormattedAmount(serviceReport_items.total_income) }}
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="male attendance"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >male Attendance</label
                  >
                  <p>{{ serviceReport_items.male_attendance }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="female attendance"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >Female Attendance</label
                  >
                  <p>{{ serviceReport_items.female_attendance }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="children attendance"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >Children Attendance</label
                  >
                  <p>{{ serviceReport_items.children_attendance }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="teenager attendance"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >Teenager(s) Attendance</label
                  >
                  <p>{{ serviceReport_items.teenager_attendance }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="first timer"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >First Timer(s)</label
                  >
                  <p>{{ serviceReport_items.first_timers }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="new convert"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >New Convert(s)</label
                  >
                  <p>{{ serviceReport_items.new_convert }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="speaker"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >Speaker</label
                  >
                  <p>{{ serviceReport_items.speaker }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="Topic"
                    ><v-icon class="textColor--text mb-1"
                      >mdi-account-outline</v-icon
                    >Topic</label
                  >
                  <p>{{ serviceReport_items.topic }}</p>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="incomes"
                sort-by="calories"
                disable-pagination
                hide-default-footer
                class="elevation-1 my-4"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title class="text-center textColor--text"
                      >Incomes</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog2" max-width="600px" persistent>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-5 font-weight-bold"
                          v-bind="attrs"
                          v-on="on"
                          color="primary text--white"
                          ><v-icon>mdi-plus</v-icon> Add Income</v-btn
                        >
                      </template>
                      <v-card class="rounded-lg text-capitalize">
                        <v-card-title>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="dialog2 = false">
                            <v-icon>mdi-window-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-form class="pa-9" ref="form">
                          <h2
                            class="mb-4 primary--text d-flex justify-center font-weight-bold"
                          >
                            Add Income
                          </h2>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="income_report_payload.branch_id"
                                :items="branches"
                                dense
                                label="Branch"
                                id="branch_id"
                                item-value="id"
                                outlined
                                item-text="name"
                                :error-messages="incomeReportErrors.branch_id"
                              >
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                type="date"
                                dense
                                label="Income Date"
                                outlined
                                v-model="income_report_payload.income_date"
                                :error-messages="incomeReportErrors.income_date"
                              ></v-text-field>
                            </v-col>
                            <v-col lg="12">
                              <v-autocomplete
                                :items="incomeTitles"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                label="Income Title"
                                v-model="income_report_payload.income_title_id"
                                :error-messages="
                                  incomeReportErrors.income_title_id
                                "
                              ></v-autocomplete>
                            </v-col>
                            <v-col lg="12">
                              <v-text-field
                                label="Amount"
                                outlined
                                dense
                                v-model="income_report_payload.amount"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-btn
                            type="submit"
                            color="primary white--text"
                            block
                            :loading="isSubmitted"
                            :disabled="isSubmitted"
                            @click="createIncome"
                            >Add Income</v-btn
                          >
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Edit</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-autocomplete
                              :items="incomeTitles"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              label="Income Title"
                              v-model="
                                serviceReport_details.income_reports
                                  .income_title_id
                              "
                            ></v-autocomplete>
                            <v-text-field
                              label="Amount"
                              outlined
                              dense
                              v-model="
                                serviceReport_details.income_reports.amount
                              "
                            >
                            </v-text-field>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary darken-1" text @click="close">
                            Cancel
                          </v-btn>
                          <v-btn color="primary darken-1" text @click="save">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5"
                          >Are you sure you want to delete this
                          item?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary darken-1"
                            text
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="primary darken-1"
                            text
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  ₦{{ getFormattedAmount(item.amount) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>

              <v-btn
                block
                :to="{
                  name: 'AdminEditServiceReport',
                  params: { serviceReport_slug: routeParamSlug },
                }"
                color="primary"
                >Edit Service Report</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedAmount,
  getFormattedDate,
} from "../../__helpers/handelExceptions";
export default {
  components: { SmartChurchLayout },

  data() {
    return {
      overlay: false,
      dialog2: false,
      isSubmitted: false,
      incomes: [],
      serviceReport_items: [],
      routeParamSlug: this.$route.params.serviceReport_slug,
      user: this.$store.state.userDetail.user,
      branches: [],
      mountedError: null,
      incomeTitles: [],
      isLoading: false,
      dialog: false,
      dialogDelete: false,
      branchName: "",
      serviceName: "",
      headers: [
        {
          text: "Name",

          value: "income_title.name",
        },
        { text: "Amount", value: "amount" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      serviceReport_details: {
        income_reports: [{ income_title_id: "", amount: "" }],

        response: "",
        error: "",
      },
      income_report_payload: {
        service_report_id: "",
        income_title_id: "",
        amount: "",
        income_date: "",
        branch_id: "",
      },
      incomeReportErrors: {},
    };
  },

  methods: {
    viewAdminServiceReport() {
      this.isLoading = true;

      this.$http
        .get("admin/service-report/" + this.routeParamSlug)
        .then((response) => {
          this.serviceReport_items = response.data.data;
          // <!-- console.log(this.serviceReport_items); -->
          this.branchName = this.serviceReport_items.branch.name;
          this.serviceName = this.serviceReport_items.service_type.name;
          this.income_report_payload.service_report_id = response.data.data.id;
          this.incomes = this.serviceReport_items.income_reports;
          // console.log(this.incomes)
          this.income_report_payload.income_date = this.incomes[0].income_date;
          this.income_report_payload.branch_id = this.incomes[0].branch_id;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          // this.mountedError = error.response.data.message
          this.overlay = false;
          this.isLoading = false;

          catchAxiosExceptions(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.incomes.indexOf(item);
      this.serviceReport_details.income_reports = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.incomes.indexOf(item);
      this.serviceReport_details.income_reports = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(
          `admin/incomeReports/` +
            this.serviceReport_details.income_reports.slug
        )
        .then((response) => {
          this.snackbar.status = true;

          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.viewAdminServiceReport();
          this.closeDelete();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
          catchAxiosExceptions(error);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$http
        .put(
          `admin/incomeReports/` +
            this.serviceReport_details.income_reports.slug,
          this.serviceReport_details.income_reports
        )
        .then((response) => {
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.viewAdminServiceReport();
          this.close();
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
          catchAxiosExceptions(error);
        });
    },

    createIncome() {
      this.isSubmitted = true;

      this.$http
        .post(`admin/incomeReports`, this.income_report_payload)
        .then((response) => {
          this.snackbar.status = true;
          this.isSubmitted = false;

          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.dialog2 = false;

          this.viewAdminServiceReport();
          this.$refs.form.reset();
        })
        .catch((error) => {
          this.snackbar.color = "red";
          // catchAxiosExceptions(error);
          if (error.response.status === 402) {
            alert(
              "Please upgrade your Subscription Plan to enjoy unlimited access. Thank You!"
            );
            this.$refs.form.reset();
          }
          this.snackbar.msg = error.response.data.message;
          this.incomeReportErrors = error.response.data.errors;
          this.isSubmitted = false;
        });
    },

    getIncomeTitles() {
      this.$http
        .get(`admin/income-title`)
        .then((response) => {
          this.incomeTitles = response.data.data;
        })
        .catch((error) => {
          catchAxiosExceptions(error);
        });
    },

    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
  },

  mounted() {
    this.viewAdminServiceReport();
    this.getIncomeTitles();
    this.branches = this.user.branches;
  },
};
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
