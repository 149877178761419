<template>
  <smart-church-layout page="Edit Branch">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container fluid  class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminBranches' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initBranchInfo"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="mt-12 rounded-lg text-capitalize">
            <v-form
              class="my-6 pa-10"
              ref="form"
              @submit.prevent="updateBranch"
            >
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                edit branch
              </h2>

              <v-row class="mt-6">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Name"
                    id="name"
                    v-model="branch_form.name"
                    :error-messages="branch_form_errors.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    id="city"
                    v-model="branch_form.city"
                    :error-messages="branch_form_errors.city"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    id="state"
                    v-model="branch_form.state"
                    :error-messages="branch_form_errors.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Country"
                    id="country"
                    v-model="branch_form.country"
                    :error-messages="branch_form_errors.country"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    id="email"
                    type="email"
                    v-model="branch_form.email"
                    :error-messages="branch_form_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Phone Number"
                    id="phone"
                    type="number"
                    v-model="branch_form.phone"
                    :error-messages="branch_form_errors.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="branch_form.pastor_id"
                    :items="pastors"
                    dense
                    label="Head pastor"
                    item-value="id"
                    outlined
                    item-text="first_name"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    label="Year of creation"
                    id="year"
                    type="date"
                    v-model="branch_form.year"
                    :error-messages="branch_form_errors.year"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    label="Address"
                    id="address"
                    v-model="branch_form.address"
                    :error-messages="branch_form_errors.address"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="branch_form.is_active"
                    label="Is this branch active?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="branch_form.is_head_branch"
                    label="Is this the head branch?"
                  ></v-switch>
                </v-col>
                <div
                  class="alert alert-success my-3"
                  v-if="branch_form.response"
                >
                  {{ branch_form.response }}
                </div>
                <div class="alert alert-danger my-3" v-if="branch_form.error">
                  {{ branch_form.error }}
                </div>
              </v-row>
              <v-btn
                type="submit"
                depressed
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                color="primary white--text"
                >save</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      branch_form: {
        error: "",
        response: "",
        pastor_id: "",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      items: ["yes", "no"],

      overlay: true,
      branch_form_errors: {},
      branches: [],
      branchData: [],

      pastors: [],
      routeParamSlug: this.$route.params.branch_slug,

      isLoading: false,
      mountedError: null,
    }
  },

  methods: {
    initBranchInfo() {
      if (this.routeParamSlug === undefined || this.routeParamSlug.length < 1) {
        this.$router.push({ name: "branches" })
      } else {
        this.isLoading = true

        this.$http
          .get("admin/branches/" + this.routeParamSlug)
          .then((response) => {
            this.branch_form = response.data.data

            this.isLoading = false
            this.overlay = false
          })
          .catch((error) => {
            this.mountedError = error.response.data.message
            this.overlay = false
            this.isLoading = false

            catchAxiosExceptions(error)
          })
      }
    },

    updateBranch() {
      this.branch_form.response = ""
      this.branch_form.error = ""

      this.isSubmitted = true

      this.$http
        .put("admin/branches/" + this.routeParamSlug, this.branch_form)
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false

          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.branch_form_errors = error.response.data.errors
          this.isSubmitted = false
          catchAxiosExceptions(error)
        })
    },

    allPastors() {
      this.$http
        .get("admin/pastors")
        .then((response) => {
          this.pastors = response.data.data
          // console.log(this.pastors)
        })
        .catch((error) => {
          this.create_branch_errors = error.response.data.message
        })
    },
  },

  mounted() {
    this.initBranchInfo()
    this.allPastors()
  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
.v-icon {
  color: #c73438;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.itk {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.v-text-field::-moz-focus-inner {
  background-color: red !important;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
}
</style>
