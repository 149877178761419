<template>
  <smart-church-layout page="Approved Months">
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <v-container >
      <v-data-table
        :headers="headers"
        :items="approvedMonths"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:[`item.month`]="{ item }">
          {{ getFormattedDate(item.month) }}
        </template>
      </v-data-table>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import {
  catchAxiosExceptions,
  getFormattedDate,
} from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/DataTableLoader.vue"

export default {
  components: { SmartChurchLayout },
  data() {
    return {
      headers: [
        {
          text: "Branch",
          align: "start",

          value: "branch.name",
        },
        { text: "Months", value: "month" },
      ],
      approvedMonths: [],
      overlay: true,
    }
  },
  methods: {
    getApprovedMonths() {
      this.$http
        .get(`admin/approvedReportsList`)
        .then((response) => {
          // console.log(response)
          this.overlay = false
          this.approvedMonths = response.data.data
        })
        .catch((error) => {
          // console.log(error)
          catchAxiosExceptions(error);
        })
    },

    getFormattedDate(date) {
      return getFormattedDate(date)
    },
  },
  mounted() {
    this.getApprovedMonths()
  },
}
</script>
<style></style>
