<template>
  <smart-church-layout page="View First Timer">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminFirstTimers' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="viewFirstTimer"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="primary--text font-weight-bold d-flex justify-center mb-7"
              >
                view First Timer
              </h2>
              <!-- <v-img
                :src="first_timer_details.logo"
                max-height="120"
                max-width="120"
                class="d-block mx-auto rounded-circle"
              ></v-img> -->
              <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ first_timer_details.first_name }}</span>
                <span class="me-3">{{ first_timer_details.last_name }}</span>
              </h2>
              <v-row class="mt-6 primary--text pa-4">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ first_timer_details.phone }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"><v-icon>mdi-home</v-icon>Branch</label>
                  <p>{{ branchName }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ first_timer_details.email }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"
                    ><v-icon>mdi-account</v-icon>Employment Status</label
                  >
                  <p>{{ first_timer_details.employment_status }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"
                    ><v-icon>mdi-account</v-icon>Marital Status</label
                  >
                  <p>{{ first_timer_details.marital_status }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"
                    ><v-icon>mdi-party-popper</v-icon>Birthday</label
                  >
                  <p>
                    {{ getFormattedDate(first_timer_details.date_of_birth) }}
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-account</v-icon>Status</label>
                  <p>{{ first_timer_details.status }}</p>
                </v-col>

                <v-btn
                  block
                  :to="{
                    name: 'AdminEditFirstTimers',
                    params: { firstTimer_slug: routeParamSlug },
                  }"
                  color="primary"
                  >Edit First Timer</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import {
  catchAxiosExceptions,
  getFormattedDate,
} from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      first_timer_details: [],
      overlay: true,

      response: "",
      first_timer_details_errors: {},

      routeParamSlug: this.$route.params.firstTimer_slug,

      isLoading: false,
      mountedError: null,
      branchName: "",
    }
  },

  methods: {
    viewFirstTimer() {
      this.isLoading = true

      this.$http
        .get("admin/firstTimers/" + this.routeParamSlug)
        .then((response) => {
          this.first_timer_details = response.data.data
           this.branchName = this.first_timer_details.branch.name
          this.isLoading = false
          this.overlay = false
        })
        .catch((error) => {
          this.mountedError = error.response.data.message
          this.isLoading = false
          this.overlay = false

          catchAxiosExceptions(error)
        })
    },

    getFormattedDate(date) {
      return getFormattedDate(date)
    },
  },

  mounted() {
    this.viewFirstTimer()
  },
}
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
