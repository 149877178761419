<template>
  <v-container class="fill-height d-background" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <a href="https://smartchurch.ng/" target="_blank">
          <v-img
            src="@/assets/smartchurch-logo.png"
            max-height="180"
            max-width="180"
            class="d-block mx-auto mb-6"
          ></v-img>
        </a>
        <v-card class="pa-6 rounded-lg">
          <v-form @submit.prevent="submitForm" ref="form" class="px-5">
            <h3 class="text-center">Forgot Password</h3>
            <p class="fs-5 mb-5">
              Enter your verified email address to reset your Smartchurch
              account
            </p>
            <!-- <p class="emailHead"><v-icon>mdi-email</v-icon>email</p> -->
            <v-text-field
              outlined
              dense
              label="Email"
              rounded
              v-model="email"
              required
              :error-messages="church_form_errors.email"
            ></v-text-field>

            <v-btn
              type="submit"
              :loading="isSubmitted"
              :disabled="isSubmitted"
              block
              color="primary"
              >Request Reset Link</v-btn
            >

            <v-card-text class="text-center content-paragraph">
              Already have an account?
              <router-link :to="{ name: 'Login' }">Login</router-link>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-text>
          <div class="text-h6 pt-5">An email has been sent to you!</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            class="my-3"
            text
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      church_form_errors: {},

      dialog: false,
      isSubmitted: false,
    }
  },

  methods: {
    submitForm() {
      this.isSubmitted = true
      this.$http
        .post("password-reset", {
          email: this.email,
        })
        .then((response) => {
          this.dialog = true

          console.log(response.data.message)
          this.isSubmitted = false
          this.$store.commit("setUserEmail", this.email)
          this.email = ""
          // this.$router.push({ name: "forgotten password" });
        })
        .catch((error) => {
          this.church_form_errors = error.response.data.errors
          this.isSubmitted = false
        })
    },
  },
}
</script>

<style scoped>
.d-background {
  background: #fef6f6;
}
h2,
h3 {
  text-align: left;
  padding: 20px;
  color: #c73436;
  font-weight: bold;
}

.emailHead {
  margin-top: 1.6rem;
}

/* .v-btn {
  background: rgba(203, 68, 71, 1) !important;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  height: 40px !important;
  color: #fff;
  padding: 1rem 2rem !important;
} */
p {
  margin: 10px;
  text-transform: capitalize !important;
  font-size: 1.2rem;
}
p span {
  color: rgba(203, 68, 71, 1);
  font-size: 1em;
}
a {
  text-decoration: none;
}
.btn-request {
  margin-top: 1rem;
}
</style>
