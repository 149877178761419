import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import PageNotFound from "../views/PageNotFound";
import ServerError from "../views/ServerError";

// Activity Log
import ActivityLog from "../views/ActivityLog.vue"

import AdminLogin from "../views/admin/AdminLogin";
import AdminSignUp from "../views/admin/AdminSignUp";
import AdminForgottenPassword from "../views/admin/AdminForgottenPassword";
import WelcomeAfterSignup from "../views/accounts/WelcomeAfterSignUp.vue";
import AdminUpdateCurrentUser from "../views/admin/AdminUpdateCurrentUser.vue";
import AdminSetOpeningBalance from "../views/admin/AdminSetOpeningBalance.vue";

import AdminView from "../views/admin/AdminView.vue";
import AdminDashboard from "../views/admin/AdminDashboard.vue";
import AdminBranches from "../views/admin/AdminBranches";
import AdminCreateBranch from "../views/admin/AdminCreateBranch";
import AdminViewBranch from "../views/admin/AdminViewBranch";
import AdminHumanResources from "../views/admin/AdminHumanResources.vue";
import AdminAddPastor from "../views/admin/AdminAddPastor.vue";
import AdminViewPastor from "../views/admin/AdminViewPastor.vue";
import AdminResetPassword from "../views/admin/AdminResetPassword.vue";
import AdminServiceReport from "../views/admin/AdminServiceReport.vue";
import AdminServices from "../views/admin/AdminServices.vue";
import AdminCreateServiceReport from "../views/admin/AdminCreateServiceReport.vue";
import AdminMemberDemographics from "../views/admin/AdminMemberDemographics.vue";
import AdminEditServiceReport from "../views/admin/AdminEditServiceReport.vue";
import AdminViewServiceReport from "../views/admin/AdminViewServiceReport.vue";
// import AdminMonthlyYearlyBranchReport from "../views/admin/AdminMonthlyYearlyBranchReport.vue";
import AdminEditBranch from "../views/admin/AdminEditBranch.vue";
import AdminFinance from "../views/admin/AdminFinance.vue";
import AdminEditPastor from "../views/admin/AdminEditPastor.vue";
import AdminExpenses from "../views/admin/AdminExpenses.vue";
import AdminExpenseTitle from "../views/admin/AdminExpenseTitle.vue";
import AdminChangePassword from "../views/admin/AdminChangePassword";
import AdminIncomeTitle from "../views/admin/AdminIncomeTitle.vue";
import AdminAdditionalIncome from "../views/admin/AdminAdditionalIncome.vue";
import AdminAddMember from "../views/admin/AdminAddMember.vue";
import AdminViewMember from "../views/admin/AdminViewMembers.vue";
import AdminEditMember from "../views/admin/AdminEditMembers.vue";
import AdminMembers from "../views/admin/AdminMembers.vue";
import AdminStaffs from "../views/admin/AdminStaffs.vue";
import AdminAddStaff from "../views/admin/AdminAddStaff.vue";
import AdminEditStaff from "../views/admin/AdminEditStaff.vue";
import AdminChurchUpdateProfile from "../views/admin/AdminChurchUpdateProfile.vue";
import AdminViewProfile from "../views/admin/AdminViewProfile.vue";
import AdminAllUsers from "../views/admin/AdminAllUsers.vue";
import GetAllAdmin from "../views/admin/GetAllAdmin.vue";
import AdminBulkImport from "../views/admin/AdminBulkImport.vue";

import AdminViewStaff from "../views/admin/AdminViewStaff.vue";
import AdminBranchMonthlyReports from "../views/admin/AdminBranchMonthlyReports.vue";
import AdminGlobalReports from "../views/admin/AdminGlobalReports.vue";
// import AdminEditServiceReport from "../views/admin/AdminEditServiceReport.vue"
// import AdminBranchYearlyReports from "../views/admin/AdminBranchYearlyReports.vue";
import AdminBranchRangeReport from "../views/admin/AdminBranchRangeReport.vue";
import AdminAddExpense from "../views/admin/AdminAddExpense.vue";
import AdminAddIncome from "../views/admin/AdminAddIncome.vue";
import AdminSupport from "../views/admin/AdminSupport.vue";
import EmailCommunication from "../views/admin/EmailCommunication.vue";
import EmailAutomation from "../views/admin/EmailAutomation.vue";
import SmsCommunication from "../views/admin/SmsCommunication.vue";
import FollowUpCommunication from "../views/admin/FollowUpCommunication.vue";
import FollowUpSms from "../views/admin/FollowUpSms.vue";
import AdminFirstTimers from "../views/admin/AdminFirstTimers.vue";
import AdminAddFirstTimers from "../views/admin/AdminAddFirstTimers.vue";
import AdminEditFirstTimers from "../views/admin/AdminEditFirstTimers.vue";
import AdminViewFirstTimers from "../views/admin/AdminViewFirstTimers.vue";
import AdminApprovedMonths from "../views/admin/AdminApprovedMonths.vue";
import AdminRoles from "../views/admin/AdminRoles.vue";
import AdminUsers from "../views/admin/AdminUsers.vue";
import AdminAddUsers from "../views/admin/AdminAddUsers.vue";
import AdminViewUser from "../views/admin/AdminViewUser.vue";
import AdminEditUser from "../views/admin/AdminEditUser.vue";
import AdminTransitionTemplate from "../views/admin/AdminTransitionTemplate.vue";





// Subscription Section

import AdminCurrentSubscription from "../views/Subscription/CurrentSubscription.vue";






Vue.use(VueRouter);

// const userDetail = JSON.parse(localStorage.getItem("user"));
// const userPermissions = userDetail.permission_names;

// console.log(userPermissions);

const routes = [
  // account section
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },

  {
    path: "/account/login",
    name: "Login",
    component: AdminLogin,
  },
  {
    path: "/account/signup",
    name: "sign up",
    component: AdminSignUp,
  },

  {
    path: "/account/forgot-password",
    name: "forgotten password",
    component: AdminForgottenPassword,
    meta: {},
  },
  // {
  //   path: "/account/change-password",
  //   name: "change password",
  //   component: ChangePassword,
  //   meta: {
  //     auth: true,
  //   },
  // },


  {
    path: "/account/welcome",
    name: "WelcomeAfterSignup",
    component: WelcomeAfterSignup,
  },
  

  {
    path: "/account/reset-password",
    name: "reset password",
    component: AdminResetPassword,
    meta: {},
  },

  // End of account section

  // the site admin
  {
    path: "",
    component: AdminView,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
        meta: {
          auth: true,
        },
      },

      // Branches
      {
        path: "branches",
        name: "AdminBranches",
        component: AdminBranches,
        meta: {
          auth: true,
        },
        // beforeEnter(to, from, next) {
          
        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {
            
        //     next();
        //   }
        // }
      },
      {
        path: "create-branch",
        name: "AdminCreateBranch",
        component: AdminCreateBranch,
        meta: {
          auth: true,
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "edit-branch/:branch_slug",
        name: "AdminEditBranch",
        component: AdminEditBranch,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "add-expense",
        name: "adminAddExpense",
        component: AdminAddExpense,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "add-income",
        name: "adminAddIncome",
        component: AdminAddIncome,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      // End of branch section

      // Pastors Section

      {
        path: "pastors",
        name: "AdminPastors",
        component: AdminHumanResources,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "addPastor",
        name: "AdminAddPastor",
        component: AdminAddPastor,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "view-pastor/:pastor_slug",
        name: "AdminViewPastor",
        component: AdminViewPastor,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "edit-pastor/:pastor_slug",
        name: "AdminEditPastor",
        component: AdminEditPastor,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      // End of Pastor Section

      // Service Section

      {
        path: "services",
        name: "AdminServices",
        component: AdminServices,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "create-service-report",
        name: "AdminCreateServiceReport",
        component: AdminCreateServiceReport,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "service-report",
        name: "AdminServiceReport",
        component: AdminServiceReport,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "member-demographics",
        name: "AdminMemberDemographics",
        component: AdminMemberDemographics,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },


      {
        path: "edit-service-report/:serviceReport_slug",
        name: "AdminEditServiceReport",
        component: AdminEditServiceReport,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "view-service-report/:serviceReport_slug",
        name: "AdminViewServiceReport",
        component: AdminViewServiceReport,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      // {
      //   path: "monthly-yearly-branch-report",
      //   name: "AdminMonthlyYearlyBranchReport",
      //   component: AdminMonthlyYearlyBranchReport,
      //   meta: {
      //     auth: true,
      //     // userType: "church",
      //   },
      // },

      // End of service section

      // AdminSubscriptionPlans
      {
        path: "subscription",
        name: "AdminCurrentSubscription",
        component: AdminCurrentSubscription,
        meta: {
          auth: true,
          // userType: "church",
        },
      },
      // End of Subscription



      // Expense section
      {
        path: "expenses",
        name: "AdminExpenses",
        component: AdminExpenses,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "expenses-title",
        name: "AdminExpenseTitle",
        component: AdminExpenseTitle,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "view-branch/:branch_slug",
        name: "AdminViewBranch",
        component: AdminViewBranch,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      // Member Section
      {
        path: "members",
        name: "AdminMembers",
        component: AdminMembers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "create-member",
        name: "AdminAddMember",
        component: AdminAddMember,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "view-member/:member_slug",
        name: "AdminViewMember",
        component: AdminViewMember,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "edit-member/:member_slug",
        name: "AdminEditMember",
        component: AdminEditMember,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "transition-template",
        name: "AdminTransitionTemplate",
        component: AdminTransitionTemplate,
        meta: {
          auth: true,
        },
      },
      {
        path: "first-timers",
        name: "AdminFirstTimers",
        component: AdminFirstTimers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "add-first-timers",
        name: "AdminAddFirstTimers",
        component: AdminAddFirstTimers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "edit-first-timer/:firstTimer_slug",
        name: "AdminEditFirstTimers",
        component: AdminEditFirstTimers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "view-first-timers/:firstTimer_slug",
        name: "AdminViewFirstTimers",
        component: AdminViewFirstTimers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      // End of Member Section

      // Staff Section
      {
        path: "staffs",
        name: "AdminStaff",
        component: AdminStaffs,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "create-staff",
        name: "AdminAddStaff",
        component: AdminAddStaff,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "view-staff/:staff_slug",
        name: "AdminViewStaff",
        component: AdminViewStaff,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "edit-staff/:staff_slug",
        name: "AdminEditStaff",
        component: AdminEditStaff,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      // End of Staff

      // Finance Section

      {
        path: "finance",
        name: "AdminFinance",
        component: AdminFinance,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "other-income",
        name: "AdminAdditionalIncome",
        component: AdminAdditionalIncome,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "income-title",
        name: "AdminIncomeTitle",
        component: AdminIncomeTitle,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report') || !userPermissions.includes('write_service_report') || !userPermissions.includes('write_income_report') || !userPermissions.includes('read_income_report') || !userPermissions.includes('write_expense_report') || !userPermissions.includes('read_expense_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      // End of Finance section

      //  Other Section



      {
        path: "update-profile/",
        name: "AdminChurchUpdateProfile",
        component: AdminChurchUpdateProfile,
        meta: {
          auth: true,
          // userType: "church",
        },
      },
      {
        path: "update-current-user",
        name: "AdminUpdateCurrentUser",
        component: AdminUpdateCurrentUser,
        meta: {
          auth:true,
        }
      },
      {
        path: "view-profile/",
        name: "AdminViewProfile",
        component: AdminViewProfile,
        meta: {
          auth: true,
          // userType: "church",
        },
      },
      {
        path: "global-report",
        name: "AdminGlobalReports",
        component: AdminGlobalReports,
        // redirect: {
        //   name: "AdminBranchYearlyReports",
        // },
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "branch-monthly-report",
        name: "AdminBranchMonthlyReports",
        component: AdminBranchMonthlyReports,
        // redirect: {
        //   name: "AdminBranchYearlyReports",
        // },
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      
      {
        path: "range-report",
        name: "adminBranchRangeReport",
        component: AdminBranchRangeReport,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_service_report')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      //communication
      {
        path: "email",
        name: "emailCommunication",
        component: EmailCommunication,
        meta: {
          auth: true,
        },
      },
      {
        path: "email-automation",
        name: "emailAutomation",
        component: EmailAutomation,
        meta: {
          auth: true,
        },
      },
      {
        path: "sms",
        name: "smsCommunication",
        component: SmsCommunication,
        meta: {
          auth: true,
        },
      },
      {
        path: "follow-up-communication",
        name: "followUpCommunication",
        component: FollowUpCommunication,
        meta: {
          auth: true,
        },
      },
      {
        path: "follow-up-sms",
        name: "followUpSms",
        component: FollowUpSms,
        meta: {
          auth: true,
        },
      },


      
      {
        path: "change-password",
        name: "AdminChangePassword",
        component: AdminChangePassword,
        meta: {
          auth: true,
        },
      },

      {
        path: "set-opening-balance",
        name: "AdminSetOpeningBalance",
        component: AdminSetOpeningBalance,
        meta: {
          auth: true,
        }
      },
      {
        path: "all-users",
        name: "AdminAllUsers",
        component: AdminAllUsers,
        meta: {
          auth: true,
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "all-admin",
        name: "GetAllAdmin",
        component: GetAllAdmin,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "roles",
        name: "AdminRoles",
        component: AdminRoles,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "users",
        name: "AdminUsers",
        component: AdminUsers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "add-users",
        name: "AdminAddUsers",
        component: AdminAddUsers,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "edit-user/:user_id",
        name: "AdminEditUser",
        component: AdminEditUser,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "users/:user_id",
        name: "AdminViewUser",
        component: AdminViewUser,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },
      {
        path: "member-bulkImport",
        name: "memberBulkImport",
        component: AdminBulkImport,
        meta: {
          auth: true,
          // userType: "church",
        },
        // beforeEnter(to, from, next) {

        //   if (!userPermissions.includes('read_pastors') || !userPermissions.includes('write_pastors') || !userPermissions.includes('read_members') || !userPermissions.includes('write_members')) {
        //     next(false);
        //   } else {

        //     next();
        //   }
        // }
      },

      {
        path: "approved-months",
        name: "AdminApprovedMonths",
        component: AdminApprovedMonths,
        meta: {
          auth: true,
          // userType: "church",
        },
      },
      {
        path: "support",
        name: "adminSupport",
        component: AdminSupport,
        meta: {
          auth: true,
          // userType: "church",
        },
      },
    ],
  },

  {
    path: "/server-error",
    name: "ServerError",
    component: ServerError,
  },
  {
    path: "/*",
    name: "PageNotFound",
    component: PageNotFound,
  },

  {
    path: "/activity-log",
    name: "ActivityLog",
    component: ActivityLog,
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  // const routeMetaUserType = to.meta.userType;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);

  // var userPermissions = user.permission_names;
  if (requiresAuth && !token && !user) {
    next({ name: "Login", query: { to: to.path } });
  } else {
    next();
  }

  if (
    requiresAuth &&
    token &&
    user
  ) {
    next();
  } else {
    next();
  }

  if (
    (requiresAuth && !token && !user && to.path == "/account/login") ||
    to.path == "/account/signup"
  ) {
    next();
  } else {
    next();
  }


  next();
});



export default router;
