<template>
  <smart-church-layout page="Branch Monthly Report">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" class="mt-7" /> -->

    <v-container fluid class="my-8">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="7">
          <v-card class="mt-12 rounded-lg text-capitalize p-5">
            <h3 class="primary--text font-weight-bold text-center">
              SmartChurch Monthly Report
            </h3>
            <v-row justify="center" class="mt-6">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedData.branch"
                  :items="branches"
                  required
                  dense
                  label="Branch"
                  item-value="id"
                  outlined
                  item-text="name"
                  @input="clearData"
                  return-object
                  :error-messages="monthlyReport_errors.branch"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <!-- <p class="mb-3 orange--text font-weight-bold">Please enter the first day of the month(1) followed by the selected month and year</p> -->
                <v-text-field
                  v-model="selectedData.year"
                  required
                  type="month"
                  label="Month and Year"
                  class="mb-0"
                  outlined
                  dense
                  @input="clearData"
                  :error-messages="monthlyReport_errors.year"
                ></v-text-field>
              </v-col>

              <v-btn
                color="primary"
                @click="getChurchMonthlyReport"
                :loading="loading"
                :disabled="loading"
                >Generate report</v-btn
              >
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="showMonthlyReport">
          <div id="pdf-content">
            <div>
              <p
                class="primary fs-3 mb-0 text-white p-2 text-center fw-bold text-uppercase"
              >
                {{ selectedData.branch.name }}
                {{ months.name }}
                Reports
              </p>

              <v-card class="text-capitalize p-3 mb-4">
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="fs-5">Number of Meetings</td>
                          <td class="fs-5">
                            {{
                              serviceData.service_reports_count != null
                                ? getFormattedAmount(
                                    serviceData.service_reports_count
                                  )
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-5">Highest Attendance</td>
                          <td class="fs-5">
                            {{
                              serviceData.highest_attendance != null
                                ? getFormattedAmount(
                                    serviceData.highest_attendance
                                  )
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-5">Lowest Attendance</td>
                          <td class="fs-5">
                            {{
                              serviceData.lowest_attendance != null
                                ? getFormattedAmount(
                                    serviceData.lowest_attendance
                                  )
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-5">Average Income</td>
                          <td class="fs-5">
                            ₦{{
                              serviceData.avg_income != null
                                ? getFormattedAmount(
                                    Math.ceil(serviceData.avg_income)
                                  )
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-5">Total First Timers</td>
                          <td class="fs-5">
                            {{
                              serviceData.first_timers != null
                                ? getFormattedAmount(serviceData.first_timers)
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-5">Total New Converts</td>
                          <td class="fs-5">
                            {{
                              serviceData.new_convert != null
                                ? getFormattedAmount(serviceData.new_convert)
                                : "0"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-5">Average Attendance</td>
                          <td class="fs-5">
                            {{
                              serviceData.avg_attendance != null
                                ? getFormattedAmount(
                                    Math.round(
                                      Number(serviceData.avg_attendance)
                                    )
                                  )
                                : "0"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card>
            </div>

            <div class="mt-0">
              <v-row>
                <v-col cols="12">
                  <h3
                    class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                  >
                    Income and Expense Chart
                  </h3>
                  <v-card class="p-3">
                    <div>
                      <bar-chart
                        ref="barChart"
                        :chart-data="{
                          labels: [selectedData.branch.name],
                          datasets: [
                            {
                              label: 'Income',
                              backgroundColor: '#3366CC',
                              data: [Number(incomeExpenseChartIncomeValue)],
                            },
                            {
                              label: 'Expenditure',
                              backgroundColor: '#880808',
                              data: [Number(incomeExpenseChartExpenseValue)],
                            },
                          ],
                        }"
                        :chartOptions="{
                          responsive: true,
                          maintainAspectRatio: false,
                          layout: {
                            padding: {
                              left: 40,
                              right: 40,
                              top: 40,
                              bottom: 40,
                            },
                          },
                        }"
                      />
                    </div>
                    <p class="text-center fw-bold fs-5 p-2">
                      You spent {{ percentageTotal ? percentageTotal : "0" }}%
                      of income on Expenditure
                    </p>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                  <h3
                    class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                  >
                    Income Chart
                  </h3>
                  <v-card class="p-1">
                    <div>
                      <pie-chart
                        ref="pieChart"
                        :chart-data="pieChartIncomeData"
                        :chartOptions="{
                          responsive: true,
                          maintainAspectRatio: false,
                          layout: {
                            padding: {
                              left: 40,
                              right: 40,
                              top: 40,
                              bottom: 40,
                            },
                          },
                        }"
                      />
                    </div>
                    <p class="text-center p-2 fw-bold fs-5">
                      This shows all Income for the Month of
                      {{ months.name }}
                    </p>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="6">
                  <div>
                    <h3
                      class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                    >
                      Expense Chart
                    </h3>
                    <v-card class="p-1">
                      <div>
                        <pie-chart
                          ref="pieChart"
                          :chart-data="pieChartExpenseData"
                          :chartOptions="{
                            responsive: true,
                            maintainAspectRatio: false,
                            layout: {
                              padding: {
                                left: 40,
                                right: 40,
                                top: 40,
                                bottom: 40,
                              },
                            },
                          }"
                        />
                      </div>
                      <p class="text-center p-2 fw-bold fs-5">
                        This shows all Expenditure for the Month of
                        {{ months.name }}
                      </p>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div>
              <v-row>
                <v-col cols="12" lg="6">
                  <h3
                    class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                  >
                    Sunday Attendance Chart
                  </h3>
                  <div>
                    <v-card class="p-1">
                      <div>
                        <line-chart
                          ref="lineChart"
                          :chart-data="sundayLineChartData"
                          :chartOptions="{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true,
                              },
                            },
                          }"
                        />
                      </div>
                    </v-card>
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <h3
                    class="primary text-white p-2 mb-0 text-center fw-bold mt-4 fs-3 text-uppercase"
                  >
                    Weekdays Attendance Chart
                  </h3>
                  <div>
                    <v-card class="p-1">
                      <div>
                        <line-chart
                          ref="lineChart"
                          :chart-data="weekdaysLineChartData"
                          :chartOptions="{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true,
                              },
                            },
                          }"
                        />
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div>
              <p
                class="primary fs-3 mb-0 text-white p-2 text-center fw-bold mt-4 text-uppercase"
              >
                {{ months.name }} Income Report
              </p>
              <v-card class="text-capitalize p-3 mb-4">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="fs-5 incomeKey text-uppercase fw-bold">
                          Opening Balance
                        </td>
                        <td
                          class="fs-5 incomeValue text-right"
                          :class="
                            openingBalance >= 0
                              ? 'positive-value'
                              : 'negative-value'
                          "
                        >
                          ₦{{
                            openingBalance
                              ? getFormattedAmount(openingBalance)
                              : "0"
                          }}
                        </td>
                      </tr>

                      <tr v-for="(value, key) in incomeChartData" :key="key">
                        <td class="incomeKey fs-5">{{ key }}</td>
                        <td class="text-right incomeValue fs-5">
                          {{value ? getFormattedAmount(value) : "0" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-uppercase fs-5">Total Income</td>
                        <td class="text-right fw-bold fs-5"> ₦{{ normalTotalIncome ? getFormattedAmount(normalTotalIncome) : "0" }}</td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-uppercase fs-5">
                         Grand Total Income
                        </td>
                        <td class="text-right fw-bold fs-5">
                          ₦{{
                            incomeExpenseChartIncomeValue
                              ? getFormattedAmount(
                                  incomeExpenseChartIncomeValue
                                )
                              : "0"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <p
                class="primary fs-3 mb-0 text-white p-2 text-center fw-bold mt-4 text-uppercase"
              >
                {{ months.name }} Expense Report
              </p>
              <v-card class="text-capitalize p-3 mb-4">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(value, key) in expenseChartData"
                        :key="key"
                      >
                        <td class="incomeKey fs-5">{{ key }}</td>
                        <td class="text-right incomeValue fs-5">
                          ₦{{
                            value
                              ? getFormattedAmount(value)
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-uppercase fs-5">
                          Total Expense
                        </td>
                        <td class="text-right fw-bold fs-5">
                          ₦{{
                            incomeExpenseChartExpenseValue
                              ? getFormattedAmount(
                                  incomeExpenseChartExpenseValue
                                )
                              : "0"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="incomeKey fs-5">Closing Balance</td>
                        <td
                          class="text-right incomeValue fs-5"
                          :class="
                            closingBalance >= 0
                              ? 'positive-value'
                              : 'negative-value'
                          "
                        >
                          ₦{{ getFormattedAmount(closingBalance) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>

            <p
              class="primary fs-3 mb-0 text-white p-2 text-center fw-bold text-uppercase"
            >
              {{ selectedData.branch.name }} - {{ months.name }} Service Reports
            </p>
            <template>
              <v-data-table
                :headers="headers"
                :items="serviceAttendanceStatistics"
                class="elevation-1"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.service_date`]="{ item }">
                  {{ getFormattedDate(item.service_date) }}
                </template>
                <template v-slot:[`item.total_attendance`]="{ item }">
                  {{ getTotalAttendance(item) }}
                </template>
                <template v-slot:[`item.total_income`]="{ item }">
                  ₦{{ getFormattedAmount(item.total_income) }}
                </template>
              </v-data-table>
            </template>
          </div>
          <div class="d-lg-flex justify-space-between d-block">
            <v-btn
              @click="reportStatus"
              :color="buttonColor"
              :loading="loading"
              :disabled="loading"
              v-if="
                canManageUsers ||
                canViewUsers ||
                canManageRoles ||
                canViewRoles ||
                noRoles
              "
              class="mt-4"
              >{{ buttonText }}</v-btn
            >
            <v-btn @click="downloadPDF" color="primary" class="fw-bold mt-4"
              >Download Report as PDF</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import {
  catchAxiosExceptions,
  getFormattedDate,
  getFormattedAmount,
  getTotalAttendance,
} from "../../__helpers/handelExceptions";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"
import BarChart from "../../components/charts/BarChart.vue";
import PieChart from "../../components/charts/PieChart.vue";
import LineChart from "../../components/charts/LineChart.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SmartChurchLayout,

    BarChart,
    PieChart,
    LineChart,
  },

  data() {
    return {
      headers: [
        {
          text: "Service Date",
          align: "start",
          sortable: false,
          value: "service_date",
        },
        // { text: "Service Day", value: "service_date" },
        { text: "Service Type", value: "service_type.name" },

        { text: "Service Topic", value: "topic" },
        { text: "Service Speaker", value: "speaker" },
        { text: "Male Attendance", value: "male_attendance" },
        { text: "Female Attendance", value: "female_attendance" },

        { text: "Children Attendance", value: "children_attendance" },
        { text: "Teenage Attendance", value: "teenager_attendance" },
        { text: "New Convert", value: "new_convert" },
        { text: "First Timer", value: "first_timers" },
        { text: "Total Attendance", value: "total_attendance" },
        { text: "Total Service Income", value: "total_income" },
      ],

      serviceAttendanceStatistics: [],
      serviceData: [],
      incomeData: [],
      incomeArray: [],

      expenseData: [],

      showMonthlyReport: false,
      percentageTotal: "",

      overlay: true,

      years: [],

      months: [],

      monthlyReport_errors: [],

      selectedData: {
        branch: null,
        // month: null,
        year: null,
      },

      userDetails: [],
      userPermissionNames: [],

      showReport: false,
      loading: false,

      branches: [],
      monthlyReports: [],
      serviceReports: [],

      incomeExpenseChartBranchName: [],
      incomeExpenseChartIncomeValue: 0, //Grand Total Income
      normalTotalIncome: 0, //Normal total Income
      incomeExpenseChartExpenseValue: 0,

      pieChartIncomeData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      pieChartExpenseData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },

      sundayLineChartData: {
        labels: [],
        datasets: [
          {
            label: "Sunday",
            backgroundColor: "blue",
            data: [],
            borderColor: "#244AFB",
          },
        ],
      },

      weekdaysLineChartData: {
        labels: [],
        datasets: [
          {
            label: "Week Days",
            backgroundColor: "#EA5F82",
            data: [],
            borderColor: "#EA5F82",
          },
        ],
      },

      openingBalance: 0,
      closingBalance: 0,

      incomeChart: [],
      incomeChartData: [],
      incomeValues: [],

      expenseChart: [],
      expenseChartData: [],
      expenseValues: [],

      attendanceData: [],
      attendanceChartData: [],

      isLoading: false,
      // mountedError: null,
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      buttonText: "",
      buttonColor: "",
      approval_status: "",
    };
  },

  computed: {
    ...mapGetters([
      "canManageServiceReport",
      "canViewServiceReport",
      "canViewIncomeReport",
      "canManageIncomeReport",
      "canManageExpenseReport",
      "canViewExpenseReport",
      "canManageReports",
      "canViewReports",
      "noRoles",
      "isAdmin",
      "canManageUsers",
      "canViewUsers",
      "canManageRoles",
      "canViewRoles",
    ]),

    // months() {
    //   const date = new Date(this.selectedData.year);
    //   const monthNumber = date.getMonth() + 1; // add 1 because getMonth() returns zero-based month
    //   const monthName = date.toLocaleString("default", { month: "long" });
    //   return {
    //     number: monthNumber,
    //     name: monthName,
    //   };
    // },
    // year() {
    //   const date = new Date(this.selectedData.year);
    //   return date.getFullYear();
    // },
    checkIfIncomeExpenseIsPresent() {
      return this.incomeValues.length > 0 || this.expenseValues.length > 0;
    },
    checkIfAttendanceIsPresent() {
      return this.attendanceData.length > 0;
    },
  },

  watch: {
    "selectedData.year": function (newVal, oldVal) {
      this.updateMonths(newVal);
      this.updateYear(newVal);
    },
    // "months.name": function (newVal, oldVal) {
    //   this.months.name = newVal;
    // },
    // "months.number": function (newVal, oldVal) {
    //   this.months.number = newVal;
    // }
  },

  methods: {
    async initMonthlyReport() {
      this.isLoading = true;

      try {
        const user = this.$store.state.userDetail.user;
        this.branches = user.branches;
        this.isLoading = false;
        this.overlay = false;
      } catch (error) {
        this.isLoading = false;
        this.overlay = false;

        catchAxiosExceptions(error);
      }
    },

    updateMonths(year) {
      const date = new Date(year);
      const monthNumber = date.getMonth() + 1; // add 1 because getMonth() returns zero-based month
      const monthName = date.toLocaleString("default", { month: "long" });
      this.months = {
        number: monthNumber,
        name: monthName,
      };
    },

    updateYear(year) {
      const date = new Date(year);
      this.years = date.getFullYear();
    },

    updateBarChart() {
      this.$refs.barChart.update();
    },
    updatePieChart() {
      this.$refs.pieChart.update();
    },
    updateLineChart() {
      this.$refs.lineChart.update();
    },

    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    getFormattedAmount(amount) {
      return getFormattedAmount(amount);
    },

    getChurchMonthlyReport() {
      this.loading = true;
      this.$http
        .get(
          `admin/churchMonthlyReport?branch_id=${this.selectedData.branch.id}&month=${this.months.number}&year=${this.years}`
        )
        .then((response) => {
          const data = response.data.data;
          console.log(response.data);
          this.openingBalance = data.openingBalance;
          this.serviceAttendanceStatistics = data.services;
          this.attendanceData = data.services;
          this.incomeData = data.incomes;
          this.expenseData = data.expenses;
          this.serviceData = data.service_data;

          this.loading = false;
          this.showMonthlyReport = true;

          this.incomeChartData = this.transformDataToChartData(
            this.incomeData,
            "income_title"
          );
          // console.log(this.incomeChartData);
          this.setPieChartData(this.pieChartIncomeData, this.incomeChartData);

          this.expenseChartData = this.transformDataToChartData(
            this.expenseData,
            "expense_title"
          );
          console.log(this.expenseChartData)
          this.setPieChartData(this.pieChartExpenseData, this.expenseChartData);
          this.normalTotalIncome = this.serviceData.total_income
          this.incomeExpenseChartIncomeValue =
            this.serviceData.total_income + Number(this.openingBalance);
          this.incomeExpenseChartExpenseValue = this.serviceData.total_expense;
          this.percentageTotal = Math.floor(
            (this.incomeExpenseChartExpenseValue /
              this.incomeExpenseChartIncomeValue) *
              100
          );
          this.closingBalance =
            this.incomeExpenseChartIncomeValue -
            this.incomeExpenseChartExpenseValue;

          if (
            this.serviceAttendanceStatistics[0].approval_status === "pending"
          ) {
            this.buttonText = `Approve ${this.months.name} Report`;
            this.buttonColor = "green";
          } else {
            this.buttonText = `Disapprove ${this.months.name} Report`;
            this.buttonColor = "red";
          }

          this.attendanceChartData = this.handleServiceAttendanceData(
            this.attendanceData
          );
          // this.setLineChartData();
        })
        .catch((error) => {
          this.loading = false;
          catchAxiosExceptions(error);
        });
    },

    transformDataToChartData(dataArray, titleKey) {
      return dataArray
        .map((item) => [item[titleKey].name, Number(item.amount)])
        .reduce((acc, [name, value]) => {
          acc[name] = (acc[name] || 0) + value;
          return acc;
        }, {});
    },
    setPieChartData(chartData, transformedData) {
      chartData.labels = Object.keys(transformedData);
      chartData.datasets[0].data = Object.values(transformedData);
      chartData.datasets[0].backgroundColor = this.generateBackgroundColors(
        chartData.labels.length
      );
    },

    handleServiceAttendanceData(dataArray) {
      let attendanceMap = {
        Sunday: {},
        Weekdays: {},
      };

      dataArray.forEach((attendance) => {
        let dateKey = getFormattedDate(attendance.service_date);

        if (attendance.service_day === "Sunday") {
          attendanceMap.Sunday[dateKey] =
            (attendanceMap.Sunday[dateKey] || 0) + attendance.total_attendance;
          console.log(attendanceMap.Sunday);
        } else {
          attendanceMap.Weekdays[dateKey] =
            (attendanceMap.Weekdays[dateKey] || 0) +
            attendance.total_attendance;
        }
      });
      // console.log(attendanceMap.Sunday);
      // console.log(attendanceMap.Weekdays);

      // Sort the keys (dates) and map to chart data
      this.sundayLineChartData.labels = Object.keys(attendanceMap.Sunday).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      this.sundayLineChartData.datasets[0].data =
        this.sundayLineChartData.labels.map(
          (date) => attendanceMap.Sunday[date]
        );

      this.weekdaysLineChartData.labels = Object.keys(
        attendanceMap.Weekdays
      ).sort((a, b) => new Date(a) - new Date(b));
      this.weekdaysLineChartData.datasets[0].data =
        this.weekdaysLineChartData.labels.map(
          (date) => attendanceMap.Weekdays[date]
        );

      // console.log(this.sundayLineChartData.datasets[0].data);
    },

    // setLineChartData(chartData, transformedData) {
    //   chartData.labels = Object.keys(transformedData);
    //   chartData.datasets[0].data = Object.values(transformedData);
    // },

    downloadPDF() {
      const pdf = new jsPDF("p", "mm", "a4");

      html2canvas(document.getElementById("pdf-content")).then((canvas) => {
        const imgData = canvas.toDataURL("image/JPEG");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("document.pdf");
      });
    },

    generateBackgroundColors(count) {
      const backgroundColors = [];
      // Generate a random color for each label
      for (let i = 0; i < count; i++) {
        const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        backgroundColors.push(color);
      }
      return backgroundColors;
    },

    getTotalAttendance(report) {
      return getTotalAttendance(report);
    },

    clearData() {
      this.showMonthlyReport = false;
      // this.updateBarChart();
      // this.updatePieChart();
      // this.updateLineChart();
      this.attendanceData = [];
      this.serviceAttendanceStatistics = [];

      this.sundayLineChartData.labels = [];
      this.weekdaysLineChartData.labels = [];

      this.sundayLineChartData.datasets[0].data = [];
      this.weekdaysLineChartData.datasets[0].data = [];

      this.pieChartIncomeData.datasets[0].data = [];
      this.pieChartExpenseData.datasets[0].data = [];
      this.pieChartIncomeData.labels = [];
      this.pieChartExpenseData.labels = [];

      // this.buttonText = "";
      // this.buttonColor = "white";
    },

    // getMonthlyReport() {

    // },

    reportStatus() {
      this.loading = true;
      const item = this.serviceAttendanceStatistics[0];
      if (item.approval_status === "pending") {
        this.$http
          .get(
            `admin/approveReports?branch_id=${this.selectedData.branch.id}&month=${this.months.number}&year=${this.years}`
          )
          .then((response) => {
            console.log(response.data);
            this.snackbar.status = true;
            this.snackbar.msg = response.data.message;
            this.snackbar.color = "green";
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (item.approval_status === "approved") {
        this.$http
          .get(
            `admin/unApproveReports?branch_id=${this.selectedData.branch.id}&month=${this.months.number}&year=${this.years}`
          )
          .then((response) => {
            console.log(response.data);
            this.snackbar.status = true;
            this.snackbar.msg = response.data.message;
            this.snackbar.color = "green";
            this.loading = false;
          })
          .catch((error) => {
            // console.error(error);
            catchAxiosExceptions(error);
          });
      } else {
        return item.approval_status;
      }

      this.getChurchMonthlyReport();
    },
  },

  created() {
    this.userDetails = this.$store.state.userDetail.user;
    this.$store.commit("allPermissions");
  },

  mounted() {
    this.initMonthlyReport();
  },
};
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}

.positive-value {
  color: green;
}

.negative-value {
  color: red;
}

.w-80 {
  width: 80%;
}
.fs-7 {
  font-size: 3rem;
}
.border-base {
  border-color: #fce4ec;
}

.v-icon {
  color: #c73438;
}
.downloadBtn {
  display: flex;
  justify-content: justify-end;
}

/* .v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.v-text-field::-moz-focus-inner {
  background-color: red !important;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
} */
</style>
