<template>
  <v-container fluid fill-height d-background>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <div class="d-flex justify-center">
          <v-img src="@/assets/500 Server error.jpg" max-width="350" max-height="350"></v-img>
        </div>
        <div class="text-center">
          <h4>Ooops! Sorry, Please try again later</h4>
          <v-btn :to="{ name: getUsertype }" color="primary" class="mt-2">
            Go back</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    getUsertype() {
      let user = localStorage.getItem("user");
      let routeName = "Login";
      if (user) {
        routeName = "AdminDashboard";
      }
      return routeName;
    },
  },

  mounted() { },
};
</script>

<style scoped>
.d-background {
  background: #eedddd;
}
</style>