<template>
  <smart-church-layout page="Human Resources">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
      <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container  class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminBranches' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="viewBranch"
            >
              Fetch again
            </v-btn>
          </div>
          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="
                  primary--text
                  font-weight-bold
                  d-flex
                  justify-center
                  mb-5
                "
              >
                view Branch
              </h2>
              <v-img
                src="@/assets/Ellipse-7.png"
                max-height="120"
                max-width="120"
                class="d-block mx-auto"
              ></v-img>
              <!-- <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ branch_items.first_name }}</span>
                <span class="me-3">{{ branch_items.last_name }}</span>
              </h2> -->
              <v-row class="mt-6 primary--text pa-4">
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ branch_items.phone }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="branch"><v-icon>mdi-home</v-icon>Branch</label>
                  <p>{{ branch_items.name }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ branch_items.email }}</p>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="address"><v-icon>mdi-home</v-icon>Address</label>
                  <p>{{ branch_items.address }}</p>
                </v-col> -->
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="country"><v-icon>mdi-map</v-icon>Country</label>
                  <p>{{ branch_items.country }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="state"><v-icon>mdi-map</v-icon>State</label>
                  <p>{{ branch_items.state }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-map</v-icon>City</label>
                  <p>{{ branch_items.city }}</p>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <label for="city"><v-icon>mdi-home</v-icon>Address</label>
                  <p>{{ branch_items.address }}</p>
                </v-col>

                <v-btn
                  block
                  :to="{
                    name: 'AdminEditBranch',
                    params: { branch_slug: routeParamSlug },
                  }"
                  color="primary"
                  >Edit Branch</v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { catchAxiosExceptions } from "../../__helpers/handelExceptions";
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"


export default {
  components: { SmartChurchLayout },

  data() {
    return {
      branch_items: null,
      overlay: true,

      response: "",
      branch_items_errors: {},

      routeParamSlug: this.$route.params.branch_slug,

      isLoading: false,
      mountedError: null,
    };
  },

  methods: {
    viewBranch() {
      this.isLoading = true;

      this.$http
        .get("admin/branches/" + this.routeParamSlug)
        .then((response) => {
          this.branch_items = response.data.data;

          this.isLoading = false;
          this.overlay = false;
        })
        .catch((error) => {
          this.mountedError = error.response.data.message;
          this.overlay = false;
          this.isLoading = false;

          catchAxiosExceptions(error);
        });
    },
  },

  mounted() {
    this.viewBranch();
  },
};
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
