import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/components/plugins/vuetify";
import axios from "@/components/plugins/axios";
import Vue2Editor from "vue2-editor";
// import VueGoogleCharts from 'vue-google-charts'

Vue.prototype.$http = axios;
Vue.use(Vue2Editor);

// Vue.use(VueGoogleCharts)

Vue.config.productionTip = false;
Vue.use(store);

new Vue({
  router,
  store,
  vuetify,
  Vue2Editor,
  render: (h) => h(App),
}).$mount("#app");
