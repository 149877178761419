<template>
  <smart-church-layout page="Pastors">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container fluid  class="my-8">
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'AdminPastors' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initAddPastor"
            >
              Fetch again
            </v-btn>
          </div>

          <v-card
            class="d-flex justify-center mt-12 rounded-lg text-capitalize"
          >
            <v-form class="my-6 pa-13" ref="form" @submit.prevent="addPastor">
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                add new pastor
              </h2>

              <v-row class="mt-6">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    id="fname"
                    label="First Name"
                    v-model="add_pastor.first_name"
                    :error-messages="add_pastor_errors.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    id="lname"
                    label="Last Name"
                    v-model="add_pastor.last_name"
                    :error-messages="add_pastor_errors.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    id="email"
                    v-model="add_pastor.email"
                    :error-messages="add_pastor_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    label="Phone Number"
                    id="phone"
                    v-model="add_pastor.phone"
                    :error-messages="add_pastor_errors.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="add_pastor.branch_id"
                    :items="branches"
                    dense
                    label="Branch"
                    id="branch_id"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="add_pastor_errors.branch_id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="date"
                    outlined
                    dense
                    label="Date Of Birth"
                    v-model="add_pastor.date_of_birth"
                    :error-messages="add_pastor_errors.date_of_birth"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="add_pastor.role"
                    :items="roles"
                    dense
                    label="Roles"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="add_pastor_errors.role"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Password"
                    id="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-model="add_pastor.password"
                    :error-messages="add_pastor_errors.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Confirm Password"
                    id="confirm_password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    v-model="add_pastor.confirm_password"
                    :error-messages="add_pastor_errors.confirm_password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="add_pastor.is_suspended"
                    label="Is this pastor active?"
                  ></v-switch>
                </v-col>

                <div
                  class="alert alert-danger mt-5 text-center"
                  v-if="add_pastor_errors.length > 1"
                >
                  {{ add_pastor_errors }}
                </div>
              </v-row>
              <v-btn
                type="submit"
                depressed
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                color="primary white--text"
                >Create</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      items: ["male", "female"],
      roles: [
        "Admin",
        "Branch Head",
        "Branch Assistant",
        "HR",
        "Finance Head",
        "Viewer",
      ],
      add_pastor: {
        logo: "",
        first_name: "",
        last_name: "",
        sex: "",
        date_of_birth: "",
        country: "",
        nationality: "",
        state: "",
        city: "",
        branch: "",
        branch_id: "",
        role: "",
        email: "",
        phone: "",
        status: "",
        role_id: "",
        is_suspended: false,
        address: "",
        next_of_kin: "",
        password: "",
        confirm_password: "",
        response: "",
        error: "",
      },

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      isSubmitted: false,

      show1: false,
      show2: false,
      overlay: true,

      churches: [],

      branches: [],

      add_pastor_errors: {},

      isLoading: false,
      mountedError: null,
    }
  },

  methods: {
    async initAddPastor() {
      this.isLoading = true

      try {
        // const allBranch = await this.$http.get("admin/getBranches")
        // const allRoles = await this.$http.get("admin/roles")
        const user = this.$store.state.userDetail.user;
         
        // this.roles = allRoles.data.data
        this.branches = user.branches;

        this.isLoading = false
        this.overlay = false
      } catch (error) {
        // this.mountedError = error.response.data.message
        this.isLoading = false
        this.overlay = false

        catchAxiosExceptions(error)
      }
    },

    addPastor() {
      this.add_pastor_errors = ""

      this.isSubmitted = true

      if (this.add_pastor.confirm_password !== this.add_pastor.password) {
        this.add_pastor_errors = "Password does not match"
      } else {
        this.$http
          .post("admin/pastors", this.add_pastor)
          .then((response) => {
            this.isSubmitted = false

            this.snackbar.status = true
            this.snackbar.msg = response.data.message
            this.snackbar.color = "green"

            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0

            this.$refs.form.reset()
          })
          .catch((error) => {
            this.snackbar.status = true
            this.snackbar.msg = error.response.data.message
            this.snackbar.color = "red"

            this.isSubmitted = false
            this.add_pastor_errors = error.response.data.errors

            catchAxiosExceptions(error)
            if (error.response.status === 402) {
              alert(
                "Please upgrade your Subscription Plan to enjoy unlimited access. Thank You!"
              )
              this.$refs.form.reset()
            }
          })
      }
    },
  },

  mounted() {
    this.initAddPastor()
  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}

.v-icon {
  color: #c73438;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.v-text-field::-moz-focus-inner {
  background-color: red !important;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
}
</style>
