<template>
  <div v-if="showWidget">
    <div id="whatsapp-widget"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showWidget: false
    };
  },
  mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
    recaptchaScript.async = true;
    document.body.appendChild(recaptchaScript);
    recaptchaScript.onload = () => {
    var wa_btnSetting = {
          btnColor: "#16BE45",
          ctaText: "WhatsApp Us",
          cornerRadius: 40,
          marginBottom: 20,
          marginLeft: 20,
          marginRight: 20,
          btnPosition: "right",
          whatsAppNumber: "08077544056",
          welcomeMessage: "HI!\n\nI want to find out about SmartChurch!",
          zIndex: 999999,
          btnColorScheme: "light",
        };
        _waEmbed(wa_btnSetting);
    }
    if (recaptchaScript) {
      recaptchaScript.remove()
    }
    
  },
  // activated() {
  //   // Show the WhatsApp widget button when the component is activated
  //   this.showWidget = true;
  // },
  // deactivated() {
  //   // Hide the WhatsApp widget button when the component is deactivated
  //   this.showWidget = false;
  // }
};
</script>

<style>

</style>