<template>
  <smart-church-layout page="View Profile">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
     
      <v-row align="center" justify="center" class="mt-12">
        <v-col cols="12" sm="10" md="6" lg="6">
          
          <v-card class="pa-6 text-capitalize rounded-lg">
            <v-form>
              <h2
                class="primary--text font-weight-bold d-flex justify-center mb-5"
              >
                 Church Details
              </h2>
              <v-img
              :src="getDetail.logo"
              class="mx-auto rounded-circle pa-6"
              max-height="120"
              max-width="120"
              >
              </v-img>
              <h2 class="primary--text d-flex justify-center mt-4">
                <span class="me-3">{{ getDetail.first_name }}</span>
                <span class="me-3">{{ getDetail.last_name }}</span>
              </h2>
              <v-row class="mt-6 primary--text pa-4">
                <v-col cols="6">
                  <label for="phone"
                    ><v-icon color="primary">mdi-phone</v-icon>phone
                    number</label
                  >
                  <div>{{ getDetail.phone }}</div>
                </v-col>
                

                <v-col cols="6">
                  <label for="email"><v-icon>mdi-email</v-icon>Email</label>
                  <p>{{ getDetail.email }}</p>
                </v-col>
                
                <v-col cols="6">
                  <label for="branch"
                    ><v-icon>mdi-account</v-icon>Gender</label
                  >
                  <p>{{ getDetail.gender }}</p>
                </v-col>

                <!-- <v-col cols="6">
                  <label for="country"><v-icon>mdi-map</v-icon>Country</label>
                  <p>{{ getDetail.country }}</p>
                </v-col> -->
                <v-col cols="6">
                  <label for="state"><v-icon>mdi-map</v-icon>State</label>
                  <p>{{ getDetail.state }}</p>
                </v-col>

                <v-col cols="6">
                  <label for="city"><v-icon>mdi-map</v-icon>City</label>
                  <p>{{ getDetail.city }}</p>
                </v-col>

                <v-col cols="6">
                  <label for="city"><v-icon>mdi-home</v-icon>Address</label>
                  <p>{{ getDetail.address }}</p>
                </v-col>

                <!-- <v-btn
                  block
                  :to="{
                    name: 'AdminUpdateProfile',
                  }"
                  color="primary"
                  >Edit Profile</v-btn
                > -->
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"

import { catchAxiosExceptions } from "../../__helpers/handelExceptions"

export default {
  components: { SmartChurchLayout },

  data() {
    return {
      getDetail: [],
      overlay: true,

      getDetails_errors: {},

      isLoading: false,
     user: this.$store.state.userDetail.user,
    }
  },

  methods: {
    viewAdminPastor() {
     
      this.$http
        .get("churches/" + this.user.church.slug)
        .then((response) => {
          this.getDetail = response.data.data
          // <!-- console.log(this.getDetail); -->

          this.isLoading = false
          this.overlay = false
        })
        .catch((error) => {
          this.getDetail_errors = error.response.data.message
          catchAxiosExceptions(error)

        })
    },
  },

  mounted() {
    this.viewAdminPastor()
  },
}
</script>

<style scoped>
.v-icon {
  color: #c23d41;
}
label {
  margin-bottom: 0.5em;
}
</style>
