<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      width="320"
      class="shadow-sm navigationDrawer"
    >
      <a href="https://smartchurch.ng/" target="_blank">
        <v-img
          src="@/assets/smartchurch-logo.png"
          max-height="180"
          max-width="180"
          class="d-block mx-auto mt-5"
        ></v-img>
      </a>

      <v-list class="mt-7">
        <v-list-item link :to="{ name: 'AdminDashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Reports Section -->
        <v-list-group prepend-icon="mdi-file-chart-outline" v-if="canManageServiceReport || canViewServiceReport || canViewIncomeReport || canManageIncomeReport || canViewExpenseReport || canManageExpenseReport || canManageReports || canViewReports || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Reports</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminCreateServiceReport' }"
            v-if="canManageServiceReport || noRoles || canManageReports || canManageIncomeReport || canManageExpenseReport"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Create Service Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminServices' }"
             v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Service</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminServiceReport' }"
            v-if="canManageServiceReport || canViewServiceReport || canViewIncomeReport || canManageIncomeReport || canViewExpenseReport || canManageExpenseReport || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Service Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminBranchMonthlyReports' }"
            v-if=" canViewIncomeReport|| canManageIncomeReport || canManageExpenseReport || canViewExpenseReport || canManageServiceReport || canViewServiceReport || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Branch Monthly Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'adminBranchRangeReport' }"
             v-if=" canViewIncomeReport || canManageIncomeReport || canManageExpenseReport || canViewExpenseReport || canManageServiceReport || canViewServiceReport || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Range Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminGlobalReports' }"
            v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Global Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminSetOpeningBalance' }"
            v-if="canViewIncomeReport || canManageIncomeReport || canManageExpenseReport || canViewExpenseReport || canManageServiceReport || canViewServiceReport || noRoles"
          >
            <v-list-item-icon>
             <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Initial Opening Balance</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminApprovedMonths' }"
            v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Approved Months</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Personnel Management -->
        <v-list-group prepend-icon="mdi-account-multiple-outline"  v-if="canViewMembers || canManageMembers || canViewStaffs || canManageStaffs || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Personnel Management</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminMembers' }"
            v-if="canViewMembers || canManageMembers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Members</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminAddMember' }"
             v-if="canManageMembers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Member</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'memberBulkImport' }"
             v-if="canManageMembers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Import Members</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminMemberDemographics' }"
            v-if="canViewMembers || canManageMembers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Member Demographics</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminStaff' }"
            v-if="canViewStaffs || canManageStaffs || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Staff</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminAddStaff' }"
            v-if="canManageStaffs || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Staff</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminFirstTimers' }"
            v-if="canViewMembers || canManageMembers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>First Timers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Branches -->
        <v-list-group prepend-icon="mdi-home-city-outline" v-if="canManageUsers || canManageRoles || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Branches</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminBranches' }"
           v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-home-city-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>View Branches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Finance -->
        <v-list-group prepend-icon="mdi-cash-multiple" v-if="canManageIncomeReport || canManageExpenseReport || canViewIncomeReport || canViewExpenseReport || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Finance</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminIncomeTitle' }"
            v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Income Title</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminAdditionalIncome' }"
            v-if="canManageIncomeReport || canManageExpenseReport || canViewIncomeReport || canViewExpenseReport || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Income</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminExpenseTitle' }"
            v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Expense Title</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminExpenses' }"
            v-if="canManageIncomeReport || canManageExpenseReport || canViewIncomeReport || canViewExpenseReport || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Expenses</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- communication -->
        <v-list-group prepend-icon="mdi-forum-outline" v-if="canManageUsers || canManageRoles || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Communication</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'emailCommunication' }"
           v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Email Communication</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'smsCommunication' }"
           v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-message-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sms Communication</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'followUpCommunication' }"
           v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>First Timers Communication</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'followUpSms' }"
           v-if="canManageUsers || canManageRoles || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-message-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Follow Up Sms</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>


        <!-- Manage Users -->
        <v-list-group prepend-icon="mdi-account-multiple-outline" v-if="canManageRoles || canViewRoles || canManageUsers || canViewUsers || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Manage Users</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminRoles' }"
            v-if="canManageRoles || canViewRoles || canManageUsers || canViewUsers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Roles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminUsers' }"
            v-if="canManageRoles || canViewRoles || canManageUsers || canViewUsers || noRoles"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminAllUsers' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Get All Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'GetAllAdmin' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Get All Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>

        <!-- Setup Menu -->
        <v-list-group prepend-icon="mdi-account-multiple-outline" v-if="canManageUsers || canManageRoles || noRoles">
          <template v-slot:activator>
            <v-list-item-title>Setup Menu</v-list-item-title>
          </template>

          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminCreateBranch' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-home-city-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Branch</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminServices' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Service</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminIncomeTitle' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Income Title</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminExpenseTitle' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Expense Title</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminRoles' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add Roles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminAddUsers' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Add User</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            color="primary"
            class="ms-4"
            :to="{ name: 'AdminTransitionTemplate' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Transition Template</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- <v-sheet class="sheet"> </v-sheet> -->
      <v-list class="mt-8">
        <v-list-item-group color="primary" class="primary--text">
          <v-list-group prepend-icon="mdi-account-circle-outline" v-if="canManageUsers || canManageRoles || noRoles">
            <template v-slot:activator>
              <v-list-item-title>Manage Account</v-list-item-title>
            </template>

            <v-list-item
              color="primary"
              class="ms-4"
              :to="{ name: 'AdminCurrentSubscription' }"
            >
              <v-list-item-icon>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Subscription</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item color="primary" :to="{ name: 'adminSupport' }">
            <v-list-item-icon>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Support</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="primary" @click="logOut">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat color="background">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        icon
        @click.stop="mini = !mini"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-bold">{{ page }} </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn @click="toggleTheme" fab x-small inset
        ><v-icon>mdi-brightness-4</v-icon></v-btn
      >
      <v-menu offset-y bottom :close-on-content-click="closeOnContentClick">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex">
            <v-avatar class="mx-3 mt-3">
              <img :src="user.image" alt="Logo" />
            </v-avatar>
            <div class="acct-text">
              <p>{{ getFullName() }}</p>
            </div>
            <div class="mt-3"><v-icon right> mdi-chevron-down </v-icon></div>
          </div>
        </template>
        <v-list>
          <v-list-item link :to="{ name: 'AdminChurchUpdateProfile' }" v-if="canManageUsers || canManageRoles || noRoles">
            <v-list-item-title>
              <v-icon left>mdi-account-outline</v-icon>
              Update Church Account</v-list-item-title
            >
          </v-list-item>
          <v-list-item link :to="{ name: 'AdminViewProfile' }" v-if="canManageUsers || canManageRoles || noRoles">
            <v-list-item-title>
              <v-icon left>mdi-account-outline</v-icon>
              View Church Profile</v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item link :to="{name: 'AdminUpdateCurrentUser'}" class="d-none">
            <v-list-item-title>
              <v-icon left>mdi-account-outline</v-icon>
              Update User Account</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item link :to="{ name: 'AdminChangePassword' }">
            <v-list-item-title>
              <v-icon left>mdi-fingerprint</v-icon>
              Change password
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="logOut">
              <v-icon left>mdi-logout</v-icon>
              Log Out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="background">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: null,

      search: null,

      model: 0,
      mini: true,
      menus: require("../../menu").default,

      closeOnContentClick: true,

      offset: true,
      getDetail: [],
      user: this.$store.state.userDetail.user,

      userPermissionNames: [],
    };
  },

  props: {
    page: {
      type: String,
      default: "Dashboard",
    },
  },

  computed: {
    ...mapGetters([
      "canManageServiceReport",
      "canViewServiceReport",
      "canViewIncomeReport",
      "canViewIncomeTitles",
      "canManageIncomeTitles",
      "canManageIncomeReport",
      "canViewExpenseReport",
      "canManageExpenseReport",
      "canManageReports",
      "canViewReports",
      "canViewMembers",
      "canManageMembers",
      "canViewStaffs",
      "canManageStaffs",
      "canViewPastors",
      "canManagePastors",
      "noRoles",
      "canViewRoles",
      "canManageRoles",
      "canViewAnnouncement",
      "canManageAnnouncement",
      "canManageBirthday",
      "canViewUsers",
      "canManageUsers",
      "isAdmin",
    ]),
  },

  methods: {
    logOut() {
      this.$store.commit("removeUserDetail");
      this.$store.commit("removeAllPermissions");
      this.$vuetify.theme.dark = false;
      this.$router.push({ name: "Login" });
      localStorage.removeItem("theme");
      // localStorage.removeItem("checkedRatingForm");
    },

    toggleTheme() {
      // Toggle the theme value
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    getFullName() {
      let firstName = this.userUseable ? this.userUseable.first_name : "";
      let lastName = this.userUseable ? this.userUseable.last_name : "";

      let fullName = firstName + " " + lastName;
      return fullName;
    },

    // viewAdminPastor() {

    //   this.$http
    //     .get("churches/" + this.user.slug)
    //     .then((response) => {
    //       this.getDetail = response.data.data
    //       // console.log(this.getDetail);

    //       this.isLoading = false
    //       this.overlay = false
    //     })
    //     .catch((error) => {
    //       this.getDetail_errors = error.response.data.message
    //       catchAxiosExceptions(error)

    //     })
    // },
  },

  watch: {
    "$vuetify.theme.dark"(newVal) {
      localStorage.setItem("theme", newVal);
    },
  },
  mounted() {
    // this.viewAdminPastor()
    const storedTheme = localStorage.getItem("theme");
    this.$vuetify.theme.dark = storedTheme === "true" || false;
    // console.log(this.user);
  },

  created() {
    this.$store.commit("allPermissions");
  },
};
</script>

<style scoped>
.mt-7 {
  margin-top: 3.3rem !important;
}
.mt-8 {
  margin-top: 8rem !important;
}

.acct-text p {
  font-weight: 500;
  font-size: 14px;
  color: #4a4856;
  margin-top: 20px !important;
}
</style>
