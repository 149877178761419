<template>
  <smart-church-layout>
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
     <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container  class="my-8">
      <v-row>
        <v-col>

          <v-data-table
            :headers="headers"
            :items="allAdmin"
            class="elevation-1"
          >
          <template v-slot:top>
              <v-dialog v-model="removeAdmin" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to remove this Admin?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDelete">No</v-btn>
                    <v-btn color="primary" text @click="confirmRemoveAdmin"
                      >YES</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              
              <!-- <v-btn  small elevation="0" color="primary" class="mr-2 " @click="upgradeUser(item)">Upgrade</v-btn> -->
              <v-btn  small elevation="0" outlined color="primary" class="mr-2" @click="removeUser(item)">Remove</v-btn>
              
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/DataTableLoader.vue"
export default {
  components: {SmartChurchLayout},
  data() {
    return {
      removeAdmin: false,
      allAdmin: [],
      overlay:true,
      headers: [
        
        { text: "Name", value: "name" },
        { text: "Email Address", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
      all_admin: {
        name: "",
        email: "",
        phone: "",
      },
    };
  },

  methods: {
    getAllAdmin() {
      this.$http
        .get("/admin/getAllAdmin")
        .then((response) => {
          this.allAdmin = response.data.data.user;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          catchAxiosExceptions(error);
        });
    },

    removeUser(item) {
      this.editedIndex = this.allAdmin.indexOf(item);
      this.all_admin = Object.assign({}, item);
      this.removeAdmin = true;

     
      
    },
    confirmRemoveAdmin(){
      
        this.$http
        .put(`/admin/removeFromAdmin/${this.all_admin.id}`)
        .then((response) => {
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";

          this.closeDelete()
          this.getAllAdmin()
          
        })
        .catch((error) => {
          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
          catchAxiosExceptions(error)
        });
    },
    closeDelete() {
      this.removeAdmin = false;
    },
  },
  mounted() {
    this.getAllAdmin();
  },
}
</script>

<style>

</style>