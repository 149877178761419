<template>
  <smart-church-layout page="Follow Up Sms">
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout">{{ snackbar.msg
    }}</v-snackbar>
    <v-container>
      <v-btn
        class="bg-transparent ms-7 mt-5"
        small
        :to="{ name: 'followUpCommunication' }"
        ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
      >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10" lg="10">
          
          <v-card class="
              d-flex
              justify-center
              mt-12
              rounded-lg
              text-capitalize
              elevation-0
            ">
            <v-form class="my-6 pa-13" ref="form">
              <h2 class="mb-4 font-weight-bold primary--text">Send Message</h2>
              <v-row>

                <v-col cols="12" md="6">
                  <v-text-field dense label="Subject" outlined v-model="subject">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="selectedSms" item-text="name" :items="allSms" item-value="email"   chips
                    label="Phone Number" multiple outlined dense>
                    <template #prepend-item>
                      <v-list-item @click="selectAllSms">
                        <v-list-item-action>
                          <v-checkbox v-model="selectAll"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>Select All</v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <!-- <vue-editor v-model="content" /> -->
                  <v-textarea v-model="content" label="Message" outlined></v-textarea>
                </v-col>
              </v-row>
              <div>
                <v-btn color="primary white--text" @click="sendSms()">Send Message</v-btn>
              </div>
            </v-form>
          </v-card>
          <!-- <v-data-table :headers="headers" :items="showSms" class="elevation-1 mt-5">
            <template v-slot:[`item.created_at`]="{ item }">
              {{ getFormattedDate(item.created_at) }}
            </template>
          </v-data-table> -->
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue";
import { VueEditor } from "vue2-editor";
import { getFormattedDate } from "../../__helpers/handelExceptions";
export default {
  components: { SmartChurchLayout, VueEditor },
  data() {
    return {
      // items: [{ text: "Pastors", value: "firstTimers" }],
      subject: "",
      content: "",
      emails: [],
      firstTimers: [],
      members: [],
      headers: [
        {
          text: "Date",
          value: "created_at",
          align: "start",
        },
        {
          text: "Email Address",
          value: "recipients",
        },
        {
          text: "To",
          value: "type",
        },
        {
          text: "Subject",
          value: "subject",
        },
        { text: "Status", value: "mail", sortable: false },
      ],
      selectedRecipient: null,
      selectedSms: [],
      selectAll: false,
      allSms: [],
      showSms: [],
      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
    };
  },
  methods: {
    async initialize() {
      await this.loadFirstTimers();
      await this.loadEmails();
      // await this.displayEmails();
    },
    async loadFirstTimers() {
      try {
        const response = await this.$http.get("admin/firstTimersList");
        this.firstTimers = response.data.data;
        console.log(this.firstTimers);
      } catch (error) {
        console.log(error);
      }
    },
    
    loadEmails() {
      
        this.allSms = this.firstTimers.map((firstTimer) => firstTimer.phone);
        console.log(this.allSms)
      
    },

    sendSms() {
      const payload = {
        subject: this.subject,
        body: this.content,
        recipients: this.selectedSms,
        type: "firstTimers"
      };

      
        this.$http
          .post("admin/followUp?via=sms", payload)
          .then((response) => {
            this.snackbar.status = true;
            this.snackbar.msg = response.data.message;
            this.snackbar.color = "green";
            this.$refs.form.reset();
          })
          .catch((error) => {
            this.snackbar.status = true;
            this.snackbar.msg = error.response.data.message;
            this.snackbar.color = "red";
          });
      
    },
    selectAllSms() {
      if (this.selectAll) {
        this.selectedSms = this.allSms.slice();
      } else {
        this.selectedSms = [];
      }
    },
    // async displayEmails() {
    //   this.$http.get("admin/followUp").then((response) => {
    //     this.showSms = response.data.data;
    //     console.log(this.showSms);
    //     this.sendSms();
    //   });
    // },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>