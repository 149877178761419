import router from "../router";
import store from "../store";

export const catchAxiosExceptions = (error) => {
  if (error.response) {
    // unauthenticated
    // redirect to login
    if (error.response.status === 401) {
      // remove user details - logout
      store.commit("removeUserDetail");
      localStorage.setItem("errorMessage", true);

      // redirect to login
      router.push({ name: "Login" });
    }

    // 404 error
    if (error.response.status === 404) {
      router.push({ name: "PageNotFound" });
    }

    // server error
    if(error.response.status === 500){
      router.push({name: "ServerError"})
    }

    // Subscription related error
    if(error.response.status === 402){
      router.push({ name: "AdminCurrentSubscription"})
    }

    // Unauthorized
    if(error.response.status === 403){
      store.commit("removeUserDetail");
      router.push({ name: "Login"});
    }
  }
};


export const getFormattedAmount = (amount) =>{
  return new Intl.NumberFormat('en-US').format(amount);
}

export const getFormattedDate = (date) => {
  var options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const theDate = new Date(date);

  var formattedDate = theDate.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const getReportYears = (serviceReports) => {
  let years = [];

  serviceReports.forEach((serviceReport) => {
    let createdYear = serviceReport.service_date.split("-")[0];

    if (!years.includes(createdYear)) {
      years.push(createdYear);
    }
  });

  return years;
};

export const getTotalAttendance = (report) => {
  return (
    report.male_attendance +
    report.female_attendance +
    report.children_attendance +
    report.teenager_attendance
  );
};

export const getSelectedFromToDate = (selectedData) => {
  let startMonth = selectedData.startMonth;
  let endMonth = selectedData.endMonth;

  let date = new Date(selectedData.year, selectedData.endMonth, 0);

  if (startMonth.toString().length === 1) {
    startMonth = `0${selectedData.startMonth}`;
  }

  if (date.getMonth().toString().length === 1) {
    endMonth = `0${selectedData.endMonth}`;
  }

  let finalDate = {};

  finalDate["from"] = `${selectedData.year}-${startMonth}-01`;
  finalDate["to"] = `${selectedData.year}-${endMonth}-${date.getDate()}`;

  return finalDate;
};

export const getLastTwoMonthsAndToday = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let lastDay = date.getDate();

  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  if (lastDay.toString().length === 1) {
    lastDay = `0${lastDay}`;
  }

  date.setMonth(date.getMonth() - 2);

  let lastTwoMonths = date.toLocaleDateString().split("/");

  if (lastTwoMonths[1].toString().length === 1) {
    lastTwoMonths[1] = `0${lastTwoMonths[1]}`;
  }

  let finalDate = {};

  finalDate["dateLastTwoMonths"] = `${lastTwoMonths[2]}-${lastTwoMonths[1]}-01`;
  finalDate["today"] = `${year}-${month}-${lastDay}`;

  return finalDate;
};
