<template>
  <v-container fluid class="d-background">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="6" lg="6">
        <a href="https://smartchurch.ng/" target="_blank">
          <v-img
            src="@/assets/smartchurch-logo.png"
            max-height="180"
            max-width="180"
            class="d-block mx-auto mb-6"
          ></v-img>
        </a>
        <v-card class="px-6 py-10 rounded-lg">
          <h3 class="text-center my-8 primary--text font-weight-bold">
            Create account
          </h3>
          <v-form class="px-5" ref="form" @submit.prevent="signupChurch">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="First Name"
                  v-model="church_form.first_name"
                  :error-messages="church_form_errors.first_name"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Last Name"
                  v-model="church_form.last_name"
                  :error-messages="church_form_errors.last_name"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Church Name"
                  v-model="church_form.name"
                  :error-messages="church_form_errors.name"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Address"
                  v-model="church_form.address"
                  :error-messages="church_form_errors.address"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="church_form.email"
                  :error-messages="church_form_errors.email"
                  outlined
                  dense
                  rounded
                  type="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Phone Number"
                  v-model="church_form.phone"
                  :error-messages="church_form_errors.phone"
                  type="number"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Role in Church"
                  v-model="church_form.role_in_church"
                  :error-messages="church_form_errors.role_in_church"
                  outlined
                  dense
                  rounded
                  :items="items"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  label="Country"
                  v-model="church_form.country"
                  :error-messages="church_form_errors.country"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  rounded
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="State"
                  v-model="church_form.state"
                  :error-messages="church_form_errors.state"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="City"
                  v-model="church_form.city"
                  :error-messages="church_form_errors.city"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Gender"
                  :items="gender"
                  v-model="church_form.gender"
                  :error-messages="church_form_errors.gender"
                  outlined
                  dense
                  rounded
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :value="userPassword"
                  required
                  label="Password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  v-model="church_form.password"
                  :error-messages="church_form_errors.password"
                  @click:append="show1 = !show1"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Confirm Password"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  v-model="church_form.password_confirmation"
                  :error-messages="church_form_errors.password_confirmation"
                  @click:append="show2 = !show2"
                  outlined
                  dense
                  rounded
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              block
              depressed
              rounded
              color="primary white--text "
              :loading="isSubmitted"
              :disabled="isSubmitted"
              large
              >sign up</v-btn
            >
          </v-form>
          <!-- <div
            class="alert alert-success mt-5 text-center"
            v-if="church_form.response"
          >
            {{ church_form.response }}
          </div>
          <div
            class="alert alert-danger mt-5 text-center"
            v-if="church_form.error"
          >
            {{ church_form.error }}
          </div>
          <div
            class="alert alert-danger mt-5 text-center"
            v-if="church_form_errors.length > 1"
          >
            {{ church_form_errors }}
          </div> -->
          <v-card-text class="text-center content-paragraph">
            Already have an account?
            <router-link :to="{ name: 'Login' }">Login</router-link>
            <br />
            <router-link :to="{ name: 'forgotten password' }"
              >Forgot password?</router-link
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Countries from "@/models/countries";

export default {
  data() {
    return {
      // countries: [],

      items: ["Founder", "Resident Pastor", "IT Head", "Developer"],
      gender: ["male", "female"],

      church_form: {
        name: "",
        last_name: "",
        first_name: "",
        email: "",
        phone: "",
        country_code: "",
        country: "",
        state: "",
        city: "",
        password: "",
        password_confirmation: "",
        address: "",
        gender: "",
        response: "",
        error: "",
        role_in_church: "",
      },

      show1: false,
      show2: false,

      // dialog: false,

      isSubmitted: false,

      church_form_errors: [],

      countries: [],
      userPassword: "",

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },
    };
  },

  methods: {
    signupChurch() {
      this.$store.commit("removeUserDetail");

      this.church_form_errors = [];

      if (
        this.church_form.password !== this.church_form.password_confirmation
      ) {
        this.church_form_errors = "Invalid password";
      }

      // if (!this.church_form_errors.length) {
      this.isSubmitted = true;

      this.$http
        .post("churches", this.church_form)

        .then((response) => {
          this.church_form.response = response.data.message;
          // console.log(this.church_form.response)
          this.isSubmitted = false;
          // this.dialog = false
          this.$refs.form.reset();
          this.$router.push({
            name: "WelcomeAfterSignup",
          });
          // this.$router.push({
          //   name: "Login",
          //  query: {
          //   signup: "success",
          // },
          //
          // });
        })
        .catch((error) => {
          this.isSubmitted = false;
          this.snackbar.status = true;
          
          this.snackbar.color = "red";

          // console.log(error);

          // if(error == undefined && error.response == undefined){
          //   this.$router.push({name: "WelcomeAfterSignup"});
          // }
         
          if (error !== undefined && error.response !== undefined) {
           
            this.church_form_errors = error.response.data.errors;
          }

          // if (error.response.status === 500) {
          //   this.$router.push({ name: "AdminLogin" });
          // }
        });
      // }
    },
    getCountryNames() {
      this.$http
        .get("countries")
        .then((response) => {
          this.countries = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.countries = Countries;
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");

* {
  font-family: "Fira Sans", sans-serif;
  text-transform: capitalize;
}
.v-application--wrap {
  background: blue !important;
}
p {
  font-size: 1.5em;
  color: #c73439;
}
a {
  text-decoration: none;
}
.d-background {
  background: #fef6f6;
}
label {
  margin-bottom: 0.8em;
  color: #c73439;
}
</style>
