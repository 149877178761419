<template>
  <smart-church-layout page="Members">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" class="mt-7" /> -->
    <v-dialog
      v-model="dialog"
       max-width="900"
      height="700"
      overlay
      retain-focus
      transition="dialog-bottom-transition"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <v-card class="p-1 p-lg-3">
        
        <div class="d-flex justify-content-end">
          <v-btn icon dark @click="dialog = false" color="black" class="p-3">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row align="center" justify="center" class="mt-0 mt-lg-5 w-90 mx-auto">
          <v-col cols="12">
            <a href="https://smartchurch.ng/" target="_blank">
              <v-img
                src="@/assets/smartchurch-logo.png"
                max-height="180"
                max-width="180"
                class="d-block mx-auto"
              ></v-img>
            </a>
          </v-col>
          <v-col cols="12" lg="7">
            <v-form class="p-4" @submit.prevent="addFeedback" ref="ratingForm">
              <v-row>
                <v-col cols="12">
                  <h3 class="text-h4 text-bold text-center">
                    What is your rating?
                  </h3>
                  <p class="text-secondary fs-5 text-center">
                    Please drop a comment or remark about your
                    experience using SmartChurch
                  </p>
                  <div class="w-75 mx-auto m-2">
                    <star-rating
                      animate
                      :glow="glow"
                      :show-rating="showNumber"
                      :star-size="starSize"
                      v-model="feedBack.rating"
                      class="d-flex justify-content-center"
                    />
                  </div>
                </v-col>
                <v-col cols="12" class="mt-5">
                  <v-textarea outlined v-model="feedBack.message" hint="Kindly complete the comment section" required> </v-textarea>
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary white--text"
                block
                class="fw-bold"
                :loading="isSubmitted"
                :disabled="isSubmitted"
                >Submit</v-btn
              >
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-container fluid class="my-8">
      <div class="d-flex justify-space-between">
        <v-btn
          class="bg-transparent ms-7 mt-5"
          small
          :to="{ name: 'AdminMembers' }"
          ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn
        >
        <v-btn
          color="primary white--text"
          class="mt-6"
          :to="{ name: 'memberBulkImport' }"
          >Import From Excel</v-btn
        >
      </div>

      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="8">
          <!-- <div class="alert alert-danger my-3" v-if="mountedError">
            Data could not be loaded: {{ mountedError }}
            <v-btn
              color="primary white--text"
              :loading="isLoading"
              @click="initAddMember"
            >
              Fetch again
            </v-btn>
          </div> -->

          <v-card
            class="d-flex justify-center mt-12 rounded-lg text-capitalize"
          >
            <v-form class="my-6 pa-13" ref="form" @submit.prevent="addMember">
              <h2
                class="primary--text d-flex justify-center my-4 font-weight-bold"
              >
                Add New Member
              </h2>

              <v-row class="mt-6">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                    id="fname"
                    v-model="add_member.first_name"
                    :error-messages="add_member_errors.first_name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Last Name"
                    id="lname"
                    v-model="add_member.last_name"
                    :error-messages="add_member_errors.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    outlined
                    dense
                    label="Gender"
                    id="sex"
                    :items="items"
                    v-model="add_member.sex"
                    :error-messages="add_member_errors.sex"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="add_member.branch_id"
                    :items="branches"
                    dense
                    label="Branch"
                    id="branch_id"
                    item-value="id"
                    outlined
                    item-text="name"
                    :error-messages="add_member_errors.branch_id"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Country"
                    id="country"
                    v-model="add_member.country"
                    :error-messages="add_member_errors.country"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Nationality"
                    id="nationality"
                    v-model="add_member.nationality"
                    :error-messages="add_member_errors.nationality"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    id="state"
                    v-model="add_member.state"
                    :error-messages="add_member_errors.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    id="city"
                    v-model="add_member.city"
                    :error-messages="add_member_errors.city"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    id="email"
                    v-model="add_member.email"
                    :error-messages="add_member_errors.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    outlined
                    dense
                    label="Residential Address"
                    id="address"
                    v-model="add_member.address"
                    :error-messages="add_member_errors.address"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    label="Phone Number"
                    id="phone"
                    v-model="add_member.phone"
                    :error-messages="add_member_errors.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="date"
                    outlined
                    dense
                    label="Date Of Birth"
                    v-model="add_member.date_of_birth"
                    :error-messages="add_member_errors.date_of_birth"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Occupation"
                    id="Occupation"
                    v-model="add_member.occupation"
                    :error-messages="add_member_errors.occupation"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Next of Kin"
                    id="next_of_kin"
                    v-model="add_member.next_of_kin"
                    :error-messages="add_member_errors.next_of_kin"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="marital_status"
                    outlined
                    dense
                    label="Marital Status"
                    id="marital status"
                    v-model="add_member.marital_status"
                    :error-messages="add_member_errors.marital_status"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    outlined
                    dense
                    label="Unit in Church"
                    id="unit in church"
                    v-model="add_member.unit_in_church"
                    :error-messages="add_member_errors.unit_in_church"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="employment"
                    id="Employment Status"
                    label="Employment status"
                    v-model="add_member.employment_status"
                    :error-messages="add_member_errors.employment_status"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="status"
                    id="status"
                    label="Status"
                    v-model="add_member.status"
                    :error-messages="add_member_errors.status"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    label="Permanent Home Address"
                    id="address"
                    v-model="add_member.address"
                    :error-messages="add_member_errors.address"
                  ></v-textarea>
                </v-col>

                <div
                  class="alert alert-danger mt-5 text-center"
                  v-if="add_member_errors.length > 1"
                >
                  {{ add_member_errors }}
                </div>

                <v-col cols="12" md="6">
                  <div class="img-content">
                    <v-icon x-large>mdi-image-outline</v-icon>
                    <p class="text--secondary">
                      Please upload as a JPG, <br />
                      JPEG or PNG format <br />
                      (not more than 2MB)
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-end">
                  <v-file-input
                    ref="fileInput"
                    show-size
                    accept="image/png, image/jpeg,"
                    small-chips
                    outlined
                    label="Upload Image"
                    prepend-icon="mdi-camera"
                    dense
                    v-model="add_member.logo"
                    class="mx-auto upload-body"
                  >
                  </v-file-input>
                </v-col>
                <!-- <div>
                  <v-btn @click="uploadPicture" depressed color="primary white--text" class="mb-2">
                  upload
                </v-btn>
                </div> -->
              </v-row>
              <v-btn
                type="submit"
                depressed
                block
                :loading="isSubmitted"
                :disabled="isSubmitted"
                color="primary white--text"
                >Add</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </smart-church-layout>
</template>
<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
// import skeletonLoader from "../../components/FormSkeletonLoader.vue"
import StarRating from "vue-star-rating";


export default {
  components: { SmartChurchLayout, StarRating },

  data() {
    return {
      // rules: [
      //   (value) =>
      //     !value ||
      //     !value.length ||
      //     value[0].size < 200000 ||
      //     "Avatar size should be less than 2 MB!",
      // ],
      items: ["Male", "Female"],
      status: ["Active", "Inactive"],
      employment: ["Employed", "Unemployed"],
      marital_status: ["Single", "Married"],

      add_member: {
        first_name: "",
        last_name: "",
        branch_id: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        occupation: "",
        employment_status: "",
        sex: "",
        marital_status: "",
        date_of_birth: "",
        nationality: "",
        status: "",
        unit_in_church: "",
        logo: "",
      },
      // photo:{
      //   logo:"",
      //   // _method: "PUT"
      // },

      overlay: true,

      branches: [],
      add_member_errors: {},

      isLoading: false,

      isSubmitted: false,

      snackbar: {
        status: false,
        msg: "",
        color: "",
        timeout: 3000,
      },

      import_excel: {
        excel_sheet: null,
        branch_id: null,
      },

       dialog: false,

      showNumber: false,
      starSize: 45,
      glow: 4,

      feedBack: {
        rating: 0,
        message: "",
      },
    }
  },

  methods: {
    async initAddMember() {
      this.isLoading = true

      try {
         const user = this.$store.state.userDetail.user;
        this.branches = user.branches;
        this.isLoading = false

        this.overlay = false
      } catch (error) {
        // this.mountedError = error.response.data.message
        this.isLoading = false
        this.overlay = false

        catchAxiosExceptions(error)
      }
    },

    addMember() {
      this.add_member_errors = ""

      this.isSubmitted = true

      const headers = { "Content-Type": "multipart/form-data" }

      this.$http
        .post("admin/members", this.add_member, { headers })
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          this.$refs.form.reset();

           if (!localStorage.getItem("checkedRatingForm")) {
            this.dialog = true;
          }
        })
        .catch((error) => {
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"

          this.add_member_errors = error.response.data.errors

          this.isSubmitted = false

          catchAxiosExceptions(error)
          if (error.response.status === 402) {
            alert(
              "Please upgrade your Subscription Plan to enjoy unlimited access. Thank You!"
            )
            this.$refs.form.reset()
          }
        })
    },

    // uploadPicture(){
    //   // ${this.routeParamSlug}
    //   const headers = { "Content-Type": "multipart/form-data" };
    //   this.$http.post(`memberImageUpdate`, this.photo, {headers})
    //   .then((response)=>{
    //     console.log(response);
    //   }).catch((error)=>{
    //     console.log(error);
    //   })
    // },

     addFeedback() {
      this.isSubmitted = true;

      this.$http
        .post("feedback", this.feedBack)
        .then((response) => {
          // console.log(response.data.message);
          this.isSubmitted = false;
          this.dialog = false;
          this.snackbar.status = true;
          this.snackbar.msg = response.data.message;
          this.snackbar.color = "green";
          this.$refs.ratingForm.reset();
          this.feedBack.rating = 0;
          localStorage.setItem("checkedRatingForm", true);
        })
        .catch((error) => {
          // console.log(error);
          this.isSubmitted = false;

          this.snackbar.status = true;
          this.snackbar.msg = error.response.data.message;
          this.snackbar.color = "red";
        });
    },

    memberImport() {
      this.import_excel = ""
      this.isSubmitted = true
      const headers = { "Content-Type": "multipart/form-data" }

      this.$http
        .post("admin/membersImport", this.import_excel, {
          headers,
        })
        .then((response) => {
          this.snackbar.status = true
          this.snackbar.msg = response.data.message
          this.snackbar.color = "green"

          this.isSubmitted = false
        })
        .catch((error) => {
          this.isSubmitted = false
          this.snackbar.status = true
          this.snackbar.msg = error.response.data.message
          this.snackbar.color = "red"
        })
    },
    onFileSelected() {
      console.log(this.excel_sheet)
    },
  },

  mounted() {
    this.initAddMember()
  },
}
</script>

<style scoped>
label {
  color: #c73438;
  font-size: 16px !important;
  margin-bottom: 0.5em !important;
}
.v-icon {
  color: #c73438;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(204, 49, 10, 0.986);
}

.v-text-field::-moz-focus-inner {
  background-color: red !important;
}
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot:focus {
  background: #eedddd !important;
}
.img-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
